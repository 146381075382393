const Data = [
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2da",
    "rank": "12268",
    "applicationNo": "26120238392",
    "amount": "1300",
    "studentName": "ASHUTOSH PAYASI",
    "emailId": "harshpayasi6666@gmail.com",
    "mobileNo": "6263689097",
    "dob": "11/13/2005",
    "address1": "Near New Radiance English School",
    "address2": "Prem Vihar Colony",
    "city": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "485001",
    "gender": "Male",
    "registeredDate": "26-1-2023",
    "paymentStatus": "Success",
    "fatherName": "RAJIV KUMAR PAYASI",
    "motherName": "ARCHANA PAYASI",
    "photo": "https://ucarecdn.com/cf0f8800-a8b4-4d9f-b2b1-65314f6e7b8a/-/crop/193x193/0,21/-/preview/",
    "signature": "https://ucarecdn.com/763fe2df-12c3-4395-965e-71d6b773830a/-/crop/150x60/7,0/-/preview/",
    "markObtained": "176",
    "phy": "46",
    "che": "42",
    "bio": "31",
    "gk": "57",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "175345",
    "__v": 3,
    "date": "2023-03-01T04:25:18.940Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=26120238392&tracking_id=112808923039&bank_ref_no=306021390144&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=ASHUTOSH PAYASI&billing_address=Near New Radiance English School&billing_city=Satna&billing_state=Madhya Pradesh&billing_zip=485001&billing_country=India&billing_tel=6263689097&billing_email=harshpayasi6666@gmail.com&delivery_name=ASHUTOSH PAYASI&delivery_address=Near New Radiance English School&delivery_city=Satna&delivery_state=Madhya Pradesh&delivery_zip=485001&delivery_country=India&delivery_tel=6263689097&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=01/03/2023 09:55:06&bin_country=",
    "_id": "63fed3aea9acd6bdda329d30"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf07",
    "collegeName": "People?s University, Bhopal",
    "collegeCode": "MP00012",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf00",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MP00004",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaeff",
    "collegeName": " Amity University,Gwalior",
    "collegeCode": "MP00003",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf06",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MP00011",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf0b",
    "collegeName": " Sage university ,Indore",
    "collegeCode": "MP00017",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb163",
    "collegeName": "S. B. PATIL COLLEGE OF ENGINEERING",
    "collegeCode": "MH00049",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16d",
    "collegeName": "ST. JOHN COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "MH00059",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16f",
    "collegeName": "TRINITY COLLEGE OF ENGINEERING & RESEARCH",
    "collegeCode": "MH00061",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf03",
    "collegeName": "G.h. raisoni university,Chhindwara",
    "collegeCode": "MP00008",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0f",
    "collegeName": "Mansarovar Global University,Gadia",
    "collegeCode": "MP00021",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf08",
    "collegeName": " P.K University,Shivpuri",
    "collegeCode": "MP00013",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefe",
    "collegeName": " Avantika University,Ujjain",
    "collegeCode": "MP00002",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0a",
    "collegeName": "Rabindranath Tagore University (Formerly AISECTUniversity),Bhopal",
    "collegeCode": "MP00015",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0e",
    "collegeName": "Mandsaur university, Mandsaur",
    "collegeCode": "MP00020",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf02",
    "collegeName": " Eklavya University",
    "collegeCode": "MP00007",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf12",
    "collegeName": " Sarvepalli Radhakrishnan University",
    "collegeCode": "MP00024",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0d",
    "collegeName": " Mahakaushal University, Anthakheda, Chargawan Road, Post- Tilwara, Jabalpur, Madhya Pradesh ",
    "collegeCode": "MP00019",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf09",
    "collegeName": "RKDF University, Bhopal",
    "collegeCode": "MP00014",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaece",
    "collegeName": "Jaypee Institute of Information Technology, Noida",
    "collegeCode": "UP00012",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed6",
    "collegeName": "Swami Vivekanand Subharti University, Meerut",
    "collegeCode": "UP00020",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25c",
    "collegeName": "BHABHA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UP00043",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb24b",
    "collegeName": "ACE COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "UP00025",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb253",
    "collegeName": "APOLLO INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UP00033",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "branch": "Computer Science Engineering with Data science ",
    "programme": "Engineering",
    "state": "MADHYA PRADESH"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2cd",
    "rank": "8302",
    "applicationNo": "171120225809",
    "amount": "1300",
    "studentName": "Om Roy",
    "emailId": "omroy3432@gmail.com",
    "mobileNo": "9950882655",
    "dob": "7/29/2005",
    "address1": "Near New Radiance English School",
    "address2": "Prem Vihar Colony",
    "city": "Satna",
    "state": "Madhya Pradesh",
    "pincode": "485001",
    "gender": "Male",
    "registeredDate": "17-11-2022",
    "paymentStatus": "Success",
    "fatherName": "Jitendra Roy",
    "motherName": "Ranjeeta Roy",
    "photo": "https://ucarecdn.com/e8ad3a9d-6f87-4067-bbfa-caf8d01d485c/-/crop/300x300/0,19/-/preview/",
    "signature": "https://ucarecdn.com/35075a56-5ea6-42b0-a6f7-d590aadcb639/-/crop/284x114/0,6/-/preview/",
    "markObtained": "245",
    "phy": "61",
    "che": "54",
    "bio": "50",
    "gk": "80",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "351260",
    "__v": 4,
    "date": "2023-03-01T02:59:39.336Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=171120225809&tracking_id=112808852552&bank_ref_no=342634537875&order_status=Failure&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Failed&currency=INR&amount=5300.00&billing_name=Om Roy&billing_address=Near New Radiance English School&billing_city=Satna&billing_state=Madhya Pradesh&billing_zip=485001&billing_country=India&billing_tel=9950882655&billing_email=omroy3432@gmail.com&delivery_name=Om Roy&delivery_address=Near New Radiance English School&delivery_city=Satna&delivery_state=Madhya Pradesh&delivery_zip=485001&delivery_country=India&delivery_tel=9950882655&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=01/03/2023 08:29:30&bin_country=",
    "_id": "63febf9ba9acd6bdda329cad"
    },
    {
    "paymentStatus": "order_id=171120225809&tracking_id=112808920328&bank_ref_no=306021132339&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Om Roy&billing_address=Near New Radiance English School&billing_city=Satna&billing_state=Madhya Pradesh&billing_zip=485001&billing_country=India&billing_tel=9950882655&billing_email=omroy3432@gmail.com&delivery_name=Om Roy&delivery_address=Near New Radiance English School&delivery_city=Satna&delivery_state=Madhya Pradesh&delivery_zip=485001&delivery_country=India&delivery_tel=9950882655&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=01/03/2023 09:46:00&bin_country=",
    "_id": "63fed18ba9acd6bdda329cfc"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf66",
    "collegeName": "ADITYA COLLEGE OF ENGINEERING",
    "collegeCode": "AP00013",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed0",
    "collegeName": "Rama University, Kanpur,Delhi Ncr",
    "collegeCode": "UP00014",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb12f",
    "collegeName": "RADHARAMAN INSTITUTE OF TECHNOLOGY AND SCIENCE",
    "collegeCode": "MP00059",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb132",
    "collegeName": "SAGAR INSTITUTE OF RESEARCH & TECHNOLOGY",
    "collegeCode": "MP00062",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae90",
    "collegeName": "Dr. C.V. Raman University,",
    "collegeCode": "BR00004",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae8f",
    "collegeName": "K K University, Nalanda ",
    "collegeCode": "BR00003",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeb8",
    "collegeName": " Jagannath University ,Jhajjar",
    "collegeCode": "HR00008",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf5f",
    "collegeName": " Swami Vivekananda University",
    "collegeCode": "WB00006",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee9",
    "collegeName": " ARKA Jain University",
    "collegeCode": "JH00003",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9b",
    "collegeName": "Amitya University,Rajkot",
    "collegeCode": "GU00002",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9c",
    "collegeName": "Bhagwan Mahavir University",
    "collegeCode": "GU00004",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9f",
    "collegeName": " Ganpat University,Mehsana",
    "collegeCode": "GU00008",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea2",
    "collegeName": " INDUS University,Ahmedabad",
    "collegeCode": "GU00012",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea4",
    "collegeName": "ITM (SLS) Baroda University",
    "collegeCode": "GU00014",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaead",
    "collegeName": "Rai University,Ahmedabad",
    "collegeCode": "GU00024",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf0e",
    "collegeName": "Mandsaur university, Mandsaur",
    "collegeCode": "MP00020",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf0c",
    "collegeName": "Madhyanchal Professional University,Bhopal",
    "collegeCode": "MP00018",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf01",
    "collegeName": " Dr. A.P.J. Abdul KalamUniversity,Indore",
    "collegeCode": "MP00005",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf00",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MP00004",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf02",
    "collegeName": " Eklavya University",
    "collegeCode": "MP00007",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf03",
    "collegeName": "G.h. raisoni university,Chhindwara",
    "collegeCode": "MP00008",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf06",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MP00011",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "Rama University, Kanpur,Delhi Ncr",
    "collegeCode": "UP00014",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "UTTAR PRADESH"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2d7",
    "rank": "8965",
    "applicationNo": "6120235644",
    "amount": "1300",
    "studentName": "Avinandan Mukherjee",
    "emailId": "avinandanmukherjee.2003@gmail.com",
    "mobileNo": "9836714226",
    "dob": "9/15/2003",
    "address1": "Agarpara",
    "address2": "Hori Mohan Chatterjee Road PO kamarhati  PS khardahah",
    "city": "kolkata",
    "state": "west bengal",
    "pincode": "700058",
    "gender": "Male",
    "registeredDate": "6/1/2023",
    "paymentStatus": "Success",
    "fatherName": "Subhash Mukherjee",
    "motherName": "Tumpa Mukherjee",
    "photo": "https://ucarecdn.com/a6dc632e-1a32-4de2-9af9-55344b2ea6b5/-/crop/532x532/0,0/-/preview/",
    "signature": "https://ucarecdn.com/e1f4de7b-b5d1-4e68-8fcd-0d6745ae868a/-/crop/1601x641/107,0/-/preview/",
    "markObtained": "228",
    "phy": "49",
    "che": "48",
    "bio": "47",
    "gk": "84",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "88019",
    "__v": 4,
    "date": "2023-02-27T17:42:38.803Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=6120235644&tracking_id=112807577373&bank_ref_no=186888&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=MasterCard Debit Card&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=5300.00&billing_name=Avinandan Mukherjee&billing_address=Agarpara&billing_city=Kolkata&billing_state=West Bengal&billing_zip=700058&billing_country=India&billing_tel=9836714226&billing_email=avinandanmukherjee.2003@gmail.com&delivery_name=Avinandan Mukherjee&delivery_address=Agarpara&delivery_city=Kolkata&delivery_state=West Bengal&delivery_zip=700058&delivery_country=India&delivery_tel=9836714226&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=27/02/2023 23:12:38&bin_country=INDIA",
    "_id": "63fceb8e3e15a807c907ca90"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf62",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5e",
    "collegeName": "Sister Nivedita University, Kolkata",
    "collegeCode": "WB00005",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf35",
    "collegeName": " NIIT university, Neemrana",
    "collegeCode": "RJ00021",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5f",
    "collegeName": " Swami Vivekananda University",
    "collegeCode": "WB00006",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8f",
    "collegeName": "K K University, Nalanda ",
    "collegeCode": "BR00003",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8d",
    "collegeName": "Amity University , Patna ",
    "collegeCode": "BR00001",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeac",
    "collegeName": " R.K. University",
    "collegeCode": "GU00023",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5c",
    "collegeName": "Brainware University, Kolkata",
    "collegeCode": "WB00003",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf61",
    "collegeName": "The Neotia University, Sarisa",
    "collegeCode": "WB00008",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5d",
    "collegeName": "JIS University,Kolkata",
    "collegeCode": "WB00004",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5b",
    "collegeName": "Adamas University,Kolkata",
    "collegeCode": "WB00001",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29c",
    "collegeName": "ASANSOL ENGINEERING COLLEGE",
    "collegeCode": "WB00011",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a4",
    "collegeName": "CALCUTTA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00020",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29d",
    "collegeName": "BASANTIKA INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00012",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29e",
    "collegeName": "BENGAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00013",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a6",
    "collegeName": "CAMELLIA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00022",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf45",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b7",
    "collegeName": "KANAD INSTITUTE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "WB00046",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c3",
    "collegeName": "SWAMI VIVEKANANDA INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "WB00061",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a8",
    "collegeName": "DR. B.C. ROY ENGINEERING COLLEGE, DURGAPUR",
    "collegeCode": "WB00024",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b9",
    "collegeName": "NARULA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00048",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b0",
    "collegeName": "GURUNANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00033",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4a",
    "collegeName": "VELS University, Pallavaram",
    "collegeCode": "TM00012",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf46",
    "collegeName": "Hindustan Institute of Technology and Science",
    "collegeCode": "TM00005",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaece",
    "collegeName": "Jaypee Institute of Information Technology, Noida",
    "collegeCode": "UP00012",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed6",
    "collegeName": "Swami Vivekanand Subharti University, Meerut",
    "collegeCode": "UP00020",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeca",
    "collegeName": "IIMT University, Meerut",
    "collegeCode": "UP00008",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf41",
    "collegeName": "University of Engineering & Management, Jaipur,",
    "collegeCode": "RJ00033",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf01",
    "collegeName": " Dr. A.P.J. Abdul KalamUniversity,Indore",
    "collegeCode": "MP00005",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8a",
    "collegeName": "SRM University,AP",
    "collegeCode": "AP00008",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae99",
    "collegeName": "Kalinga University",
    "collegeCode": "CH00010",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae85",
    "collegeName": "Centurion University , Visakhapatnam",
    "collegeCode": "AP00001",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae86",
    "collegeName": "B.E.S.T Innovation Technology , Anantapur",
    "collegeCode": "AP00002",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae85",
    "collegeName": "Centurion University , Visakhapatnam",
    "collegeCode": "AP00001",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae86",
    "collegeName": "B.E.S.T Innovation Technology , Anantapur",
    "collegeCode": "AP00002",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8a",
    "collegeName": "SRM University,AP",
    "collegeCode": "AP00008",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae90",
    "collegeName": "Dr. C.V. Raman University,",
    "collegeCode": "BR00004",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8e",
    "collegeName": "Sandip University,Madhubani",
    "collegeCode": "BR00002",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8d",
    "collegeName": "Amity University , Patna ",
    "collegeCode": "BR00001",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8e",
    "collegeName": "Sandip University,Madhubani",
    "collegeCode": "BR00002",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8f",
    "collegeName": "K K University, Nalanda ",
    "collegeCode": "BR00003",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae90",
    "collegeName": "Dr. C.V. Raman University,",
    "collegeCode": "BR00004",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1c",
    "collegeName": " C.T. University,Ludhiana",
    "collegeCode": "PN00003",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1d",
    "collegeName": "D.A.V. University,Jalandhar",
    "collegeCode": "PN00004",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1e",
    "collegeName": " GNA University,Kapurthala",
    "collegeCode": "PN00005",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1f",
    "collegeName": " Guru Kashi University,Bhatinda",
    "collegeCode": "PN00006",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf21",
    "collegeName": "Rayat Bahra University,Mohali",
    "collegeCode": "PN00008",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf22",
    "collegeName": " RIMT University,Gobindgarh",
    "collegeCode": "PN00009",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf23",
    "collegeName": "Sant Baba Bhag Singh University",
    "collegeCode": "PN00010",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf24",
    "collegeName": "Sri Guru Granth Sahib World University",
    "collegeCode": "PN00011",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1c",
    "collegeName": " C.T. University,Ludhiana",
    "collegeCode": "PN00003",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1d",
    "collegeName": "D.A.V. University,Jalandhar",
    "collegeCode": "PN00004",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1e",
    "collegeName": " GNA University,Kapurthala",
    "collegeCode": "PN00005",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1f",
    "collegeName": " Guru Kashi University,Bhatinda",
    "collegeCode": "PN00006",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf21",
    "collegeName": "Rayat Bahra University,Mohali",
    "collegeCode": "PN00008",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf22",
    "collegeName": " RIMT University,Gobindgarh",
    "collegeCode": "PN00009",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf24",
    "collegeName": "Sri Guru Granth Sahib World University",
    "collegeCode": "PN00011",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf23",
    "collegeName": "Sant Baba Bhag Singh University",
    "collegeCode": "PN00010",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5b",
    "collegeName": "Adamas University,Kolkata",
    "collegeCode": "WB00001",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5c",
    "collegeName": "Brainware University, Kolkata",
    "collegeCode": "WB00003",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5d",
    "collegeName": "JIS University,Kolkata",
    "collegeCode": "WB00004",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5e",
    "collegeName": "Sister Nivedita University, Kolkata",
    "collegeCode": "WB00005",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5f",
    "collegeName": " Swami Vivekananda University",
    "collegeCode": "WB00006",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf61",
    "collegeName": "The Neotia University, Sarisa",
    "collegeCode": "WB00008",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf62",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf53",
    "collegeName": "Bhagwant Global University,",
    "collegeCode": "UT00001",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf54",
    "collegeName": "DIT University",
    "collegeCode": "UT00002",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf55",
    "collegeName": "Graphic Era,Dehradun",
    "collegeCode": "UT00003",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf56",
    "collegeName": "Himalayiya University",
    "collegeCode": "UT00004",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf57",
    "collegeName": "Himgiri Zee University",
    "collegeCode": "UT00005",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5a",
    "collegeName": "Quantum University, Roorkee",
    "collegeCode": "UT00010",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf53",
    "collegeName": "Bhagwant Global University,",
    "collegeCode": "UT00001",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf54",
    "collegeName": "DIT University",
    "collegeCode": "UT00002",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf55",
    "collegeName": "Graphic Era,Dehradun",
    "collegeCode": "UT00003",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf56",
    "collegeName": "Himalayiya University",
    "collegeCode": "UT00004",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf57",
    "collegeName": "Himgiri Zee University",
    "collegeCode": "UT00005",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5a",
    "collegeName": "Quantum University, Roorkee",
    "collegeCode": "UT00010",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec6",
    "collegeName": "Babu Banarasi Das University, Lucknow",
    "collegeCode": "UP00003",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec8",
    "collegeName": "G.L.A. University,Mathura",
    "collegeCode": "UP00005",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaecb",
    "collegeName": " IFTM University, Moradabad",
    "collegeCode": "UP00009",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaecc",
    "collegeName": " Integral University, Lucknow",
    "collegeCode": "UP00010",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4c",
    "collegeName": "Aurora Higher Education & Research Academy,",
    "collegeCode": "TL00002",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4e",
    "collegeName": "ICFAI Foundation for Higher Education,",
    "collegeCode": "TL00004",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf50",
    "collegeName": "Malla Reddy University,",
    "collegeCode": "TL00006",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf51",
    "collegeName": "SR University,",
    "collegeCode": "TL00007",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf52",
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf43",
    "collegeName": "Amrita VishwaVidyapeetham,Coimbatore",
    "collegeCode": "TM00001",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf44",
    "collegeName": "Bharath Institute of Higher Education & Research",
    "collegeCode": "TM00003",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf48",
    "collegeName": "Karunya Institute of Technology and Sciences,Coimbatore",
    "collegeCode": "TM00007",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf49",
    "collegeName": "Karpagam Academy of Higher Education,Coimbatore",
    "collegeCode": "TM00008",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf42",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf40",
    "collegeName": "Suresh Gyan Vihar University, Jaipur",
    "collegeCode": "RJ00032",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3f",
    "collegeName": " Sir Padmapat Singhania University",
    "collegeCode": "RJ00031",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3e",
    "collegeName": " Singhania University",
    "collegeCode": "RJ00030",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3d",
    "collegeName": " Shyam University",
    "collegeCode": "RJ00029",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3c",
    "collegeName": " Shridhar University",
    "collegeCode": "RJ00028",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3b",
    "collegeName": " Sangam University",
    "collegeCode": "RJ00027",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3a",
    "collegeName": " R.N.B. Global University, Bikaner",
    "collegeCode": "RJ00026",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf39",
    "collegeName": " Raffles University, Neemrana",
    "collegeCode": "RJ00025",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf38",
    "collegeName": " Pratap University",
    "collegeCode": "RJ00024",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf37",
    "collegeName": " Pacific Academy of Higher Education & Research University",
    "collegeCode": "RJ00023",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf36",
    "collegeName": " Nirwan University",
    "collegeCode": "RJ00022",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf33",
    "collegeName": "Mody University of Science and Technology, Sikar",
    "collegeCode": "RJ00019",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf32",
    "collegeName": " Mewar University",
    "collegeCode": "RJ00018",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf31",
    "collegeName": " Madhav University",
    "collegeCode": "RJ00016",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf30",
    "collegeName": " Lords University, Alwar",
    "collegeCode": "RJ00015",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2e",
    "collegeName": "J.K. Lakshmipat University, Jaipur",
    "collegeCode": "RJ00013",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2c",
    "collegeName": " Jagan Nath University, Jaipur",
    "collegeCode": "RJ00011",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2b",
    "collegeName": " ICFAI University, Jaipur",
    "collegeCode": "RJ00010",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2a",
    "collegeName": "Dr. K.N. Modi University,Tonk",
    "collegeCode": "RJ00007",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf29",
    "collegeName": " Career Point University, Kota",
    "collegeCode": "RJ00006",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf28",
    "collegeName": "Bhagwant University,Ajmer",
    "collegeCode": "RJ00005",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf27",
    "collegeName": " Banasthali Vidyapith",
    "collegeCode": "RJ00003",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf26",
    "collegeName": " Apex University, Jaipur",
    "collegeCode": "RJ00002",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf25",
    "collegeName": " Amity University, Rajasthan",
    "collegeCode": "RJ00001",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1a",
    "collegeName": " GIET University,Gunupur",
    "collegeCode": "OD00004",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf16",
    "collegeName": "Sanjay GhodawatUniversity,Kolhapur",
    "collegeCode": "MH00005",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefd",
    "collegeName": "A.K.S. University, Satna",
    "collegeCode": "MP00001",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefe",
    "collegeName": " Avantika University,Ujjain",
    "collegeCode": "MP00002",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeff",
    "collegeName": " Amity University,Gwalior",
    "collegeCode": "MP00003",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf00",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MP00004",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf02",
    "collegeName": " Eklavya University",
    "collegeCode": "MP00007",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf03",
    "collegeName": "G.h. raisoni university,Chhindwara",
    "collegeCode": "MP00008",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0c",
    "collegeName": "Madhyanchal Professional University,Bhopal",
    "collegeCode": "MP00018",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0b",
    "collegeName": " Sage university ,Indore",
    "collegeCode": "MP00017",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0a",
    "collegeName": "Rabindranath Tagore University (Formerly AISECTUniversity),Bhopal",
    "collegeCode": "MP00015",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf09",
    "collegeName": "RKDF University, Bhopal",
    "collegeCode": "MP00014",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf08",
    "collegeName": " P.K University,Shivpuri",
    "collegeCode": "MP00013",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf07",
    "collegeName": "People?s University, Bhopal",
    "collegeCode": "MP00012",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf06",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MP00011",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf12",
    "collegeName": " Sarvepalli Radhakrishnan University",
    "collegeCode": "MP00024",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf10",
    "collegeName": "Mangalayatan University",
    "collegeCode": "MP00022",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0f",
    "collegeName": "Mansarovar Global University,Gadia",
    "collegeCode": "MP00021",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0e",
    "collegeName": "Mandsaur university, Mandsaur",
    "collegeCode": "MP00020",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0d",
    "collegeName": " Mahakaushal University, Anthakheda, Chargawan Road, Post- Tilwara, Jabalpur, Madhya Pradesh ",
    "collegeCode": "MP00019",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefc",
    "collegeName": "Srinivas University, Mangalore",
    "collegeCode": "KR00017",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefb",
    "collegeName": "Sri Siddhartha Academy of Higher Education, Tumkur",
    "collegeCode": "KR00016",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef8",
    "collegeName": "Rai Technology University, Bangalore",
    "collegeCode": "KR00013",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef1",
    "collegeName": "Adichunchanagiriuniversity,Mandya",
    "collegeCode": "KR00001",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee8",
    "collegeName": "Amity University , Ranchi",
    "collegeCode": "JH00001",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee9",
    "collegeName": " ARKA Jain University",
    "collegeCode": "JH00003",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeea",
    "collegeName": "Capital University, Koderma",
    "collegeCode": "JH00005",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeeb",
    "collegeName": "Jharkhand Rai University",
    "collegeCode": "JH00006",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeec",
    "collegeName": "Radha Govind University",
    "collegeCode": "JH00007",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeed",
    "collegeName": "Ram Krishana Dharmarth Foundation University",
    "collegeCode": "JH00008",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeee",
    "collegeName": "Sai Nath University,Ranchi",
    "collegeCode": "JH00009",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeef",
    "collegeName": " Sarla Birla University",
    "collegeCode": "JH00010",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef0",
    "collegeName": "Usha Martin University",
    "collegeCode": "JH00011",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee7",
    "collegeName": " Shoolini University of Biotechnology and Management Sciences",
    "collegeCode": "HP00014",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee6",
    "collegeName": "Sri Sai University, Palampur",
    "collegeCode": "HP00013",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee5",
    "collegeName": "Manav Bharti University, Solan",
    "collegeCode": "HP00012",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee4",
    "collegeName": " Maharishi Markandeshwar University",
    "collegeCode": "HP00011",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee3",
    "collegeName": " jaypee university of information technology, Solan",
    "collegeCode": "HP00010",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee2",
    "collegeName": " Indus international university, Una",
    "collegeCode": "HP00009",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee1",
    "collegeName": "ICFAI University,Solan",
    "collegeCode": "HP00008",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee0",
    "collegeName": "Eternal University,Sirmour",
    "collegeCode": "HP00007",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedf",
    "collegeName": " Chitkara University,Solan",
    "collegeCode": "HP00006",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaede",
    "collegeName": "Career Point University, Hamirpur",
    "collegeCode": "HP00005",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedd",
    "collegeName": "Bahra University,Solan",
    "collegeCode": "HP00004",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedc",
    "collegeName": "Baddi University of Emerging Sciences andTechnologies,Solan",
    "collegeCode": "HP00003",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedb",
    "collegeName": "AP Goyal Shimla University,Shimla ",
    "collegeCode": "HP00002",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeda",
    "collegeName": " Arni University , Kangra",
    "collegeCode": "HP00001",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb2",
    "collegeName": "Al- Falah University,Faridabad",
    "collegeCode": "HR00001",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb3",
    "collegeName": "Amity University ,Gurugram",
    "collegeCode": "HR00002",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb4",
    "collegeName": " Sushant University (Formerly Ansal University)",
    "collegeCode": "HR00003",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb5",
    "collegeName": " Apeejay Stya University ,Gurugram",
    "collegeCode": "HR00004",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb6",
    "collegeName": "BML Munjal University, Gurugram",
    "collegeCode": "HR00005",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb7",
    "collegeName": " G D Goenka University, Sohna Road, Gurugram",
    "collegeCode": "HR00006",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb8",
    "collegeName": " Jagannath University ,Jhajjar",
    "collegeCode": "HR00008",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb9",
    "collegeName": "K R Mangalam University, Gurugram",
    "collegeCode": "HR00009",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeba",
    "collegeName": " Lingaya?s VidyapeethUniversity,",
    "collegeCode": "HR00010",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebb",
    "collegeName": " Maharishi Markandeshwar University ,Mullana,Amballa",
    "collegeCode": "HR00011",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebc",
    "collegeName": "Manav RachnaUniversity,Faridabad",
    "collegeCode": "HR00012",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebd",
    "collegeName": " M.V.N. University,Palwal",
    "collegeCode": "HR00013",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebe",
    "collegeName": " NIILM University,Kaithal",
    "collegeCode": "HR00014",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebf",
    "collegeName": " Om Sterling Global University, Hisar",
    "collegeCode": "HR00015",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec0",
    "collegeName": "PDM University,Bahadurgarh",
    "collegeCode": "HR00017",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec1",
    "collegeName": "SGT University,",
    "collegeCode": "HR00018",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec4",
    "collegeName": "GEETA University ",
    "collegeCode": "HR00022",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec2",
    "collegeName": " Starex University, Gurugram",
    "collegeCode": "HR00019",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb1",
    "collegeName": "UKA Tarsadia University",
    "collegeCode": "GU00030",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb0",
    "collegeName": " Swarnim Startup & Innovation University",
    "collegeCode": "GU00029",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaf",
    "collegeName": "Silver Oak University",
    "collegeCode": "GU00028",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeae",
    "collegeName": " Sankalchand Patel University",
    "collegeCode": "GU00026",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaead",
    "collegeName": "Rai University,Ahmedabad",
    "collegeCode": "GU00024",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea8",
    "collegeName": "Monark University",
    "collegeCode": "GU00018",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea6",
    "collegeName": "Karnavati University",
    "collegeCode": "GU00016",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea5",
    "collegeName": " Kadi Sarva Vishwavidyalaya",
    "collegeCode": "GU00015",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea4",
    "collegeName": "ITM (SLS) Baroda University",
    "collegeCode": "GU00014",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9f",
    "collegeName": " Ganpat University,Mehsana",
    "collegeCode": "GU00008",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9e",
    "collegeName": "Darshan University",
    "collegeCode": "GU00006",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9d",
    "collegeName": " Charotar University of Science and Technology(CHARUSAT),Anand",
    "collegeCode": "GU00005",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9c",
    "collegeName": "Bhagwan Mahavir University",
    "collegeCode": "GU00004",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9b",
    "collegeName": "Amitya University,Rajkot",
    "collegeCode": "GU00002",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "branch": "Mechanical Engineering",
    "programme": "Engineering",
    "state": "WEST BENGAL"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=6120235644&tracking_id=112839382461&bank_ref_no=309157564933&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=25000.00&billing_name=Avinandan Mukherjee&billing_address=Agarpara&billing_city=kolkata&billing_state=west bengal&billing_zip=700058&billing_country=India&billing_tel=9836714226&billing_email=avinandanmukherjee.2003@gmail.com&delivery_name=Avinandan Mukherjee&delivery_address=Agarpara&delivery_city=kolkata&delivery_state=west bengal&delivery_zip=700058&delivery_country=India&delivery_tel=9836714226&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=01/04/2023 18:20:22&bin_country=",
    "_id": "6428289455208530e812a8f5"
    }
    ],
    "action": "Freeze",
    "tenthCert": "168035315229510.pdf",
    "twelveCert": "168035316766312.pdf",
    "aadhaar": "1680353180529Avi.pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2df",
    "rank": "9825",
    "applicationNo": "28120238746",
    "amount": "1300",
    "studentName": "Arya Kumar",
    "emailId": "aryak.kum@gmail.com",
    "mobileNo": "9663334121",
    "dob": "10/19/2005",
    "address1": "A 206, Fern Saroj Apartment,",
    "address2": "7A Cross, LBS Nagar",
    "city": "Bangalore",
    "state": "Karnataka",
    "pincode": "560017",
    "gender": "Male",
    "registeredDate": "28-1-2023",
    "paymentStatus": "Success",
    "fatherName": "Amarjeet Kumar",
    "motherName": "Ranu Thakur Kumar",
    "photo": "https://ucarecdn.com/dc9d07a7-a9e7-4c52-bc3f-0007f752735c/-/crop/413x413/0,19/-/preview/",
    "signature": "https://ucarecdn.com/0752a02f-0c75-4a5a-9234-e3976601257f/-/crop/833x334/0,1/-/preview/",
    "markObtained": "191",
    "phy": "36",
    "che": "48",
    "bio": "49",
    "gk": "58",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "Arya@1977",
    "__v": 3,
    "date": "2023-03-01T04:43:38.050Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=28120238746&tracking_id=112808948019&bank_ref_no=000733&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=5300.00&billing_name=Arya Kumar&billing_address=A 206, Fern Saroj Apartment,&billing_city=Bangalore&billing_state=Karnataka&billing_zip=560017&billing_country=India&billing_tel=9663334121&billing_email=aryak.kum@gmail.com&delivery_name=Arya Kumar&delivery_address=A 206, Fern Saroj Apartment,&delivery_city=Bangalore&delivery_state=Karnataka&delivery_zip=560017&delivery_country=India&delivery_tel=9663334121&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=01/03/2023 10:13:37&bin_country=INDIA",
    "_id": "63fed7faa9acd6bdda329d49"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf43",
    "collegeName": "Amrita VishwaVidyapeetham,Coimbatore",
    "collegeCode": "TM00001",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf43",
    "collegeName": "Amrita VishwaVidyapeetham,Coimbatore",
    "collegeCode": "TM00001",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf43",
    "collegeName": "Amrita VishwaVidyapeetham,Coimbatore",
    "collegeCode": "TM00001",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb0bb",
    "collegeName": "NEW HORIZON COLLEGE OF ENGINEERING ",
    "collegeCode": "KR00082",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf45",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf45",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf45",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "MAHARASHTRA"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "",
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2e2",
    "rank": "9212",
    "applicationNo": "30120234461",
    "amount": "1300",
    "studentName": "JAY GOPE",
    "emailId": "jaygope700@gmail.com",
    "mobileNo": "9641590252",
    "dob": "8/1/2001",
    "address1": "BANKOLA COLLIERY 4NO.UKHRA",
    "address2": "PASCHIM BURDWAN",
    "city": "DURGAPUR",
    "state": "WEST BENGAL",
    "pincode": "713363",
    "gender": "Male",
    "registeredDate": "30-1-2023",
    "paymentStatus": "Success",
    "fatherName": "OM PRAKASH GOPE",
    "motherName": "SABITA GOPE",
    "photo": "https://ucarecdn.com/d78b1d40-685b-433a-b300-88a78341dfae/-/crop/248x248/0,20/-/preview/",
    "signature": "https://ucarecdn.com/a1745396-6334-4a83-ac4b-3149bf5d5e52/-/crop/132x53/23,3/-/preview/",
    "markObtained": "215",
    "phy": "42",
    "che": "52",
    "bio": "38",
    "gk": "83",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "499977",
    "__v": 4,
    "date": "2023-02-26T10:06:23.666Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=30120234461&tracking_id=112806302179&bank_ref_no=305785739851&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=JAY GOPE&billing_address=BANKOLA COLLIERY 4NO.UKHRA&billing_city=Burdwan&billing_state=West Bengal&billing_zip=713363&billing_country=India&billing_tel=9641590252&billing_email=jaygope700@gmail.com&delivery_name=JAY GOPE&delivery_address=BANKOLA COLLIERY 4NO.UKHRA&delivery_city=Burdwan&delivery_state=West Bengal&delivery_zip=713363&delivery_country=India&delivery_tel=9641590252&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=26/02/2023 15:36:19&bin_country=",
    "_id": "63fb2f1f3e15a807c907c74f"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaec8",
    "collegeName": "G.L.A. University,Mathura",
    "collegeCode": "UP00005",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec6",
    "collegeName": "Babu Banarasi Das University, Lucknow",
    "collegeCode": "UP00003",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeca",
    "collegeName": "IIMT University, Meerut",
    "collegeCode": "UP00008",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed0",
    "collegeName": "Rama University, Kanpur,Delhi Ncr",
    "collegeCode": "UP00014",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5b",
    "collegeName": "Adamas University,Kolkata",
    "collegeCode": "WB00001",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5d",
    "collegeName": "JIS University,Kolkata",
    "collegeCode": "WB00004",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5c",
    "collegeName": "Brainware University, Kolkata",
    "collegeCode": "WB00003",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5f",
    "collegeName": " Swami Vivekananda University",
    "collegeCode": "WB00006",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf61",
    "collegeName": "The Neotia University, Sarisa",
    "collegeCode": "WB00008",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5e",
    "collegeName": "Sister Nivedita University, Kolkata",
    "collegeCode": "WB00005",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf62",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4e",
    "collegeName": "ICFAI Foundation for Higher Education,",
    "collegeCode": "TL00004",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed9",
    "collegeName": " The Glocal University,Saharanpur",
    "collegeCode": "UP00023",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed6",
    "collegeName": "Swami Vivekanand Subharti University, Meerut",
    "collegeCode": "UP00020",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed8",
    "collegeName": " Teerthanker Mahaveer University, Moradabad",
    "collegeCode": "UP00022",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf16",
    "collegeName": "Sanjay GhodawatUniversity,Kolhapur",
    "collegeCode": "MH00005",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf24",
    "collegeName": "Sri Guru Granth Sahib World University",
    "collegeCode": "PN00011",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf23",
    "collegeName": "Sant Baba Bhag Singh University",
    "collegeCode": "PN00010",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf22",
    "collegeName": " RIMT University,Gobindgarh",
    "collegeCode": "PN00009",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf21",
    "collegeName": "Rayat Bahra University,Mohali",
    "collegeCode": "PN00008",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1f",
    "collegeName": " Guru Kashi University,Bhatinda",
    "collegeCode": "PN00006",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1e",
    "collegeName": " GNA University,Kapurthala",
    "collegeCode": "PN00005",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1d",
    "collegeName": "D.A.V. University,Jalandhar",
    "collegeCode": "PN00004",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1c",
    "collegeName": " C.T. University,Ludhiana",
    "collegeCode": "PN00003",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef8",
    "collegeName": "Rai Technology University, Bangalore",
    "collegeCode": "KR00013",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef1",
    "collegeName": "Adichunchanagiriuniversity,Mandya",
    "collegeCode": "KR00001",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefc",
    "collegeName": "Srinivas University, Mangalore",
    "collegeCode": "KR00017",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1a",
    "collegeName": " GIET University,Gunupur",
    "collegeCode": "OD00004",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae92",
    "collegeName": " ICFAI university, Durg",
    "collegeCode": "CH00002",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Electrical Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "G.L.A. University,Mathura",
    "collegeCode": "UP00005",
    "branch": "Electrical Engineering",
    "programme": "Engineering",
    "state": "UTTAR PRADESH"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=30120234461&tracking_id=112838044470&bank_ref_no=099131&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=RuPay&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=25000.00&billing_name=JAY GOPE&billing_address=BANKOLA COLLIERY 4NO.UKHRA&billing_city=DURGAPUR&billing_state=WEST BENGAL&billing_zip=713363&billing_country=India&billing_tel=9641590252&billing_email=jaygope700@gmail.com&delivery_name=JAY GOPE&delivery_address=BANKOLA COLLIERY 4NO.UKHRA&delivery_city=DURGAPUR&delivery_state=WEST BENGAL&delivery_zip=713363&delivery_country=India&delivery_tel=9641590252&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=31/03/2023 16:36:40&bin_country=INDIA",
    "_id": "6426bedb55208530e812a769"
    }
    ],
    "action": "Freeze",
    "tenthCert": "1680259685770Adobe Scan 21-Jun-2022.pdf",
    "aadhaar": "1680260667254Adobe Scan 31-Mar-2023 (3).pdf",
    "twelveCert": "1680260681918Adobe Scan 31-Mar-2023 (2).pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2dd",
    "rank": "6109",
    "applicationNo": "28120239150",
    "amount": "1300",
    "studentName": "KOMAL JEET",
    "emailId": "kajalkumarimuz26@gmail.com",
    "mobileNo": "8340645295",
    "dob": "1/11/2005",
    "address1": "Radha Krishna kedia balughat muzaffarpur",
    "address2": "Banaras bank chowk muzaffarpur",
    "city": "Muzaffarpur",
    "state": "Bihar",
    "pincode": "842001",
    "gender": "Female",
    "registeredDate": "28-1-2023",
    "paymentStatus": "Success",
    "fatherName": "Mr. Jitendra Kumar",
    "motherName": "Mrs. Poonam Chaudhary",
    "photo": "https://ucarecdn.com/5b9186bf-f12a-41e1-82d9-11c88f8ef3eb/-/crop/564x564/0,53/-/preview/",
    "signature": "https://ucarecdn.com/d740f4a6-1d78-4465-acd2-90c9e958335b/-/crop/1420x570/0,67/-/preview/",
    "markObtained": "287",
    "phy": "77",
    "che": "70",
    "bio": "53",
    "gk": "87",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "263306",
    "__v": 4,
    "date": "2023-03-01T04:19:39.161Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=28120239150&tracking_id=112808921987&bank_ref_no=234201&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=RuPay&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=5300.00&billing_name=KOMAL JEET&billing_address=Radha Krishna kedia balughat muzaffarpur&billing_city=Muzaffarpur&billing_state=Bihar&billing_zip=842001&billing_country=India&billing_tel=8340645295&billing_email=kajalkumarimuz26@gmail.com&delivery_name=KOMAL JEET&delivery_address=Radha Krishna kedia balughat muzaffarpur&delivery_city=Muzaffarpur&delivery_state=Bihar&delivery_zip=842001&delivery_country=India&delivery_tel=8340645295&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=01/03/2023 09:49:36&bin_country=INDIA",
    "_id": "63fed25ba9acd6bdda329d23"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b4",
    "collegeName": "INSTITUTE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "WB00040",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b9",
    "collegeName": "NARULA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00048",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b6",
    "collegeName": "JIS COLLEGE OF ENGINEERING",
    "collegeCode": "WB00045",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25f",
    "collegeName": "GREATER NOIDA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UP00047",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb265",
    "collegeName": "IIMT COLLEGE OF ENGINEERING",
    "collegeCode": "UP00054",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb271",
    "collegeName": "NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY ",
    "collegeCode": "UP00071",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb26e",
    "collegeName": "MANGALMAY GROUP OF INSTITUTION ",
    "collegeCode": "UP00067",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb266",
    "collegeName": "IIMT ENGINEERING COLLEGE",
    "collegeCode": "UP00055",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf27",
    "collegeName": " Banasthali Vidyapith",
    "collegeCode": "RJ00003",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf42",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a8",
    "collegeName": "DR. B.C. ROY ENGINEERING COLLEGE, DURGAPUR",
    "collegeCode": "WB00024",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b0",
    "collegeName": "GURUNANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00033",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c2",
    "collegeName": "ST. THOMAS' COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00060",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ad",
    "collegeName": "FUTURE INSTITUTE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "WB00029",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb26d",
    "collegeName": "LLOYD COLLEGE OF ENGINEERING",
    "collegeCode": "UP00066",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae99",
    "collegeName": "Kalinga University",
    "collegeCode": "CH00010",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1d1",
    "collegeName": "LUDHIANA GROUP OF COLLEGES",
    "collegeCode": "PN00045",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf25",
    "collegeName": " Amity University, Rajasthan",
    "collegeCode": "RJ00001",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29a",
    "collegeName": "TULAS INSTITUTE",
    "collegeCode": "UT00035",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28d",
    "collegeName": "DEV BHOOMI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UT00018",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "JIS COLLEGE OF ENGINEERING",
    "collegeCode": "WB00045",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "WEST BENGAL"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=28120239150&tracking_id=112842319004&bank_ref_no=890204&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=RuPay&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=25000.00&billing_name=KOMAL JEET&billing_address=Radha Krishna kedia balughat muzaffarpur&billing_city=Muzaffarpur&billing_state=Bihar&billing_zip=842001&billing_country=India&billing_tel=8340645295&billing_email=kajalkumarimuz26@gmail.com&delivery_name=KOMAL JEET&delivery_address=Radha Krishna kedia balughat muzaffarpur&delivery_city=Muzaffarpur&delivery_state=Bihar&delivery_zip=842001&delivery_country=India&delivery_tel=8340645295&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=04/04/2023 15:24:24&bin_country=INDIA",
    "_id": "642bf3d155208530e812ab73"
    }
    ],
    "action": "Freeze",
    "tenthCert": "1680601314919komalclass10.pdf",
    "aadhaar": "1680601344574komaladhar.pdf",
    "twelveCert": "1680601370048komalclass12.pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2d3",
    "rank": "6471",
    "applicationNo": "231220221115",
    "amount": "1300",
    "studentName": "Anupam Kumar",
    "emailId": "kumarsinghanupam15853s@gmail.com",
    "mobileNo": "7717741346",
    "dob": "4/26/2005",
    "address1": "Jco's QTR NO. 52/2 NDA Khadakwasla pune",
    "address2": "near nda so post office",
    "city": "pune",
    "state": "MAHARASHTRA",
    "pincode": "411023",
    "gender": "Male",
    "registeredDate": "23-12-2022",
    "paymentStatus": "Success",
    "fatherName": "Pankaj Kumar Singh",
    "motherName": "Pinki Kumari",
    "photo": "https://ucarecdn.com/28159f00-7ff0-49bc-aeb4-3a93d054eb42/-/crop/503x504/0,0/-/preview/",
    "signature": "https://ucarecdn.com/389ad41a-1fac-402f-8fd2-0258e1162923/-/crop/675x270/13,0/-/preview/",
    "markObtained": "276",
    "phy": "77",
    "che": "64",
    "bio": "45",
    "gk": "90",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "360421",
    "__v": 4,
    "date": "2023-02-28T08:46:35.316Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=231220221115&tracking_id=112807400593&bank_ref_no=null&order_status=Timeout&failure_message=&payment_mode=null&card_name=&status_code=&status_message=Session Expired&currency=INR&amount=5300.00&billing_name=Anupam Kumar&billing_address=Jcos QTR NO. 52/2 NDA Khadakwasla pune&billing_city=pune&billing_state=MAHARASHTRA&billing_zip=&billing_country=India&billing_tel=&billing_email=kumarsinghanupam15853s@gmail.com&delivery_name=&delivery_address=&delivery_city=&delivery_state=&delivery_zip=&delivery_country=&delivery_tel=&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=null&bin_country=",
    "_id": "63fdbf6b3e15a807c907cb74"
    },
    {
    "paymentStatus": "order_id=231220221115&tracking_id=112808071737&bank_ref_no=305907522725&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Anupam Kumar&billing_address=Atul nagar warje pune&billing_city=PUNE&billing_state=MAHARASHTRA&billing_zip=411058&billing_country=India&billing_tel=7060088746&billing_email=kumarsinghanupam15853s@gmail.com&delivery_name=Anupam Kumar&delivery_address=Atul nagar warje pune&delivery_city=PUNE&delivery_state=MAHARASHTRA&delivery_zip=411058&delivery_country=India&delivery_tel=7060088746&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=28/02/2023 14:18:44&bin_country=",
    "_id": "63fdbff43e15a807c907cb83"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bb",
    "collegeName": "NEW HORIZON COLLEGE OF ENGINEERING ",
    "collegeCode": "KR00082",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c5",
    "collegeName": "S.D.M. COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "KR00096",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0ad",
    "collegeName": "JYOTHY INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00061",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8a",
    "collegeName": "SRM University,AP",
    "collegeCode": "AP00008",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0e6",
    "collegeName": "JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b2",
    "collegeName": "KLE'S KLE COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00067",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a4",
    "collegeName": "CALCUTTA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00020",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf61",
    "collegeName": "The Neotia University, Sarisa",
    "collegeCode": "WB00008",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5b",
    "collegeName": "Adamas University,Kolkata",
    "collegeCode": "WB00001",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb099",
    "collegeName": "BANGALORE TECHNOLOGICAL INSTITUTE",
    "collegeCode": "KR00030",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0ab",
    "collegeName": "JAIN DEEMED TO BE UNIVERSITY FACULTY OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00059",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0aa",
    "collegeName": "JAIN COLLEGE OF ENGINEERING AND RESEARCH",
    "collegeCode": "KR00058",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0a9",
    "collegeName": "JAIN COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "KR00057",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb226",
    "collegeName": "CMR COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00019",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb227",
    "collegeName": "CMR ENGINEERING COLLEGE",
    "collegeCode": "TL00021",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b4",
    "collegeName": "MALNAD COLLEGE OF ENGINEERING",
    "collegeCode": "KR00071",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b5",
    "collegeName": "MANGALORE INSTITUTE OF TECHNOLOGY & ENGINEERING",
    "collegeCode": "KR00072",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb102",
    "collegeName": "T.K.M INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00046",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf9c",
    "collegeName": "GMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00079",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafdf",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00158",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafef",
    "collegeName": "VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE",
    "collegeCode": "AP00176",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf92",
    "collegeName": "GANDHI INSTITUTE OF TECHNOLOGY AND MANAGEMENT GITAM VISAKHAPATNAM",
    "collegeCode": "AP00068",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb234",
    "collegeName": "KONERU LAKSHMAIAH EDUCATION FOUNDATION DEEMED TO B UNIVERSITY OFF CAMPUS AZIZNAGAR HYDERABAD",
    "collegeCode": "TL00035",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "GUJARAT"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "loginEnabled": false
    },
    {
    "_id": "63f099b083839be510e8a2de",
    "rank": "8745",
    "applicationNo": "28120236197",
    "amount": "1300",
    "studentName": "SHEWATKAR AKASH VIJAY",
    "emailId": "akashshewatkar27@gmail.com",
    "mobileNo": "8208684116",
    "dob": "7/1/2004",
    "address1": "NEAR DATTA CHOWK BEHIND GOVT GODAUN KATHIPURA",
    "address2": "ANJANGAON SURJI",
    "city": "AMRAVATI",
    "state": "MAHARASHTRA",
    "pincode": "444705",
    "gender": "Male",
    "registeredDate": "28-1-2023",
    "paymentStatus": "Success",
    "fatherName": "VIJAY PANJABRAO SHEWATKAR",
    "motherName": "KAVITA",
    "photo": "https://ucarecdn.com/10275f6e-0cbb-43be-9cec-8611a2837324/-/crop/413x413/0,12/-/preview/",
    "signature": "https://ucarecdn.com/cc1ce66c-fbde-4329-9abd-bf5b8de39433/-/crop/413x165/0,5/-/preview/",
    "markObtained": "233",
    "phy": "57",
    "che": "56",
    "bio": "40",
    "gk": "80",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "614761",
    "__v": 3,
    "date": "2023-02-28T12:13:15.561Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=28120236197&tracking_id=112808338901&bank_ref_no=305990949949&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=SHEWATKAR AKASH VIJAY&billing_address=NEAR DATTA CHOWK BEHIND GOVT GODAUN KATHIPURA ANJANGAON SURJI&billing_city=Amravati&billing_state=Maharashtra&billing_zip=444705&billing_country=India&billing_tel=8208684116&billing_email=akashshewatkar27@gmail.com&delivery_name=Akash shewatkar &delivery_address=NEAR DATTA CHOWK BEHIND GOVT GODAUN KATHIPURA ANJANGAON SURJI&delivery_city=Amravati&delivery_state=Maharashtra&delivery_zip=444705&delivery_country=India&delivery_tel=8208684116&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=28/02/2023 17:43:11&bin_country=",
    "_id": "63fdefdb3e15a807c907cbe2"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb145",
    "collegeName": "BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI",
    "collegeCode": "MH00013",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf00",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MP00004",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf07",
    "collegeName": "People?s University, Bhopal",
    "collegeCode": "MP00012",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf09",
    "collegeName": "RKDF University, Bhopal",
    "collegeCode": "MP00014",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf0a",
    "collegeName": "Rabindranath Tagore University (Formerly AISECTUniversity),Bhopal",
    "collegeCode": "MP00015",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf0c",
    "collegeName": "Madhyanchal Professional University,Bhopal",
    "collegeCode": "MP00018",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf01",
    "collegeName": " Dr. A.P.J. Abdul KalamUniversity,Indore",
    "collegeCode": "MP00005",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf06",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MP00011",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf0b",
    "collegeName": " Sage university ,Indore",
    "collegeCode": "MP00017",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf12",
    "collegeName": " Sarvepalli Radhakrishnan University",
    "collegeCode": "MP00024",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaefe",
    "collegeName": " Avantika University,Ujjain",
    "collegeCode": "MP00002",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9b",
    "collegeName": "Amitya University,Rajkot",
    "collegeCode": "GU00002",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea1",
    "collegeName": " GSFC University,Vadodara",
    "collegeCode": "GU00011",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea4",
    "collegeName": "ITM (SLS) Baroda University",
    "collegeCode": "GU00014",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea3",
    "collegeName": "Institute of Advanced Research",
    "collegeCode": "GU00013",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeab",
    "collegeName": " P.P. Savani University",
    "collegeCode": "GU00022",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeac",
    "collegeName": " R.K. University",
    "collegeCode": "GU00023",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea6",
    "collegeName": "Karnavati University",
    "collegeCode": "GU00016",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9c",
    "collegeName": "Bhagwan Mahavir University",
    "collegeCode": "GU00004",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "MAHARASHTRA"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "tenthCert": "1688577623001SSC.pdf",
    "aadhaar": "1688577647939AKASH SHEWATKAR UID668.pdf",
    "twelveCert": "1688577677681HSC.pdf",
    "otherDocs": "1688577719064akash jee main final result.pdf",
    "choiceFillingSpotRound": [
    {
    "id": "64017440560a04ddc6dbb15b",
    "collegeName": "MET'S INSTITUTE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00037",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb15b",
    "collegeName": "MET'S INSTITUTE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00037",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electronics & Communication Engineering"
    }
    ],
    "choiceFillingSpotRound1Submit": true,
    "loginEnabled": false
    },
    {
    "_id": "63f099b083839be510e8a2e6",
    "rank": "11224",
    "applicationNo": "30120235959",
    "amount": "1300",
    "studentName": "Kaivalya Agarkar",
    "emailId": "kaivalyaagarkar@gmail.com",
    "mobileNo": "7447417883",
    "dob": "3/19/2005",
    "address1": "House no 515",
    "address2": "kirloskarwadi. District-Sangli. Maharashtra 416308",
    "city": "Kirloskarwadi",
    "state": "MAHARASTRA",
    "pincode": "416308",
    "gender": "Male",
    "registeredDate": "30-1-2023",
    "paymentStatus": "Success",
    "fatherName": "Sunil",
    "motherName": "Manjusha",
    "photo": "https://ucarecdn.com/135a9708-ac62-49ca-b7b0-629bf5c3f3ca/-/crop/1032x1031/0,0/-/preview/",
    "signature": "https://ucarecdn.com/2cb2c0a0-a7b7-4c4a-b247-bdeba1a13b26/-/crop/459x183/0,120/-/preview/",
    "markObtained": "181",
    "phy": "30",
    "che": "58",
    "bio": "19",
    "gk": "74",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "271954",
    "__v": 3,
    "date": "2023-02-27T16:53:39.399Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=30120235959&tracking_id=112807550016&bank_ref_no=216927&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=MasterCard&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=5300.00&billing_name=Kaivalya Agarkar&billing_address=House no 515&billing_city=Sangli&billing_state=Maharashtra&billing_zip=416308&billing_country=India&billing_tel=7447417883&billing_email=kaivalyaagarkar@gmail.com&delivery_name=Kaivalya Agarkar&delivery_address=House no 515&delivery_city=Sangli&delivery_state=Maharashtra&delivery_zip=416308&delivery_country=India&delivery_tel=7447417883&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=27/02/2023 22:23:37&bin_country=INDIA",
    "_id": "63fce0133e15a807c907ca71"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb176",
    "collegeName": "YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING",
    "collegeCode": "MH00070",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb15b",
    "collegeName": "MET'S INSTITUTE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00037",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16c",
    "collegeName": "SKN SINHGAD INSTITUTE OF TECHNOLOGY & SCIENCE",
    "collegeCode": "MH00058",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb145",
    "collegeName": "BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI",
    "collegeCode": "MH00013",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16e",
    "collegeName": "ST. VINCENT PALLOTTI COLLEGE OF ENGG. & TECHNOLOGY, NAGPUR",
    "collegeCode": "MH00060",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb147",
    "collegeName": "D. Y. PATIL COLLEGE OF ENGINEERING & TECHNOLOGY ,KOLHAPUR",
    "collegeCode": "MH00015",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf16",
    "collegeName": "Sanjay GhodawatUniversity,Kolhapur",
    "collegeCode": "MH00005",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0ab",
    "collegeName": "JAIN DEEMED TO BE UNIVERSITY FACULTY OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00059",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb165",
    "collegeName": "SANDIP INSTITUE OF ENGINEERING & MANAGEMENT,NASHIK",
    "collegeCode": "MH00051",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb100",
    "collegeName": "ST. JOSEPH'S COLLEGE OF ENGINEERING & TECHNOLOGY, PALAI",
    "collegeCode": "KR00044",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaece",
    "collegeName": "Jaypee Institute of Information Technology, Noida",
    "collegeCode": "UP00012",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafdf",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00158",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf9c",
    "collegeName": "GMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00079",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf93",
    "collegeName": "GANDHIJI INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "AP00069",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8a",
    "collegeName": "SRM University,AP",
    "collegeCode": "AP00008",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf27",
    "collegeName": " Banasthali Vidyapith",
    "collegeCode": "RJ00003",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb202",
    "collegeName": "POORNIMA COLLEGE OF ENGINEERING",
    "collegeCode": "RJ00070",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb213",
    "collegeName": "VIVEKANANDA INSTITUTE OF TECHNOLOGY, JAIPUR",
    "collegeCode": "RJ00087",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf14",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0aa",
    "collegeName": "JAIN COLLEGE OF ENGINEERING AND RESEARCH",
    "collegeCode": "KR00058",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf42",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf41",
    "collegeName": "University of Engineering & Management, Jaipur,",
    "collegeCode": "RJ00033",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafde",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING",
    "collegeCode": "AP00157",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf92",
    "collegeName": "GANDHI INSTITUTE OF TECHNOLOGY AND MANAGEMENT GITAM VISAKHAPATNAM",
    "collegeCode": "AP00068",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0a9",
    "collegeName": "JAIN COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "KR00057",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b2",
    "collegeName": "KLE'S KLE COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00067",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0d7",
    "collegeName": "VIVEKANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00117",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee8",
    "collegeName": "Amity University , Ranchi",
    "collegeCode": "JH00001",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb166",
    "collegeName": "SANGHAVI COLLEGE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00052",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16d",
    "collegeName": "ST. JOHN COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "MH00059",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "ST. VINCENT PALLOTTI COLLEGE OF ENGG. & TECHNOLOGY, NAGPUR",
    "collegeCode": "MH00060",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "MAHARASHTRA"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "tenthCert": "1688486408257kaivalya 10 th marksheet.pdf",
    "aadhaar": "1688486422577ADAHAR CARD.pdf",
    "twelveCert": "1688486450758Kaivalya 12th marksheet.pdf",
    "otherDocs": "1688655334419kaivalya mht result.pdf",
    "choiceFillingSpotRound": [
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb176",
    "collegeName": "YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING",
    "collegeCode": "MH00070",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1e8",
    "collegeName": "B.K. BIRLA INSTITUTE OF ENGINEERING AND TECHNOLOGY, JHUNJHUNU",
    "collegeCode": "RJ00043",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound1Submit": true,
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2e5",
    "rank": "9402",
    "applicationNo": "3012023792",
    "amount": "1300",
    "studentName": "khwrwmdao boro",
    "emailId": "khwramdawboro@gmail.com",
    "mobileNo": "6001069845",
    "dob": "4/23/2005",
    "address1": "2NO.Goreswar",
    "address2": "Bathouphuri",
    "city": "Goreswar",
    "state": "Assam",
    "pincode": "781366",
    "gender": "Male",
    "registeredDate": "30-1-2023",
    "paymentStatus": "Success",
    "fatherName": "Rahi Boro",
    "motherName": "Sambari Boro",
    "photo": "https://ucarecdn.com/0d6aae4c-400e-4bb1-bd9a-1a3599f99667/-/crop/790x789/0,37/-/preview/",
    "signature": "https://ucarecdn.com/b97b3b25-f33c-4ac4-9196-87324975567c/-/crop/904x361/0,20/-/preview/",
    "markObtained": "208",
    "phy": "49",
    "che": "57",
    "bio": "32",
    "gk": "70",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "630076",
    "__v": 7,
    "date": "2023-02-28T13:21:24.037Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=3012023792&tracking_id=112808417026&bank_ref_no=null&order_status=Failure&failure_message=&payment_mode=Debit Card&card_name=State Bank of India&status_code=null&status_message=REJECTED BY SWITCH(12&currency=INR&amount=5300.00&billing_name=khwrwmdao boro&billing_address=2NO.Goreswar&billing_city=Goreswar&billing_state=Assam&billing_zip=781366&billing_country=India&billing_tel=9957901644 &billing_email=borokhwrwmdao17@gmail.com &delivery_name=khwrwmdao boro&delivery_address=2NO.Goreswar&delivery_city=Goreswar&delivery_state=Assam&delivery_zip=781366&delivery_country=India&delivery_tel=9957901644 &merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=28/02/2023 18:50:51&bin_country=INDIA",
    "_id": "63fdffd43e15a807c907cc06"
    },
    {
    "paymentStatus": "order_id=3012023792&tracking_id=112808441362&bank_ref_no=305991186213&order_status=Failure&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Initiated but Interval Time Out-NA-0&currency=INR&amount=5300.00&billing_name=khwrwmdao boro&billing_address=Balabari No. 1, District Baksa&billing_city=guwahati&billing_state=ASS&billing_zip=781366&billing_country=India&billing_tel=9957901644&billing_email=khwramdawboro@gmail.com&delivery_name=khwrwmdao boro&delivery_address=Balabari No. 1, District Baksa&delivery_city=guwahati&delivery_state=ASS&delivery_zip=781366&delivery_country=India&delivery_tel=9957901644&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=28/02/2023 19:03:19&bin_country=",
    "_id": "63fe02a53e15a807c907cc27"
    },
    {
    "paymentStatus": "order_id=3012023792&tracking_id=112808503269&bank_ref_no=null&order_status=Awaited&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=null&currency=INR&amount=5300.00&billing_name=khwrwmdao boro&billing_address=2NO.Goreswar&billing_city=Goreswar&billing_state=Assam&billing_zip=781366&billing_country=India&billing_tel=9957901644&billing_email=khwramdawboro@gmail.com&delivery_name=khwrwmdao boro&delivery_address=2NO.Goreswar&delivery_city=Goreswar&delivery_state=Assam&delivery_zip=781366&delivery_country=India&delivery_tel=9957901644&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=28/02/2023 19:52:00&bin_country=",
    "_id": "63fe0e0b3e15a807c907cc68"
    },
    {
    "paymentStatus": "order_id=3012023792&tracking_id=112808811148&bank_ref_no=null&order_status=Failure&failure_message=&payment_mode=Debit Card&card_name=RuPay&status_code=null&status_message=REJECTED BY GATEWAY(IPAY0100180 - Authentication not available)&currency=INR&amount=5300.00&billing_name=khwrwmdao boro&billing_address=2NO.Goreswar&billing_city=Goreswar&billing_state=Assam&billing_zip=781366&billing_country=India&billing_tel=9957901644 &billing_email=khwramdawboro@gmail.com&delivery_name=Khwrwmdao boro&delivery_address=No. 2 Goreswar, Bathouphuri&delivery_city=Kamrup&delivery_state=Assam&delivery_zip=781366&delivery_country=India&delivery_tel=9957901644&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=01/03/2023 07:33:46&bin_country=INDIA",
    "_id": "63feb349a9acd6bdda329c33"
    },
    {
    "paymentStatus": "order_id=3012023792&tracking_id=112808825470&bank_ref_no=306092331810&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=khwrwmdao boro&billing_address=2NO.Goreswar&billing_city=Goreswar&billing_state=Assam&billing_zip=781366&billing_country=India&billing_tel=9957901644&billing_email=khwramdawboro@gmail.com&delivery_name=khwrwmdao boro&delivery_address=2NO.Goreswar&delivery_city=Goreswar&delivery_state=Assam&delivery_zip=781366&delivery_country=India&delivery_tel=9957901644&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=01/03/2023 07:52:08&bin_country=",
    "_id": "63feb6d6a9acd6bdda329c66"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017441560a04ddc6dbb2b5",
    "collegeName": "INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "WB00041",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29f",
    "collegeName": "BENGAL INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00014",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29e",
    "collegeName": "BENGAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00013",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a5",
    "collegeName": "CAMELLIA INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "WB00021",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf62",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b7",
    "collegeName": "KANAD INSTITUTE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "WB00046",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c1",
    "collegeName": "SILIGURI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00058",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a4",
    "collegeName": "CALCUTTA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00020",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a6",
    "collegeName": "CAMELLIA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00022",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2aa",
    "collegeName": "DUMKAL INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00026",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ab",
    "collegeName": "DURGAPUR INSTITUTE OF ADVANCED TECHNOLOGY & MANAGEMENT",
    "collegeCode": "WB00027",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a1",
    "collegeName": "BIRBHUM INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00016",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb119",
    "collegeName": "IES COLLEGE OF TECHNOLOGY, BHOPAL",
    "collegeCode": "MP00033",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb140",
    "collegeName": "VINDHYA INSTITUTE OF TECHNOLOGY AND SC",
    "collegeCode": "MP00080",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb129",
    "collegeName": "ORIENTAL COLLEGE OF TECHNOLOGY",
    "collegeCode": "MP00053",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25f",
    "collegeName": "GREATER NOIDA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UP00047",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb287",
    "collegeName": "VIVEKANAND INSTITUTE OF TECHNOLOGY & SCIENCE",
    "collegeCode": "UP00096",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb295",
    "collegeName": "ROORKEE COLLEGE OF ENGINEERING",
    "collegeCode": "UT00028",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb17d",
    "collegeName": "CHHATTISGARH ENGINEERING COLLEGE",
    "collegeCode": "CH00016",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb180",
    "collegeName": "DR C.V.RAMAN INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "CH00019",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb13e",
    "collegeName": "VEDICA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "MP00078",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb11c",
    "collegeName": "ITM UNIVERSITY - GWALIOR (SCHOOL OF ENGINEERING & TECHNOLOGY)",
    "collegeCode": "MP00036",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0a",
    "collegeName": "Rabindranath Tagore University (Formerly AISECTUniversity),Bhopal",
    "collegeCode": "MP00015",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf06",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MP00011",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaecb",
    "collegeName": " IFTM University, Moradabad",
    "collegeCode": "UP00009",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed9",
    "collegeName": " The Glocal University,Saharanpur",
    "collegeCode": "UP00023",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb24b",
    "collegeName": "ACE COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "UP00025",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb24c",
    "collegeName": "ACN COLLEGE OF ENGG. & MANAGMENT STUDIES",
    "collegeCode": "UP00026",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb275",
    "collegeName": "R V INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UP00075",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "WB00041",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "WEST BENGAL"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2d8",
    "rank": "9576",
    "applicationNo": "61202368",
    "amount": "1300",
    "studentName": "DRISTI MOHANTY",
    "emailId": "dristimohanty1@gmail.com",
    "mobileNo": "8097091127",
    "dob": "2/19/2005",
    "address1": "A5-14-05, Millennium Towers, Sector-9,",
    "address2": "Sanpada, Navi Mumbai",
    "city": "Navi Mumbai",
    "state": "Maharashtra",
    "pincode": "400705",
    "gender": "Female",
    "registeredDate": "6/1/2023",
    "paymentStatus": "Success",
    "fatherName": "DIPTI RANJAN MOHANTY",
    "motherName": "CHUMUKI MOHANTY",
    "photo": "https://ucarecdn.com/8ee1eb67-f3b3-462c-9fc5-e561c14ebb83/-/crop/413x413/0,0/-/preview/",
    "signature": "https://ucarecdn.com/5fe26503-b7bc-4f5f-96d4-3245b0df48ef/-/crop/819x327/0,0/-/preview/",
    "markObtained": "205",
    "phy": "49",
    "che": "49",
    "bio": "27",
    "gk": "80",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "99656",
    "__v": 2,
    "date": "2023-02-28T10:03:09.799Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=61202368&tracking_id=112808167471&bank_ref_no=084841&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=SUCCESS-NA-00&currency=INR&amount=5300.00&billing_name=DRISTI MOHANTY&billing_address=A5-14-05, Millennium Towers, Sector-9,&billing_city=Navi Mumbai&billing_state=Maharashtra&billing_zip=400705&billing_country=India&billing_tel=8097091127&billing_email=dristimohanty1@gmail.com&delivery_name=DRISTI MOHANTY&delivery_address=A5-14-05, Millennium Towers, Sector-9,&delivery_city=Navi Mumbai&delivery_state=Maharashtra&delivery_zip=400705&delivery_country=India&delivery_tel=8097091127&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=Maharashtra and Odisha are the preference with CS as Branch.&trans_date=28/02/2023 15:33:09&bin_country=INDIA",
    "_id": "63fdd15d3e15a807c907cba8"
    }
    ],
    "counsellingFeeStatus": true,
    "action": "Exit",
    "allotedCollege": [
    {
    "collegeName": "NM INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "OD00036",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "ODISHA"
    }
    ],
    "choiceFillingRound1Submit": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a9",
    "collegeName": "NM INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "OD00036",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19b",
    "collegeName": "Gandhi Institute of Technological Advancement (GITA)",
    "collegeCode": "OD00021",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb194",
    "collegeName": "BHUBANESWAR COLLEGE OF ENGINEERING",
    "collegeCode": "OD00014",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb163",
    "collegeName": "S. B. PATIL COLLEGE OF ENGINEERING",
    "collegeCode": "MH00049",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb15f",
    "collegeName": "P K TECHNICAL CAMPUS,PUNE",
    "collegeCode": "MH00043",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb143",
    "collegeName": "ALARD COLLEGE OF ENGINEERING & MANAGEMENT ,PUNE",
    "collegeCode": "MH00010",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb160",
    "collegeName": "PADMASHRI DR. V.B. KOLTE COLLEGE OF ENGINEERING",
    "collegeCode": "MH00044",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb149",
    "collegeName": "DATTAKALA GROUP OF INSTITUTIONS, PUNE",
    "collegeCode": "MH00017",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb156",
    "collegeName": "JAIHIND COLLEGE OF ENGINEERING PUNE",
    "collegeCode": "MH00031",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14b",
    "collegeName": "DHOLE PATIL EDUCATION SOCIETY'S, DHOLE PATIL COLLEGE OF ENGINEERING. PUNE",
    "collegeCode": "MH00019",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb176",
    "collegeName": "YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING",
    "collegeCode": "MH00070",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb159",
    "collegeName": "KEYSTONE SCHOOL OF ENGINEERING, PUNE",
    "collegeCode": "MH00034",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb164",
    "collegeName": "SAHYADRI VALLEY COLLEGE OF ENGINEERING & TECHNOLOGY,PUNE",
    "collegeCode": "MH00050",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb233",
    "collegeName": "KLR COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00034",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf8a",
    "collegeName": "DR SAMUEL GEORGE INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00058",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf89",
    "collegeName": "DR LANKAPALLI BULLAYYA COLLEGE OF ENGINEERING",
    "collegeCode": "AP00057",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf91",
    "collegeName": "G.V.R & S COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00067",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef8",
    "collegeName": "Rai Technology University, Bangalore",
    "collegeCode": "KR00013",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "63f099b083839be510e8a2e4",
    "rank": "9306",
    "applicationNo": "30120231009",
    "amount": "1300",
    "studentName": "John Boro",
    "emailId": "johnboro419@gmail.com",
    "mobileNo": "6001069845",
    "dob": "3/22/2004",
    "address1": "2no Goreswar",
    "address2": "Goreswar",
    "city": "Goreswar",
    "state": "Assam",
    "pincode": "781366",
    "gender": "Male",
    "registeredDate": "30-1-2023",
    "paymentStatus": "Success",
    "fatherName": "Parameswar Boro",
    "motherName": "Bharati Boro",
    "photo": "https://ucarecdn.com/3ec358b8-8515-475a-800f-8d579e6042ac/-/crop/208x208/0,0/-/preview/",
    "signature": "https://ucarecdn.com/6ce933cf-e913-43f9-9ae6-a727ebe4c60a/-/crop/1101x441/145,0/-/preview/",
    "markObtained": "209",
    "phy": "54",
    "che": "54",
    "bio": "31",
    "gk": "70",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "password": "35174",
    "__v": 3,
    "date": "2023-03-01T03:46:30.872Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=30120231009&tracking_id=112808890607&bank_ref_no=183073&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=State Bank of India&status_code=null&status_message=SUCCESS&currency=INR&amount=5300.00&billing_name=John Boro&billing_address=2no Goreswar&billing_city=Goreswar&billing_state=Assam&billing_zip=781366&billing_country=India&billing_tel=6001069845&billing_email=johnboro419@gmail.com&delivery_name=John Boro&delivery_address=2no Goreswar&delivery_city=Goreswar&delivery_state=Assam&delivery_zip=781366&delivery_country=India&delivery_tel=6001069845&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=01/03/2023 09:15:42&bin_country=INDIA",
    "_id": "63feca96a9acd6bdda329cd2"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1": [
    {
    "id": "64017441560a04ddc6dbb2b5",
    "collegeName": "INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "WB00041",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29f",
    "collegeName": "BENGAL INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00014",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29e",
    "collegeName": "BENGAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00013",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a1",
    "collegeName": "BIRBHUM INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00016",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a5",
    "collegeName": "CAMELLIA INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "WB00021",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf62",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b7",
    "collegeName": "KANAD INSTITUTE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "WB00046",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a6",
    "collegeName": "CAMELLIA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00022",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ab",
    "collegeName": "DURGAPUR INSTITUTE OF ADVANCED TECHNOLOGY & MANAGEMENT",
    "collegeCode": "WB00027",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2aa",
    "collegeName": "DUMKAL INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00026",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a4",
    "collegeName": "CALCUTTA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00020",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a3",
    "collegeName": "CALCUTTA INSTITUTE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "WB00019",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb140",
    "collegeName": "VINDHYA INSTITUTE OF TECHNOLOGY AND SC",
    "collegeCode": "MP00080",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb13f",
    "collegeName": "VIDHYAPEETH INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "MP00079",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb13d",
    "collegeName": "VAISHNAVI INSTITUTES OF TECHNOLOGY AND SCIENCE",
    "collegeCode": "MP00077",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb287",
    "collegeName": "VIVEKANAND INSTITUTE OF TECHNOLOGY & SCIENCE",
    "collegeCode": "UP00096",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25f",
    "collegeName": "GREATER NOIDA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UP00047",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb298",
    "collegeName": "SRI SAI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UT00033",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb17d",
    "collegeName": "CHHATTISGARH ENGINEERING COLLEGE",
    "collegeCode": "CH00016",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb180",
    "collegeName": "DR C.V.RAMAN INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "CH00019",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb13e",
    "collegeName": "VEDICA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "MP00078",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb11c",
    "collegeName": "ITM UNIVERSITY - GWALIOR (SCHOOL OF ENGINEERING & TECHNOLOGY)",
    "collegeCode": "MP00036",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb119",
    "collegeName": "IES COLLEGE OF TECHNOLOGY, BHOPAL",
    "collegeCode": "MP00033",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0a",
    "collegeName": "Rabindranath Tagore University (Formerly AISECTUniversity),Bhopal",
    "collegeCode": "MP00015",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25a",
    "collegeName": "BBDIT COLLEGE OF PHARMACY",
    "collegeCode": "UP00041",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb259",
    "collegeName": "BANSAL INSTITUTE OF ENGINEERING AND TECNOLOGY",
    "collegeCode": "UP00040",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb24e",
    "collegeName": "ALLAHABAD COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "UP00028",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb24b",
    "collegeName": "ACE COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "UP00025",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb24c",
    "collegeName": "ACN COLLEGE OF ENGG. & MANAGMENT STUDIES",
    "collegeCode": "UP00026",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingRound1Submit": true,
    "allotedCollege": [
    {
    "collegeName": "INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "WB00041",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "WEST BENGAL"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "action": "Exit",
    "tenthCert": "1688490072265IMG_20230615_160426.jpg",
    "twelveCert": "1688662789250IMG_1687337731100.pdf",
    "otherDocs": "1688663059410IMG_20230706_223207.pdf",
    "aadhaar": "1688663473622IMG_20230706_223555.pdf",
    "loginEnabled": false
    },
    {
    "_id": "644e6f68deb66365311f35c8",
    "slNo": "88",
    "applicationNo": "27320237982",
    "studentName": "Shirsendu Chatterjee",
    "emailId": "chattshirsendu05@gmail.com",
    "password": "800217",
    "phy": "14",
    "che": "16",
    "math": "37",
    "gk": "14",
    "markObtained": "264",
    "rank": "13454",
    "mobileNo": "7061970690",
    "state": "Jharkhand",
    "fatherName": "Sumit Chatterjee",
    "Branch Pref": "Electronics & Communication Engg",
    "photo": "https://ucarecdn.com/1e50087c-62e4-4f08-9a62-878a579753c0/-/crop/339x340/0,12/-/preview/",
    "signature": "https://ucarecdn.com/ceeca525-c69d-4e1a-93c5-09034549d562/-/crop/459x184/217,0/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": "GREATER NOIDA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UP00047",
    "branch": "Electronics & Communication Engineering",
    "programme": "Engineering",
    "state": "UTTAR PRADESH"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017441560a04ddc6dbb2b4",
    "collegeName": "INSTITUTE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "WB00040",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28c",
    "collegeName": "COLLEGE OF ENGINEERING ROORKEE",
    "collegeCode": "UT00017",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a8",
    "collegeName": "DR. B.C. ROY ENGINEERING COLLEGE, DURGAPUR",
    "collegeCode": "WB00024",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25f",
    "collegeName": "GREATER NOIDA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UP00047",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafff",
    "collegeName": "NETAJI SUBHAS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "BR00014",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29c",
    "collegeName": "ASANSOL ENGINEERING COLLEGE",
    "collegeCode": "WB00011",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b4",
    "collegeName": "INSTITUTE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "WB00040",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28c",
    "collegeName": "COLLEGE OF ENGINEERING ROORKEE",
    "collegeCode": "UT00017",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a8",
    "collegeName": "DR. B.C. ROY ENGINEERING COLLEGE, DURGAPUR",
    "collegeCode": "WB00024",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb25f",
    "collegeName": "GREATER NOIDA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UP00047",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafff",
    "collegeName": "NETAJI SUBHAS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "BR00014",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29c",
    "collegeName": "ASANSOL ENGINEERING COLLEGE",
    "collegeCode": "WB00011",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb17a",
    "collegeName": "BHILAI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "CH00013",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb17a",
    "collegeName": "BHILAI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "CH00013",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb085",
    "collegeName": " R. V. S. COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "JH00014",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb085",
    "collegeName": " R. V. S. COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "JH00014",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Electronics & Communication Engineering"
    }
    ],
    "date": "2023-05-01T09:22:14.326Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=27320237982&tracking_id=112868362646&bank_ref_no=312154987550&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=Shirsendu Chatterjee&billing_address=Jharkhand&billing_city=Jharkhand&billing_state=Jharkhand&billing_zip=XXXXXX&billing_country=India&billing_tel=7061970690&billing_email=chattshirsendu05@gmail.com&delivery_name=Shirsendu Chatterjee&delivery_address=Jharkhand&delivery_city=Jharkhand&delivery_state=Jharkhand&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7061970690&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=01/05/2023 14:52:10&bin_country=",
    "_id": "644f84c6892626d741ca0746"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=27320237982&tracking_id=112871827290&bank_ref_no=312565858779&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=25000.00&billing_name=Shirsendu Chatterjee&billing_address=Retired Colony, Jeetpur, Gomoh&billing_city=DHANBAD&billing_state=Jharkhand&billing_zip=828401&billing_country=India&billing_tel=7061970690&billing_email=chattshirsendu05@gmail.com&delivery_name=Shirsendu Chatterjee&delivery_address=Retired Colony, Jeetpur, Gomoh&delivery_city=DHANBAD&delivery_state=Jharkhand&delivery_zip=828401&delivery_country=India&delivery_tel=7061970690&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=05/05/2023 07:15:40&bin_country=",
    "_id": "64545fc7892626d741ca2fb0"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Float",
    "tenthCert": "1688535355749Class 10th Marksheet.pdf",
    "aadhaar": "1683250960705AadharNew.pdf",
    "SeatAccept": true,
    "twelveCert": "1688535417548Class 12th Marksheet.pdf",
    "choiceFillingSpotRound": [
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafff",
    "collegeName": "NETAJI SUBHAS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "BR00014",
    "programme": "Engineering",
    "state": "BIHAR",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29c",
    "collegeName": "ASANSOL ENGINEERING COLLEGE",
    "collegeCode": "WB00011",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb085",
    "collegeName": " R. V. S. COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "JH00014",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28c",
    "collegeName": "COLLEGE OF ENGINEERING ROORKEE",
    "collegeCode": "UT00017",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e6f68deb66365311f35d1",
    "slNo": "61",
    "applicationNo": "15320237497",
    "studentName": "Swaraj kumar Behera",
    "emailId": "swarajbehera923@gmail.com",
    "password": "414696",
    "phy": "12",
    "che": "34",
    "math": "15",
    "gk": "72",
    "markObtained": "240",
    "rank": "10678",
    "mobileNo": "9178773834",
    "state": "ODISHA",
    "fatherName": "sarat kumar behera",
    "Branch Pref": "Computer Science Engg",
    "photo": "https://ucarecdn.com/efa8610a-b4f1-4c0f-b931-eac879fdcbeb/-/crop/349x349/0,90/-/preview/",
    "signature": "https://ucarecdn.com/7d7afcf4-6f93-4717-a8b0-dfa2991613ed/-/crop/1600x640/0,83/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "HI-TECH INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00024",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "ODISHA"
    }
    ],
    "date": "2023-05-02T09:49:03.878Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=15320237497&tracking_id=112869320185&bank_ref_no=312258048490&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=Swaraj kumar Behera&billing_address=ODISHA&billing_city=ODISHA&billing_state=ODISHA&billing_zip=XXXXXX&billing_country=India&billing_tel=9178773834&billing_email=swarajbehera923@gmail.com&delivery_name=Swaraj kumar Behera&delivery_address=ODISHA&delivery_city=ODISHA&delivery_state=ODISHA&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9178773834&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=02/05/2023 15:18:52&bin_country=",
    "_id": "6450dc8f892626d741ca0b31"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "tenthCert": "1683210620915WhatsApp Image 2023-04-29 at 18.21.01.pdf",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19e",
    "collegeName": "HI-TECH INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00024",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb193",
    "collegeName": "BHADRAK ENGINEERING SCHOOL AND TECHNOLOGY",
    "collegeCode": "OD00013",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a9",
    "collegeName": "NM INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "OD00036",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1b0",
    "collegeName": "SYNERGY INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "OD00047",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb18d",
    "collegeName": "ADARSHA COLLEGE OF ENGINEERING",
    "collegeCode": "OD00006",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19b",
    "collegeName": "Gandhi Institute of Technological Advancement (GITA)",
    "collegeCode": "OD00021",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19f",
    "collegeName": "INDOTECH COLLEGE OF ENGINEERING",
    "collegeCode": "OD00025",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a4",
    "collegeName": "MAHAVIR INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "OD00030",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb18f",
    "collegeName": "ARYAN INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "OD00009",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1b3",
    "collegeName": "TRIDENT ACADEMY OF TECHNOLOGY",
    "collegeCode": "OD00050",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19c",
    "collegeName": "Gandhi Institute for Technology (GIFT)",
    "collegeCode": "OD00022",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a5",
    "collegeName": "MODERN INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    "collegeCode": "OD00031",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a6",
    "collegeName": "NALANDA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00032",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb195",
    "collegeName": "BHUBANESWAR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00015",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19a",
    "collegeName": "Gandhi Engineering College",
    "collegeCode": "OD00020",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a8",
    "collegeName": "NIST INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "OD00034",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1b6",
    "collegeName": "VITS ENGINEERING COLLEGE",
    "collegeCode": "OD00053",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1b5",
    "collegeName": "VIGNAN INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    "collegeCode": "OD00052",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1b4",
    "collegeName": "VEDANG INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00051",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1aa",
    "collegeName": "OXFORD COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "OD00037",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1ae",
    "collegeName": "SUNDARGARH ENGINEERING COLLEGE,SUNDARGARH",
    "collegeCode": "OD00044",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a7",
    "collegeName": "NIGAM INSITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "OD00033",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1ac",
    "collegeName": "ROLAND INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00041",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1af",
    "collegeName": "SUSHREE INSTITUTE OF TECHNICAL EDUCATION (SITE)",
    "collegeCode": "OD00045",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e6f68deb66365311f35e4",
    "slNo": "66",
    "applicationNo": "18320231607",
    "studentName": "ABHISHEK KUMAR",
    "emailId": "ak4791475@gmail.com",
    "password": "222349",
    "phy": "58",
    "che": "56",
    "math": "15",
    "gk": "83",
    "markObtained": "179",
    "rank": "7793",
    "mobileNo": "7488930978",
    "state": "JHARKHAND",
    "fatherName": "RAJAN KUMAR",
    "Branch Pref": "Computer Science Engg",
    "photo": "https://ucarecdn.com/41c9afe8-1bd4-4305-86ed-6428b05512ec/-/crop/199x199/0,0/-/preview/",
    "signature": "https://ucarecdn.com/03daa572-f08a-4da1-aed1-21dfd25f7001/-/crop/1453x581/124,0/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "WEST BENGAL"
    }
    ],
    "date": "2023-05-03T07:47:36.937Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=18320231607&tracking_id=112870194790&bank_ref_no=309217&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=MasterCard Debit Card&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=ABHISHEK KUMAR&billing_address=JHARKHAND&billing_city=JHARKHAND&billing_state=JHARKHAND&billing_zip=XXXXXX&billing_country=India&billing_tel=7488930978&billing_email=ak4791475@gmail.com&delivery_name=ABHISHEK KUMAR&delivery_address=JHARKHAND&delivery_city=JHARKHAND&delivery_state=JHARKHAND&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7488930978&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=03/05/2023 13:17:06&bin_country=INDIA",
    "_id": "64521198892626d741ca1022"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c4",
    "collegeName": "TECHNO INTERNATIONAL NEW TOWN",
    "collegeCode": "WB00062",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf62",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeef",
    "collegeName": " Sarla Birla University",
    "collegeCode": "JH00010",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5c",
    "collegeName": "Brainware University, Kolkata",
    "collegeCode": "WB00003",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5f",
    "collegeName": " Swami Vivekananda University",
    "collegeCode": "WB00006",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf61",
    "collegeName": "The Neotia University, Sarisa",
    "collegeCode": "WB00008",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5d",
    "collegeName": "JIS University,Kolkata",
    "collegeCode": "WB00004",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5b",
    "collegeName": "Adamas University,Kolkata",
    "collegeCode": "WB00001",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5e",
    "collegeName": "Sister Nivedita University, Kolkata",
    "collegeCode": "WB00005",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ae",
    "collegeName": "GLOBAL INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    "collegeCode": "WB00030",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b5",
    "collegeName": "INSTITUTE OF SCIENCE & TECHNOLOGY",
    "collegeCode": "WB00041",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b9",
    "collegeName": "NARULA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00048",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29b",
    "collegeName": "ABACUS INSTITUTE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "WB00010",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a0",
    "collegeName": "BENGAL INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    "collegeCode": "WB00015",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ad",
    "collegeName": "FUTURE INSTITUTE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "WB00029",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2bf",
    "collegeName": "SANAKA EDUCATIONAL TRUST'S GROUP OF INSTITUTIONS",
    "collegeCode": "WB00056",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ba",
    "collegeName": "NIBEDITA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00049",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2be",
    "collegeName": "SALBANI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00055",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b0",
    "collegeName": "GURUNANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00033",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c0",
    "collegeName": "SAROJ MOHAN INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00057",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e6f68deb66365311f35ed",
    "slNo": "102",
    "applicationNo": "28320233321",
    "studentName": "Prasun Pratik",
    "emailId": "prasunpratik1112@gmail.com",
    "password": "59171",
    "phy": "58",
    "che": "62",
    "math": "59",
    "gk": "81",
    "markObtained": "106",
    "rank": "7245",
    "mobileNo": "7004379343",
    "state": "Bihar",
    "fatherName": "Pankaj Kumar Singh",
    "Branch Pref": "Computer Science Engg",
    "photo": "https://ucarecdn.com/5ce8e2da-2d5a-45bd-b62c-220d43beb0db/-/crop/495x495/17,0/-/preview/",
    "signature": "https://ucarecdn.com/4438da3a-51c5-4507-b397-4165dbbc32a7/-/crop/335x134/19,0/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "branch": "Computer Science Engineering with Data science ",
    "programme": "Engineering",
    "state": "KARNATAKA"
    }
    ],
    "date": "2023-05-03T09:17:59.475Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=28320233321&tracking_id=112870296417&bank_ref_no=120041&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=State Bank of India&status_code=null&status_message=SUCCESS&currency=INR&amount=5300.00&billing_name=Prasun Pratik&billing_address=Bihar&billing_city=Bihar&billing_state=Bihar&billing_zip=XXXXXX&billing_country=India&billing_tel=7004379343&billing_email=prasunpratik1112@gmail.com&delivery_name=Prasun Pratik&delivery_address=Bihar&delivery_city=Bihar&delivery_state=Bihar&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7004379343&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=03/05/2023 14:47:07&bin_country=INDIA",
    "_id": "645226c7892626d741ca11fc"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electrical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bb",
    "collegeName": "NEW HORIZON COLLEGE OF ENGINEERING ",
    "collegeCode": "KR00082",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e6f68deb66365311f35df",
    "slNo": "95",
    "applicationNo": "28320236136",
    "studentName": "Nihal Verma",
    "emailId": "nihalv678@gmail.com",
    "password": "464706",
    "phy": "49",
    "che": "50",
    "math": "27",
    "gk": "80",
    "markObtained": "195",
    "rank": "7968",
    "mobileNo": "6202681963",
    "state": "JHARKHAND",
    "fatherName": "PRADEEP KUMAR VERMA",
    "Branch Pref": "Computer Science Engg with sepecialization (like AI, Data Science, ML or any other)",
    "photo": "https://ucarecdn.com/96027109-ca8b-46a5-8368-3b25fa113595/-/crop/473x473/8,0/-/preview/",
    "signature": "https://ucarecdn.com/bf997665-fc98-408e-b467-1c040a2c2f60/-/crop/308x123/14,0/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 5,
    "allotedCollege": [
    {
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "KARNATAKA"
    }
    ],
    "date": "2023-05-03T10:16:22.654Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=28320236136&tracking_id=112870352620&bank_ref_no=20230503010870000860546875639070868&order_status=Failure&failure_message=&payment_mode=Wallet&card_name=Paytm&status_code=null&status_message=User has not completed transaction.&currency=INR&amount=5300.00&billing_name=Nihal Verma&billing_address=JHARKHAND&billing_city=JHARKHAND&billing_state=JHARKHAND&billing_zip=XXXXXX&billing_country=India&billing_tel=6202681963&billing_email=nihalv678@gmail.com&delivery_name=Nihal Verma&delivery_address=JHARKHAND&delivery_city=JHARKHAND&delivery_state=JHARKHAND&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6202681963&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=03/05/2023 15:46:21&bin_country=",
    "_id": "64523476892626d741ca12dc"
    },
    {
    "paymentStatus": "order_id=28320236136&tracking_id=112870369846&bank_ref_no=080260&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Nihal Verma&billing_address=JHARKHAND&billing_city=JHARKHAND&billing_state=JHARKHAND&billing_zip=XXXXXX&billing_country=India&billing_tel=6202681963&billing_email=nihalv678@gmail.com&delivery_name=Nihal Verma&delivery_address=JHARKHAND&delivery_city=JHARKHAND&delivery_state=JHARKHAND&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6202681963&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=03/05/2023 15:52:54&bin_country=INDIA",
    "_id": "645235ff892626d741ca12ed"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf7e",
    "collegeName": "CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00044",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0d6",
    "collegeName": "THE OXFORD COLLEGE OF ENGINEERING",
    "collegeCode": "KR00115",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec6",
    "collegeName": "Babu Banarasi Das University, Lucknow",
    "collegeCode": "UP00003",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a8",
    "collegeName": "NIST INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "OD00034",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec8",
    "collegeName": "G.L.A. University,Mathura",
    "collegeCode": "UP00005",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeca",
    "collegeName": "IIMT University, Meerut",
    "collegeCode": "UP00008",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb26c",
    "collegeName": "KCC INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    "collegeCode": "UP00065",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb276",
    "collegeName": "R. R. INSTITUTE OF MODERN TECHNOLOGY",
    "collegeCode": "UP00076",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb273",
    "collegeName": "PSIT-PRANVEER SINGH INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UP00073",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf47",
    "collegeName": "Kalasalingam Academy of Research and Higher Education",
    "collegeCode": "TM00006",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b0",
    "collegeName": "K.S.INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00065",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf5e",
    "collegeName": "Sister Nivedita University, Kolkata",
    "collegeCode": "WB00005",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e6f68deb66365311f35da",
    "slNo": "38",
    "applicationNo": "11220235966",
    "studentName": "HARSH RANJAN",
    "emailId": "HARSHRANJAN6299@GMAIL.COM",
    "password": "886124",
    "phy": "42",
    "che": "51",
    "math": "23",
    "gk": "77",
    "markObtained": "207",
    "rank": "8715",
    "mobileNo": "6299537626",
    "state": "BIHAR",
    "fatherName": "NEERAJ KUMAR",
    "Branch Pref": "Computer Science Engg",
    "photo": "https://ucarecdn.com/71e42c0a-50eb-426d-b8c1-9f4f75b06549/-/crop/200x200/0,0/-/preview/",
    "signature": "https://ucarecdn.com/b4d35458-182e-4d8f-9964-6a0645870395/-/crop/272x108/0,10/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 6,
    "allotedCollege": [
    {
    "collegeName": "DR. B.C. ROY ENGINEERING COLLEGE, DURGAPUR",
    "collegeCode": "WB00024",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "WEST BENGAL"
    }
    ],
    "date": "2023-05-03T06:36:07.637Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=11220235966&tracking_id=112870097422&bank_ref_no=312358300648&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=HARSH RANJAN&billing_address=BIHAR&billing_city=BIHAR&billing_state=BIHAR&billing_zip=XXXXXX&billing_country=India&billing_tel=6299537626&billing_email=HARSHRANJAN6299@GMAIL.COM&delivery_name=HARSH RANJAN&delivery_address=BIHAR&delivery_city=BIHAR&delivery_state=BIHAR&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6299537626&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=03/05/2023 12:05:59&bin_country=",
    "_id": "645200d7892626d741ca0f57"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "choiceFillingRound1": [
    {
    "id": "64017441560a04ddc6dbb2b1",
    "collegeName": "HERITAGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00035",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c4",
    "collegeName": "TECHNO INTERNATIONAL NEW TOWN",
    "collegeCode": "WB00062",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a8",
    "collegeName": "DR. B.C. ROY ENGINEERING COLLEGE, DURGAPUR",
    "collegeCode": "WB00024",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf60",
    "collegeName": " Techno India University",
    "collegeCode": "WB00007",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf45",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb21c",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING",
    "collegeCode": "TM00019",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb198",
    "collegeName": "COLLEGE OF ENGINEERING BHUBANESWAR",
    "collegeCode": "OD00018",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb194",
    "collegeName": "BHUBANESWAR COLLEGE OF ENGINEERING",
    "collegeCode": "OD00014",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1aa",
    "collegeName": "OXFORD COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "OD00037",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c2",
    "collegeName": "ST. THOMAS' COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00060",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b9",
    "collegeName": "NARULA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00048",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29c",
    "collegeName": "ASANSOL ENGINEERING COLLEGE",
    "collegeCode": "WB00011",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2a7",
    "collegeName": "COLLEGE OF ENGINEERING & MANAGEMENT, KOLAGHAT",
    "collegeCode": "WB00023",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b1",
    "collegeName": "K.V.G.COLLEGE OF ENGINEERING",
    "collegeCode": "KR00066",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb101",
    "collegeName": "ST.THOMAS COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "KR00045",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b8",
    "collegeName": "MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "KR00077",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9e",
    "collegeName": "Darshan University",
    "collegeCode": "GU00006",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    }
    ],
    "tenthCert": "1688549968936class 10 th marksheet (1).pdf",
    "aadhaar": "1688549983208Aadhar.pdf",
    "twelveCert": "1688549995064Class 12 th marksheet (1).pdf",
    "otherDocs": "1688550007026manipal.pdf",
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e6f68deb66365311f35e1",
    "slNo": "86",
    "applicationNo": "25320233666",
    "studentName": "Madhur Kumar Lovewanshi",
    "emailId": "madhurlovewanshi2004@gmail.com",
    "password": "423549",
    "phy": "47",
    "che": "65",
    "math": "21",
    "gk": "75",
    "markObtained": "192",
    "rank": "7901",
    "mobileNo": "6266321407",
    "state": "Madhya Pradesh",
    "fatherName": "Jagdish Prasad Lovewanshi",
    "Branch Pref": "Computer Science Engg",
    "photo": "https://ucarecdn.com/f42d2984-cbd8-4dbd-80c1-c0b7af4d50e7/-/crop/530x530/26,192/-/preview/",
    "signature": "https://ucarecdn.com/77615410-bdce-4e06-a51d-da9729798bb2/-/crop/514x206/11,161/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "KARNATAKA"
    }
    ],
    "date": "2023-04-30T16:24:20.978Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=25320233666&tracking_id=112867808337&bank_ref_no=312053356232&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=Madhur Kumar Lovewanshi&billing_address=Madhya Pradesh&billing_city=Madhya Pradesh&billing_state=Madhya Pradesh&billing_zip=XXXXXX&billing_country=India&billing_tel=6266321407&billing_email=madhurlovewanshi2004@gmail.com&delivery_name=Madhur Kumar Lovewanshi&delivery_address=Madhya Pradesh&delivery_city=Madhya Pradesh&delivery_state=Madhya Pradesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6266321407&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=30/04/2023 21:54:15&bin_country=",
    "_id": "644e9634da65d737c3d7fe07"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb119",
    "collegeName": "IES COLLEGE OF TECHNOLOGY, BHOPAL",
    "collegeCode": "MP00033",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb133",
    "collegeName": "SAGAR INSTITUTE OF RESEARCH & TECHNOLOGY, INDORE",
    "collegeCode": "MP00063",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0b",
    "collegeName": " Sage university ,Indore",
    "collegeCode": "MP00017",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf12",
    "collegeName": " Sarvepalli Radhakrishnan University",
    "collegeCode": "MP00024",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefe",
    "collegeName": " Avantika University,Ujjain",
    "collegeCode": "MP00002",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb117",
    "collegeName": "GWALIOR INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "MP00031",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb12f",
    "collegeName": "RADHARAMAN INSTITUTE OF TECHNOLOGY AND SCIENCE",
    "collegeCode": "MP00059",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb128",
    "collegeName": "NRI-INSTITUTE OF RESEARCH & TECHNOLOGY",
    "collegeCode": "MP00052",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb132",
    "collegeName": "SAGAR INSTITUTE OF RESEARCH & TECHNOLOGY",
    "collegeCode": "MP00062",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf09",
    "collegeName": "RKDF University, Bhopal",
    "collegeCode": "MP00014",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf02",
    "collegeName": " Eklavya University",
    "collegeCode": "MP00007",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb11b",
    "collegeName": "INFINITY MANAGEMENT & ENGINEERING COLLEGE",
    "collegeCode": "MP00035",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb03e",
    "collegeName": "SHREE SWAMINARAYAN INSTITUTE OF TECHNOLOGY, GANDHINAGAR",
    "collegeCode": "GU00095",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb03f",
    "collegeName": "SHREE VASUDEVBHAI AND KANTIBHAI PATEL INSTITUTE OF ENGINEERING",
    "collegeCode": "GU00096",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb04d",
    "collegeName": "VIDUSH SOMANY INSTITUTE OF TECHNOLOGY AND RESEARCH, MEHSANA",
    "collegeCode": "GU00110",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb026",
    "collegeName": "MAHAVIR SWAMI COLLEGE OF ENGINEERING & TECHNOLOGY, SURAT",
    "collegeCode": "GU00065",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb02c",
    "collegeName": "NOBLE GROUP OF INSTITUTIONS, JUNAGADH",
    "collegeCode": "GU00072",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb032",
    "collegeName": "R. N. G. PATEL INSTITUTE OF TECHNOLOGY RNGPIT, SURAT",
    "collegeCode": "GU00078",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb033",
    "collegeName": "RK UNIVERSITY SCHOOL OF ENGINEERING, RAJKOT",
    "collegeCode": "GU00079",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf10",
    "collegeName": "Mangalayatan University",
    "collegeCode": "MP00022",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb140",
    "collegeName": "VINDHYA INSTITUTE OF TECHNOLOGY AND SC",
    "collegeCode": "MP00080",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "644e751543e2b25d246dee5f",
    "slNo": "9",
    "applicationNo": "26220232880",
    "studentName": "Vishal Das",
    "emailId": "vishaldas810145@gmail.com",
    "password": "577299",
    "mobileNo": "8101435614",
    "phy": "19",
    "che": "25",
    "bio": "51",
    "gk": "17",
    "markObtained": "112",
    "rank": "11718",
    "progOpted": "Bsc.Agriculture",
    "moe": "HBE",
    "loe": "PCB (12th Level)",
    "photo": "https://ucarecdn.com/7df97370-d3bf-4040-ad9e-421dba530b55/-/crop/597x597/0,84/-/preview/",
    "signature": "https://ucarecdn.com/abb295fe-5ff6-4e97-a666-670a3b9fec68/-/crop/521x208/66,0/-/preview/",
    "fatherName": "Bijay Das",
    "state": "West Bengal",
    "__v": 5,
    "allotedCollege": [
    {
    "collegeName": "Centurion University of Technology and Management",
    "collegeCode": "AND0004",
    "branch": null,
    "programme": "Agriculture",
    "state": "Andhra Pradesh"
    }
    ],
    "date": "2023-04-30T17:55:47.891Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=26220232880&tracking_id=112867866520&bank_ref_no=null&order_status=Aborted&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Initiation Failed&currency=INR&amount=5300.00&billing_name=Vishal Das&billing_address=West Bengal&billing_city=West Bengal&billing_state=West Bengal&billing_zip=XXXXXX&billing_country=India&billing_tel=8101435614&billing_email=vishaldas810145@gmail.com&delivery_name=Vishal Das&delivery_address=West Bengal&delivery_city=West Bengal&delivery_state=West Bengal&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=8101435614&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=30/04/2023 23:25:40&bin_country=",
    "_id": "644eaba3da65d737c3d7ff03"
    },
    {
    "paymentStatus": "order_id=26220232880&tracking_id=112867868102&bank_ref_no=312053548728&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=Vishal Das&billing_address=West Bengal&billing_city=West Bengal&billing_state=West Bengal&billing_zip=XXXXXX&billing_country=India&billing_tel=8101435614&billing_email=vishaldas810145@gmail.com&delivery_name=Vishal Das&delivery_address=West Bengal&delivery_city=West Bengal&delivery_state=West Bengal&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=8101435614&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=30/04/2023 23:30:22&bin_country=",
    "_id": "644eacbbda65d737c3d7ff1b"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Freeze",
    "choiceFillingRound1": [
    {
    "id": "62ef50bdc887c65e3f73fee8",
    "collegeName": "A.M.REDDY MEMORIAL COLLEGE OF ENGINEERING& TECHNOLOGY",
    "collegeCode": "AND0006",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feea",
    "collegeName": "ADITYA INSTITUTE OF PG STUDIES",
    "collegeCode": "AND0013",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fee7",
    "collegeName": "Centurion University of Technology and Management",
    "collegeCode": "AND0004",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fee5",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AND0002",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feed",
    "collegeName": "VIKAS COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AND0179",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feeb",
    "collegeName": "NOVA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AND0113",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fee9",
    "collegeName": "ADITYA ENGINEERING COLLEGE",
    "collegeCode": "AND0012",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feec",
    "collegeName": "PYDAH COLLEGE OF ENGINEERING",
    "collegeCode": "AND0122",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fee6",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AND0003",
    "programme": "Agriculture",
    "state": "Andhra Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff25",
    "collegeName": "Rayat Bahra University,Mohali",
    "collegeCode": "PUN0060",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff2a",
    "collegeName": "GOLDEN COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "PUN0024",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff2b",
    "collegeName": "KC COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY, NAWANSHAHR",
    "collegeCode": "PUN0031",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff2f",
    "collegeName": "SWAMI VIVEKANAND INSTITUTE OF ENGG. & TECH.",
    "collegeCode": "PUN0052",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff23",
    "collegeName": "D.A.V. University,Jalandhar",
    "collegeCode": "PUN0056",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff27",
    "collegeName": "AMRITSAR GROUP OF COLLEGES",
    "collegeCode": "PUN0003",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff2c",
    "collegeName": "KHALSA COLLEGE OF ENGINEERING & TECHNOLOGY, AMRITSAR",
    "collegeCode": "PUN0032",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff30",
    "collegeName": "BABA KUMA SINGH JI ENGG COLLEGE,AMRITSAR",
    "collegeCode": "PUN0012",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff34",
    "collegeName": "RAYAT BAHRA INSTITUTE OF MANAGEMENT",
    "collegeCode": "PUN0037",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff36",
    "collegeName": "AMANBHALLA GROUP OF INSTITUTIONS",
    "collegeCode": "PUN0001",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff37",
    "collegeName": "ARYANS COLLEGE OF ENGINEERING (ACE)",
    "collegeCode": "PUN0007",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff38",
    "collegeName": "ASRA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "PUN0008",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff21",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PUN0054",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff22",
    "collegeName": " C.T. University,Ludhiana",
    "collegeCode": "PUN0055",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff26",
    "collegeName": " RIMT University,Gobindgarh",
    "collegeCode": "PUN0061",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff2e",
    "collegeName": "SUKHJINDER TECHNICAL CAMPUS",
    "collegeCode": "PUN0049",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff31",
    "collegeName": "BAHRA GROUP OF INSTITUTION",
    "collegeCode": "PUN0013",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff32",
    "collegeName": "GULZAR GROUP OF INSTITUTIONS",
    "collegeCode": "PUN0026",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff35",
    "collegeName": " INSTITUTE OF ENGINEERING AND TECHNOLOGY - BHADDAL",
    "collegeCode": "PUN0002",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff24",
    "collegeName": " GNA University,Kapurthala",
    "collegeCode": "PUN0057",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff28",
    "collegeName": "ARYANS BUSINESS SCHOOL",
    "collegeCode": "PUN0006",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff29",
    "collegeName": "ASRA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "PUN0008",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff2d",
    "collegeName": "SHAHEED UDHAM SINGH COLLEGE OF PHARMACY",
    "collegeCode": "PUN0044",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff33",
    "collegeName": "RADICAL TECHNICAL INSTITUTE",
    "collegeCode": "PUN0036",
    "programme": "Agriculture",
    "state": "Punjab",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef3",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GUJ0011",
    "programme": "Agriculture",
    "state": "Gujarat",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef5",
    "collegeName": " Swarnim Startup & Innovation University",
    "collegeCode": "GUJ0015",
    "programme": "Agriculture",
    "state": "Gujarat",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef6",
    "collegeName": " R.K. University",
    "collegeCode": "GUJ0012",
    "programme": "Agriculture",
    "state": "Gujarat",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef4",
    "collegeName": "Rai University,Ahmedabad",
    "collegeCode": "GUJ0013",
    "programme": "Agriculture",
    "state": "Gujarat",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef1",
    "collegeName": " Ganpat University,Mehsana",
    "collegeCode": "GUJ0005",
    "programme": "Agriculture",
    "state": "Gujarat",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef2",
    "collegeName": "Marwadi University",
    "collegeCode": "GUJ0010",
    "programme": "Agriculture",
    "state": "Gujarat",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feee",
    "collegeName": "Dr. C.V. Raman University,",
    "collegeCode": "BIH0004",
    "programme": "Agriculture",
    "state": "Bihar",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feef",
    "collegeName": "Gopal Narayan Singh University",
    "collegeCode": "BIH0005",
    "programme": "Agriculture",
    "state": "Bihar",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef0",
    "collegeName": "BHARTI COLLEGE OF ENGINEERING & TECHNOLOGY DURG",
    "collegeCode": "CHG0009",
    "programme": "Agriculture",
    "state": "Chattisgarh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef8",
    "collegeName": " Maharishi Markandeshwar University ,Mullana,Amballa",
    "collegeCode": "HAR0010",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fefa",
    "collegeName": " NIILM University,Kaithal",
    "collegeCode": "HAR0013",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff07",
    "collegeName": "GEETA ENGINEERING COLLEGE",
    "collegeCode": "HAR0043",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef9",
    "collegeName": " M.V.N. University,Palwal",
    "collegeCode": "HAR0012",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fefc",
    "collegeName": " Starex University, Gurugram",
    "collegeCode": "HAR0017",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff06",
    "collegeName": "BHARAT INSTITUTE OF PHARMACY SONEPAT",
    "collegeCode": "HAR0027",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fef7",
    "collegeName": "K R Mangalam University, Gurugram",
    "collegeCode": "HAR0009",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fefb",
    "collegeName": "PDM University,Bahadurgarh",
    "collegeCode": "HAR0016",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff08",
    "collegeName": "P D M INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "HAR0065",
    "programme": "Agriculture",
    "state": "Haryana",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff0e",
    "collegeName": "Ram Krishana Dharmarth Foundation University",
    "collegeCode": "JHA0007",
    "programme": "Agriculture",
    "state": "Jharkhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff0f",
    "collegeName": "Sai Nath University,Ranch",
    "collegeCode": "JHA0008",
    "programme": "Agriculture",
    "state": "Jharkhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff0c",
    "collegeName": "Capital University, Koderma",
    "collegeCode": "JHA0004",
    "programme": "Agriculture",
    "state": "Jharkhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff10",
    "collegeName": "Usha Martin University",
    "collegeCode": "JHA0010",
    "programme": "Agriculture",
    "state": "Jharkhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff0d",
    "collegeName": "Jharkhand Rai University",
    "collegeCode": "JHA0005",
    "programme": "Agriculture",
    "state": "Jharkhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff12",
    "collegeName": " Dr. A.P.J. Abdul Kalam  University,Indore",
    "collegeCode": "MAP0004",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff18",
    "collegeName": "Rabindranath Tagore University (Formerly AISECT  University),Bhopal",
    "collegeCode": "MAP0012",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff19",
    "collegeName": " Renaissance university, indore",
    "collegeCode": "MAP0013",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff1c",
    "collegeName": "Mandsaur university, Mandsaur",
    "collegeCode": "MAP0017",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff11",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MAP0003",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff13",
    "collegeName": " Eklavya University",
    "collegeCode": "MAP0005",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff16",
    "collegeName": " P.K University,Shivpuri",
    "collegeCode": "MAP0010",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff1e",
    "collegeName": " Symbiosis University of Applied Sciences",
    "collegeCode": "MAP0019",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff17",
    "collegeName": "RKDF University, Bhopal",
    "collegeCode": "MAP0011",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff1b",
    "collegeName": "Madhyanchal Professional University,Bhopal",
    "collegeCode": "MAP0015",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff1d",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MAP0018",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff14",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MAP0007",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff15",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MAP0008",
    "programme": "Agriculture",
    "state": "Madhya Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff4c",
    "collegeName": "Kalasalingam Academy of Research and Higher Education",
    "collegeCode": "TAM0005",
    "programme": "Agriculture",
    "state": "Tamil Nadu",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff4e",
    "collegeName": "DHANALAKSHMI SRINIVASAN COLLEGE OF ENGINEERING",
    "collegeCode": "TAM0013",
    "programme": "Agriculture",
    "state": "Tamil Nadu",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff50",
    "collegeName": "DHANALAKSHMI SRINIVASAN ENGINEERING COLLEGE",
    "collegeCode": "TAM0015",
    "programme": "Agriculture",
    "state": "Tamil Nadu",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff4b",
    "collegeName": "Bharath institute of highereducation & education & research, Chennai",
    "collegeCode": "TAM0002",
    "programme": "Agriculture",
    "state": "Tamil Nadu",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff4d",
    "collegeName": "Karunya Institute of Technology and Sciences,Coimbatore",
    "collegeCode": "TAM0006",
    "programme": "Agriculture",
    "state": "Tamil Nadu",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff4f",
    "collegeName": "DHANALAKSHMI SRINIVASAN COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "TAM0014",
    "programme": "Agriculture",
    "state": "Tamil Nadu",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff5c",
    "collegeName": "Brainware University, Kolkata",
    "collegeCode": "WEB0003",
    "programme": "Agriculture",
    "state": "West Bengal",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff5e",
    "collegeName": "SISTER NIVEDITA UNIVERSITY      ",
    "collegeCode": "WEB0058",
    "programme": "Agriculture",
    "state": "West Bengal",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff5d",
    "collegeName": "JIS COLLEGE OF ENGINEERING",
    "collegeCode": "WEB0044",
    "programme": "Agriculture",
    "state": "West Bengal",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff53",
    "collegeName": "DEV BHOOMI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UTR0015",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff57",
    "collegeName": "J B INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UTR0021",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff01",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UTP0016",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fefe",
    "collegeName": " IFTM University, Moradabad",
    "collegeCode": "UTP0009",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff02",
    "collegeName": "Shobhit University",
    "collegeCode": "UTP0017",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff03",
    "collegeName": "Shri Ramswaroop Memorial University",
    "collegeCode": "UTP0018",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff04",
    "collegeName": " Shri Venkateshwara University, J.P.Nagar, Gajraula",
    "collegeCode": "UTP0020",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff05",
    "collegeName": " Teerthanker Mahaveer University, Moradabad",
    "collegeCode": "UTP0021",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff09",
    "collegeName": "R. R. INSTITUTE OF MODERN TECHNOLOGY",
    "collegeCode": "UTP0075",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73fefd",
    "collegeName": "Galgotias University",
    "collegeCode": "UTP0007",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff00",
    "collegeName": "Sanskriti University, Mathura",
    "collegeCode": "UTP0015",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff0a",
    "collegeName": "BHARAT INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "UTP0044",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73feff",
    "collegeName": " Integral University, Lucknow",
    "collegeCode": "UTP0010",
    "programme": "Agriculture",
    "state": "Uttar Pradesh",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff58",
    "collegeName": "MAYA INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "UTR0022",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff51",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UTR0006",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff52",
    "collegeName": "Quantum University, Roorkee",
    "collegeCode": "UTR0007",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff54",
    "collegeName": "DOON BUSINESS SCHOOL",
    "collegeCode": "UTR0017",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff5a",
    "collegeName": "SHREE DEV BHOOMI INSTITUTE OF EDUCATION SCIENCE & TECHNOLLOGY",
    "collegeCode": "UTR0029",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff55",
    "collegeName": "DOON INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "UTR0019",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff5b",
    "collegeName": "SRI SAI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UTR0031",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff56",
    "collegeName": "GURU RAM DAS INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    "collegeCode": "UTR0020",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff59",
    "collegeName": "SHIVALIK COLLEGE OF ENGINEERING",
    "collegeCode": "UTR0028",
    "programme": "Agriculture",
    "state": "Uttrakhand",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff3f",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RAJ0015",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff42",
    "collegeName": " Raffles University, Neemrana",
    "collegeCode": "RAJ0020",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff47",
    "collegeName": "Suresh Gyan Vihar University, Jaipur",
    "collegeCode": "RAJ0026",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff4a",
    "collegeName": "ARAVALI INSTITUTE OF TECHNICAL STUDIES, UDAIPUR",
    "collegeCode": "RAJ0030",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff3a",
    "collegeName": " Bhupal Nobles University",
    "collegeCode": "RAJ0003",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff3d",
    "collegeName": " Lords University, Alwar",
    "collegeCode": "RAJ0012",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff3b",
    "collegeName": "Bhagwant University,Ajmer",
    "collegeCode": "RAJ0005",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff3e",
    "collegeName": " Madhav University",
    "collegeCode": "RAJ0013",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff41",
    "collegeName": " Pacific Academy of Higher Education & Research University",
    "collegeCode": "RAJ0018",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff44",
    "collegeName": " Sangam University",
    "collegeCode": "RAJ0022",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff48",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RAJ0028",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff39",
    "collegeName": " Apex University, Jaipur",
    "collegeCode": "RAJ0002",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff3c",
    "collegeName": "Dr. K.N. Modi University,Tonk",
    "collegeCode": "RAJ0006",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff40",
    "collegeName": " Nirwan University",
    "collegeCode": "RAJ0017",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff43",
    "collegeName": " R.N.B. Global University, Bikaner",
    "collegeCode": "RAJ0021",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff45",
    "collegeName": " Shridhar University",
    "collegeCode": "RAJ0023",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff46",
    "collegeName": " Singhania University",
    "collegeCode": "RAJ0024",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    },
    {
    "id": "62ef50bdc887c65e3f73ff49",
    "collegeName": "SHEKHAWATI INSTITUTE OF ENGG. AND TECHNOLOGY, SIKAR",
    "collegeCode": "RAJ0073",
    "programme": "Agriculture",
    "state": "Rajasthan",
    "branch": null
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1ece584ecfa43e581fda",
    "photo": "https://ucarecdn.com/de62c2c3-50b6-4d8a-9423-8e37fc62945f/-/crop/1062x1063/82,92/-/preview/",
    "signature": "https://ucarecdn.com/3863fb69-0ad8-4771-83dd-455a42eff7d7/-/crop/743x297/52,63/-/preview/",
    "slNo": "6",
    "applicationNo": "3520236588",
    "studentName": "Tushar Yadav",
    "emailId": "tusharyadav511@gmail.com",
    "markObtained": "337",
    "rank": "586",
    "mobileNo": "7786990242",
    "dob": "03/16/2000",
    "addressLine1": "18v/2v/3",
    "addressLine2": "Muirabad",
    "city": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "211002",
    "gender": "Male",
    "regDate": "3-5-2023",
    "formFee": "Success",
    "fatherName": "Ram Chandra Yadav",
    "mothersName": "Pushpa Yadav",
    "prog": "MBA/PGDM",
    "password": "509342",
    "progOpted": "MBA/PGDM",
    "loe": "Graduation Level",
    "moe": "HBE",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "branch": null,
    "programme": "MBA/PGDM",
    "state": "KARNATAKA"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "644cdaec22264cc7e099b402",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "MBA/PGDM",
    "state": "KARNATAKA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b429",
    "collegeName": "Amity University, Mumbai",
    "collegeCode": "MH00002",
    "programme": "MBA/PGDM",
    "state": "MAHARASHTRA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b403",
    "collegeName": "CHRIST University,Bangalore",
    "collegeCode": "KR00003",
    "programme": "MBA/PGDM",
    "state": "KARNATAKA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b39a",
    "collegeName": "SRM University",
    "collegeCode": "AP00008",
    "programme": "MBA/PGDM",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b3aa",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "MBA/PGDM",
    "state": "GUJARAT",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b464",
    "collegeName": "Aurora Higher Education & Research Academy,",
    "collegeCode": "TL00002",
    "programme": "MBA/PGDM",
    "state": "TELANGANA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b3d7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "MBA/PGDM",
    "state": "UTTAR PRADESH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b46b",
    "collegeName": "Bhagwant Global University,",
    "collegeCode": "UT00001",
    "programme": "MBA/PGDM",
    "state": "UTTRAKHAND",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b47b",
    "collegeName": "University of Engineering and Management, Kolkata",
    "collegeCode": "WB00009",
    "programme": "MBA/PGDM",
    "state": "WEST BENGAL",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b43b",
    "collegeName": " Amity University, Rajasthan",
    "collegeCode": "RJ00001",
    "programme": "MBA/PGDM",
    "state": "RAJASTHAN",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b430",
    "collegeName": "Chandigarh University,Mohali",
    "collegeCode": "PN00001",
    "programme": "MBA/PGDM",
    "state": "PUNJAB",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b42e",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "MBA/PGDM",
    "state": "ODISHA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b428",
    "collegeName": "Ajeenkya D.Y. Patil  University,Pune",
    "collegeCode": "MH00001",
    "programme": "MBA/PGDM",
    "state": "MAHARASHTRA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b410",
    "collegeName": " Avantika University,Ujjain",
    "collegeCode": "MP00002",
    "programme": "MBA/PGDM",
    "state": "MADHYA PRADESH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b404",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "MBA/PGDM",
    "state": "KARNATAKA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b3f7",
    "collegeName": "Amity University , Ranchi",
    "collegeCode": "JH00001",
    "programme": "MBA/PGDM",
    "state": "JHARKHAND",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b3c2",
    "collegeName": "Amity University ,Gurugram",
    "collegeCode": "HR00002",
    "programme": "MBA/PGDM",
    "state": "HARYANA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b3a2",
    "collegeName": "ISBM University,Gariyaband ",
    "collegeCode": "CH00003",
    "programme": "MBA/PGDM",
    "state": "CHATTISGARH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b39c",
    "collegeName": "Amity University , Patna ",
    "collegeCode": "BR00001",
    "programme": "MBA/PGDM",
    "state": "BIHAR",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b398",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "MBA/PGDM",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b394",
    "collegeName": "Centurion University , Visakhapatnam",
    "collegeCode": "AP00001",
    "programme": "MBA/PGDM",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b399",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "MBA/PGDM",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b588",
    "collegeName": "UNIVERSAL BUSINESS SCHOOL",
    "collegeCode": "MH00063",
    "programme": "MBA/PGDM",
    "state": "MAHARASHTRA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b582",
    "collegeName": "PUNE BUSINESS SCHOOL",
    "collegeCode": "MH00045",
    "programme": "MBA/PGDM",
    "state": "MAHARASHTRA",
    "branch": null
    },
    {
    "id": "644cdaec22264cc7e099b572",
    "collegeName": "ALARD COLLEGE OF ENGINEERING & MANAGEMENT ,PUNE",
    "collegeCode": "MH00010",
    "programme": "MBA/PGDM",
    "state": "MAHARASHTRA",
    "branch": null
    }
    ],
    "date": "2023-05-22T08:29:39.695Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=3520236588&tracking_id=112888043895&bank_ref_no=350813059656&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Tushar Yadav&billing_address=Uttar Pradesh&billing_city=Uttar Pradesh&billing_state=Uttar Pradesh&billing_zip=XXXXXX&billing_country=India&billing_tel=7786990242&billing_email=tusharyadav511@gmail.com&delivery_name=Tushar Yadav&delivery_address=Uttar Pradesh&delivery_city=Uttar Pradesh&delivery_state=Uttar Pradesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7786990242&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=22/05/2023 13:59:35&bin_country=",
    "_id": "646b27f3bdf587a59bdfe2d1"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=3520236588&tracking_id=112893116706&bank_ref_no=351335501936&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=25000.00&billing_name=Tushar Yadav&billing_address=18v/2v/3 Muirabad&billing_city=Allahabad&billing_state=Uttar Pradesh&billing_zip=211002&billing_country=India&billing_tel=7786990242&billing_email=tusharyadav511@gmail.com&delivery_name=Tushar Yadav&delivery_address=18v/2v/3 Muirabad&delivery_city=Allahabad&delivery_state=Uttar Pradesh&delivery_zip=211002&delivery_country=India&delivery_tel=7786990242&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=27/05/2023 19:37:43&bin_country=",
    "_id": "64720eb2bdf587a59be0136a"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Freeze",
    "aadhaar": "1685195267141Aadhar.pdf",
    "tenthCert": "1685196271692DocScanner 27 May 2023 7-24 pm.pdf",
    "twelveCert": "1685196297601DocScanner 27 May 2023 7-24 pm (1).pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "_id": "646a1eec584ecfa43e581fe2",
    "photo": "https://ucarecdn.com/1b136f3a-0f25-4b15-b5e1-8ad4858bfd25/-/crop/686x686/0,113/-/preview/",
    "signature": "https://ucarecdn.com/47330d26-69a6-4cf3-94ef-5acbb75a73ef/-/crop/659x263/0,62/-/preview/",
    "slNo": "107",
    "applicationNo": "6420231172",
    "studentName": "Anshul Dikshit",
    "emailId": "anshuldikshit5@gmail.com",
    "password": "624386",
    "mobileNo": "9810923460",
    "phy": "63",
    "che": "70",
    "gk": "87",
    "math": "47",
    "markObtained": "267",
    "rank": "6426",
    "addressLine1": "332 Ground floor Masjid Moth near A71 South extension part 2 New Delhi 110049",
    "addressLine2": "Near A71 South extension part 2 New Delhi 110049",
    "city": "New Delhi",
    "state": "Delhi",
    "pincode": "110049",
    "gender": "Male",
    "regDate": "6-4-2023",
    "fatherName": "Praveen Kumar Dikshit",
    "motherName": "Vandana Dikshit",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "UTTAR PRADESH"
    }
    ],
    "date": "2023-05-24T04:34:02.892Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=6420231172&tracking_id=112889704904&bank_ref_no=20230524010990000868070672164087802&order_status=Success&failure_message=&payment_mode=Wallet&card_name=Paytm&status_code=null&status_message=Txn Success&currency=INR&amount=5300.00&billing_name=Anshul Dikshit&billing_address=Delhi&billing_city=Delhi&billing_state=Delhi&billing_zip=XXXXXX&billing_country=India&billing_tel=9810923460&billing_email=anshuldikshit5@gmail.com&delivery_name=Anshul Dikshit&delivery_address=Delhi&delivery_city=Delhi&delivery_state=Delhi&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9810923460&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=24/05/2023 10:04:01&bin_country=",
    "_id": "646d93babdf587a59bdff2d4"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Float",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb6",
    "collegeName": "BML Munjal University, Gurugram",
    "collegeCode": "HR00005",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf35",
    "collegeName": " NIIT university, Neemrana",
    "collegeCode": "RJ00021",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf35",
    "collegeName": " NIIT university, Neemrana",
    "collegeCode": "RJ00021",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf54",
    "collegeName": "DIT University",
    "collegeCode": "UT00002",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    }
    ],
    "tenthCert": "1688472700946IMG_20230630_144249.jpg",
    "aadhaar": "1688472714609IMG_20230630_144127.jpg",
    "twelveCert": "1688472725171IMG_20230630_144446.jpg",
    "otherDocs": "1688473045067IMG_20230630_144607.jpg",
    "choiceFillingSpotRound": [
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedf",
    "collegeName": " Chitkara University,Solan",
    "collegeCode": "HP00006",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound1Submit": true,
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581ff0",
    "photo": "https://ucarecdn.com/b3d75d2e-d0c8-4153-b116-9cec6c585b1a/-/crop/1382x1383/0,154/-/preview/",
    "signature": "https://ucarecdn.com/0b1ae618-5426-4935-8fb4-9455ae790ea2/-/crop/1612x645/0,219/-/preview/",
    "slNo": "113",
    "applicationNo": "1342023115",
    "studentName": "Thejas Rao Manikal",
    "emailId": "manikal.tej@gmail.com",
    "password": "669145",
    "mobileNo": "8780508179",
    "phy": "44",
    "che": "32",
    "gk": "44",
    "math": "74",
    "markObtained": "194",
    "rank": "8452",
    "addressLine1": "#302 Maple,Sankalp Central Park",
    "addressLine2": "Jawa main rd,Yadavagiri",
    "city": "Mysore",
    "state": "Karnataka",
    "pincode": "570020",
    "gender": "Male",
    "regDate": "13-4-2023",
    "fatherName": "Ashoka Rao Manikal",
    "motherName": "Sandya Ashok",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "GUJARAT"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaefc",
    "collegeName": "Srinivas University, Mangalore",
    "collegeCode": "KR00017",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb0ac",
    "collegeName": "JAIN INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00060",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c5",
    "collegeName": "S.D.M. COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "KR00096",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea4",
    "collegeName": "ITM (SLS) Baroda University",
    "collegeCode": "GU00014",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbb03a",
    "collegeName": "SARDAR VALLABHBHAI PATEL INSTITUTE OF TECHNOLOGY (SVIT), ANAND",
    "collegeCode": "GU00090",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf47",
    "collegeName": "Kalasalingam Academy of Research and Higher Education",
    "collegeCode": "TM00006",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb176",
    "collegeName": "YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING",
    "collegeCode": "MH00070",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0d6",
    "collegeName": "THE OXFORD COLLEGE OF ENGINEERING",
    "collegeCode": "KR00115",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    }
    ],
    "date": "2023-05-21T13:49:33.217Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=1342023115&tracking_id=112887453072&bank_ref_no=563728&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Thejas Rao Manikal&billing_address=Karnataka&billing_city=Karnataka&billing_state=Karnataka&billing_zip=XXXXXX&billing_country=India&billing_tel=8780508179&billing_email=manikal.tej@gmail.com&delivery_name=Thejas Rao Manikal&delivery_address=Karnataka&delivery_city=Karnataka&delivery_state=Karnataka&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=8780508179&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=21/05/2023 19:18:48&bin_country=INDIA",
    "_id": "646a216dbdf587a59bdfdc4f"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=1342023115&tracking_id=112898837362&bank_ref_no=706709&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=25000.00&billing_name=Thejas Rao Manikal&billing_address=#302, Maple, Sankalp Central Park, Jawa Main Road, Yadavagiri&billing_city=Mysore&billing_state=Karnataka&billing_zip=570020&billing_country=India&billing_tel=8780508179&billing_email=manikal.tej@gmail.com&delivery_name=Thejas Rao Manikal&delivery_address=#302, Maple, Sankalp Central Park, Jawa Main Road, Yadavagiri&delivery_city=Mysore&delivery_state=Karnataka&delivery_zip=570020&delivery_country=India&delivery_tel=8780508179&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=02/06/2023 20:10:41&bin_country=INDIA",
    "_id": "6479ff6ab744d4edb7e7b919"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Freeze",
    "tenthCert": "1685716299270in.gov.cbse-SSCER-181249992021.pdf",
    "aadhaar": "1685716606342in.gov.uidai-ADHAR-57b65c74ee0aef3c1e85aab01b8e58f0_compressed.pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581ff4",
    "photo": "https://ucarecdn.com/26a4f803-9c66-4479-9851-f60b83bbfcaf/-/crop/229x229/0,8/-/preview/",
    "signature": "https://ucarecdn.com/62fe56d8-f480-4069-b5f0-fe35702a0b98/-/crop/301x120/0,8/-/preview/",
    "slNo": "137",
    "applicationNo": "1520237628",
    "studentName": "Dhanekula mounika",
    "emailId": "dlrdlr4584@gmail.com",
    "password": "384286",
    "mobileNo": "9121539139",
    "phy": "32",
    "che": "36",
    "gk": "86",
    "math": "30",
    "markObtained": "184",
    "rank": "8703",
    "addressLine1": "Jaya parakash nagar Eluru",
    "addressLine2": "Bonda vari veedhi bavisetti vari Peta",
    "city": "Eluru",
    "state": "Andhra pardesh",
    "pincode": "534001",
    "gender": "Female",
    "regDate": "1-5-2023",
    "fatherName": "Dhanekula lakshmana rao",
    "motherName": "Dhanekula nagamma",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 6,
    "allotedCollege": [
    {
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaff3",
    "collegeName": "VISHNU INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00183",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafc1",
    "collegeName": "R.V.R.& J.C.COLLEGE OF ENGINEERING",
    "collegeCode": "AP00124",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf50",
    "collegeName": "Malla Reddy University,",
    "collegeCode": "TL00006",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf77",
    "collegeName": "BAPATLA ENGINEERING COLLEGE",
    "collegeCode": "AP00037",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafde",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING",
    "collegeCode": "AP00157",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafdf",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00158",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafe1",
    "collegeName": "SRI VENKATESWARA INSTITUTE OF TECHNOLOGY.",
    "collegeCode": "AP00160",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09d",
    "collegeName": "CAMBRIDGE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00034",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb237",
    "collegeName": "MAHATMA GANDHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00039",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb21f",
    "collegeName": "AVANTHI INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00010",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22c",
    "collegeName": "GLOBAL INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00026",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb109",
    "collegeName": "UNIVERSAL ENGINEERING COLLEGE",
    "collegeCode": "KR00053",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb21e",
    "collegeName": "AURORA'S ENGINEERING COLLEGE",
    "collegeCode": "TL00009",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb099",
    "collegeName": "BANGALORE TECHNOLOGICAL INSTITUTE",
    "collegeCode": "KR00030",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb246",
    "collegeName": "TRINITY COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "TL00054",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb107",
    "collegeName": "TRINITY COLLEGE OF ENGINEERING",
    "collegeCode": "KR00051",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae96",
    "collegeName": "O P Jindal University, Raigarh",
    "collegeCode": "CH00006",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9b",
    "collegeName": "Amitya University,Rajkot",
    "collegeCode": "GU00002",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9d",
    "collegeName": " Charotar University of Science and Technology(CHARUSAT),Anand",
    "collegeCode": "GU00005",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea6",
    "collegeName": "Karnavati University",
    "collegeCode": "GU00016",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeae",
    "collegeName": " Sankalchand Patel University",
    "collegeCode": "GU00026",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb3",
    "collegeName": "Amity University ,Gurugram",
    "collegeCode": "HR00002",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebd",
    "collegeName": " M.V.N. University,Palwal",
    "collegeCode": "HR00013",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeda",
    "collegeName": " Arni University , Kangra",
    "collegeCode": "HP00001",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeea",
    "collegeName": "Capital University, Koderma",
    "collegeCode": "JH00005",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef1",
    "collegeName": "Adichunchanagiriuniversity,Mandya",
    "collegeCode": "KR00001",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf03",
    "collegeName": "G.h. raisoni university,Chhindwara",
    "collegeCode": "MP00008",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0f",
    "collegeName": "Mansarovar Global University,Gadia",
    "collegeCode": "MP00021",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf23",
    "collegeName": "Sant Baba Bhag Singh University",
    "collegeCode": "PN00010",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf29",
    "collegeName": " Career Point University, Kota",
    "collegeCode": "RJ00006",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf3d",
    "collegeName": " Shyam University",
    "collegeCode": "RJ00029",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4a",
    "collegeName": "VELS University, Pallavaram",
    "collegeCode": "TM00012",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf73",
    "collegeName": "AVANTHI INSTITUTE OF ENGINERRING AND TECHNOLOGY",
    "collegeCode": "AP00032",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf74",
    "collegeName": "AVANTHI'S RESEARCH & TECHNOLOGICAL ACADEMY",
    "collegeCode": "AP00033",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf78",
    "collegeName": "BAPATLA WOMEN'S ENGINEERING COLLEGE",
    "collegeCode": "AP00038",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf7c",
    "collegeName": "BONAM VENKATA CHALAMAYYA ENGINEERING COLLEGE",
    "collegeCode": "AP00042",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf83",
    "collegeName": "CHIRALA ENGINEERING COLLEGE",
    "collegeCode": "AP00050",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf8a",
    "collegeName": "DR SAMUEL GEORGE INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00058",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf8d",
    "collegeName": "ELURU COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00062",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "date": "2023-05-24T01:39:36.195Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=1520237628&tracking_id=112889605712&bank_ref_no=null&order_status=Failure&failure_message=&payment_mode=Debit Card&card_name=State Bank of India&status_code=null&status_message=REJECTED BY GATEWAY(IPAY0100180 - Authentication not available)&currency=INR&amount=5300.00&billing_name=Dhanekula mounika&billing_address=Andhra pardesh&billing_city=Andhra pardesh&billing_state=Andhra pardesh&billing_zip=XXXXXX&billing_country=India&billing_tel=9121539139&billing_email=dlrdlr4584@gmail.com&delivery_name=Dhanekula mounika&delivery_address=Andhra pardesh&delivery_city=Andhra pardesh&delivery_state=Andhra pardesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9121539139&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=24/05/2023 07:09:30&bin_country=INDIA",
    "_id": "646d6ad8bdf587a59bdff271"
    },
    {
    "paymentStatus": "order_id=1520237628&tracking_id=112889607074&bank_ref_no=314427539842&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Dhanekula mounika&billing_address=Andhra pardesh&billing_city=Andhra pardesh&billing_state=Andhra pardesh&billing_zip=XXXXXX&billing_country=India&billing_tel=9121539139&billing_email=dlrdlr4584@gmail.com&delivery_name=Dhanekula mounika&delivery_address=Andhra pardesh&delivery_city=Andhra pardesh&delivery_state=Andhra pardesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9121539139&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=24/05/2023 07:12:58&bin_country=",
    "_id": "646d6ba6bdf587a59bdff284"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=1520237628&tracking_id=112899355714&bank_ref_no=315406087866&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=25000.00&billing_name=Dhanekula mounika&billing_address=12-4-8 Bonda Vari Veedhi Bavisetti Vari Peta Eluru Mandalam&billing_city=Eluru&billing_state=Andhra pardesh&billing_zip=534001&billing_country=India&billing_tel=9121539139&billing_email=dlrdlr4584@gmail.com&delivery_name=Dhanekula mounika&delivery_address=12-4-8 Bonda Vari Veedhi Bavisetti Vari Peta Eluru Mandalam&delivery_city=Eluru&delivery_state=Andhra pardesh&delivery_zip=534001&delivery_country=India&delivery_tel=9121539139&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=03/06/2023 12:55:04&bin_country=",
    "_id": "647aead5b744d4edb7e7b9e4"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "aadhaar": "1685776696813New Doc 06-03-2023 12.34.pdf",
    "tenthCert": "1685776767523New Doc 06-03-2023 12.27.pdf",
    "twelveCert": "1685776779923Image to pdf_31052023_170042.pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "_id": "646a1eec584ecfa43e581fe1",
    "photo": "https://ucarecdn.com/7153fc5e-8dbc-4993-8be1-6027e0307133/-/crop/402x402/0,11/-/preview/",
    "signature": "https://ucarecdn.com/a41d9c9a-c42a-4cf8-8eb3-4f05a7dee70c/-/crop/720x288/0,5/-/preview/",
    "slNo": "116",
    "applicationNo": "14420239166",
    "studentName": "Chhavi Yadav",
    "emailId": "chhaviyadav193@gmail.com",
    "password": "120686",
    "mobileNo": "8962296250",
    "phy": "58",
    "che": "74",
    "gk": "90",
    "math": "48",
    "markObtained": "270",
    "rank": "6356",
    "addressLine1": "Shanti Nagar Bhilai-3, Near Banhdhava Talab",
    "addressLine2": "Shanti Nagar Bhilai-3, Near Banhdhava Talab",
    "city": "Bhilai",
    "state": "Chhattisgarh",
    "pincode": "490021",
    "gender": "Male",
    "regDate": "14-4-2023",
    "fatherName": "Dinesh Kumar Yadav",
    "motherName": "Durga Yadav",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 5,
    "allotedCollege": [
    {
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "PUNJAB"
    }
    ],
    "date": "2023-05-21T16:55:34.508Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=14420239166&tracking_id=112887580344&bank_ref_no=445794&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Chhavi Yadav&billing_address=Chhattisgarh&billing_city=Chhattisgarh&billing_state=Chhattisgarh&billing_zip=XXXXXX&billing_country=India&billing_tel=8962296250&billing_email=chhaviyadav193@gmail.com&delivery_name=Chhavi Yadav&delivery_address=Chhattisgarh&delivery_city=Chhattisgarh&delivery_state=Chhattisgarh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=8962296250&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=21/05/2023 22:24:51&bin_country=INDIA",
    "_id": "646a4d06bdf587a59bdfde08"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=14420239166&tracking_id=112892205935&bank_ref_no=493814&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=25000.00&billing_name=CHHAVI YADAV&billing_address=SHANTINAGAR,BHILAI-3,DIST-DURG,CHHATTISGARH&billing_city=Bhilai&billing_state=Chhattisgarh&billing_zip=490021&billing_country=India&billing_tel=8962296250&billing_email=chhaviyadav193@gmail.com&delivery_name=CHHAVI YADAV&delivery_address=SHANTINAGAR,BHILAI-3,DIST-DURG,CHHATTISGARH&delivery_city=Bhilai&delivery_state=Chhattisgarh&delivery_zip=490021&delivery_country=India&delivery_tel=8962296250&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=26/05/2023 19:15:48&bin_country=INDIA",
    "_id": "6470b82bbdf587a59be00bea"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "tenthCert": "168510850055010th marksheet.pdf",
    "aadhaar": "1685108529582aadhar pdf 132.pdf",
    "twelveCert": "168510854760312th provisinal marksheet.pdf",
    "SeatAccept": false,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bb",
    "collegeName": "NEW HORIZON COLLEGE OF ENGINEERING ",
    "collegeCode": "KR00082",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb17a",
    "collegeName": "BHILAI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "CH00013",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb18a",
    "collegeName": "RUNGTA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "CH00029",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae96",
    "collegeName": "O P Jindal University, Raigarh",
    "collegeCode": "CH00006",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb18c",
    "collegeName": "SHRI SHANKARACHARYA TECHNICAL CAMPUS",
    "collegeCode": "CH00031",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb17e",
    "collegeName": "COLUMBIA INSTITUTE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "CH00017",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb17b",
    "collegeName": "BHILAI INSTITUTE OF TECHNOLOGY,RAIPUR.",
    "collegeCode": "CH00014",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb148",
    "collegeName": "D Y PATIL EDUCATION SOCIETY'S D Y PAIL TECHNICAL CAMPUS FACULTY OF ENGINEERING AND FACULTY OF MANAGEMENT TALSANDE KOLHAPUR",
    "collegeCode": "MH00016",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb177",
    "collegeName": "ZEAL COLLEGE OF ENGINEERING AND RESEARCH ,PUNE",
    "collegeCode": "MH00071",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf52",
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb237",
    "collegeName": "MAHATMA GANDHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00039",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf51",
    "collegeName": "SR University,",
    "collegeCode": "TL00007",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound": [
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb158",
    "collegeName": "K J COLLEGE OF ENGINEERING & MANAGEMENT RESEARCH",
    "collegeCode": "MH00033",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound1Submit": true,
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581fe3",
    "photo": "https://ucarecdn.com/df0d2d87-d142-4c2c-ba03-3bca427bf1cf/-/crop/269x269/0,3/-/preview/",
    "signature": "https://ucarecdn.com/a807e7ed-8fac-43fe-bb47-6dd211ab7085/-/crop/422x169/0,26/-/preview/",
    "slNo": "128",
    "applicationNo": "27420236935",
    "studentName": "SHAMITA ANGEL S",
    "emailId": "msebastin342@gmail.com",
    "password": "679729",
    "mobileNo": "6380442662",
    "phy": "50",
    "che": "70",
    "gk": "77",
    "math": "70",
    "markObtained": "267",
    "rank": "6428",
    "addressLine1": "72 SURVEYAR NAGAR",
    "addressLine2": "BALAKRISHNAPURAM",
    "city": "DINDIGUL",
    "state": "TAMIL NADU",
    "pincode": "624005",
    "gender": "Female",
    "regDate": "27-4-2023",
    "fatherName": "SEBASTIN MUTHU A",
    "motherName": "NIRMALA CATHERINE S",
    "progOpted": "Bio-Tech ( Bsc.BioTech / BioTech)",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 6,
    "allotedCollege": [
    {
    "collegeName": "DHANALAKSHMI SRINIVASAN ENGINEERING COLLEGE",
    "collegeCode": "TM00017",
    "branch": null,
    "programme": "Bio-Technology",
    "state": "TAMILNADU"
    }
    ],
    "date": "2023-05-23T17:21:46.636Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=27420236935&tracking_id=112889508307&bank_ref_no=-5102&order_status=Failure&failure_message=&payment_mode=Debit Card&card_name=MasterCard Debit Card&status_code=null&status_message=DECLINED&currency=INR&amount=5300.00&billing_name=SHAMITA ANGEL S&billing_address=TAMIL NADU&billing_city=TAMIL NADU&billing_state=TAMIL NADU&billing_zip=XXXXXX&billing_country=India&billing_tel=6380442662&billing_email=msebastin342@gmail.com&delivery_name=SHAMITA ANGEL S&delivery_address=TAMIL NADU&delivery_city=TAMIL NADU&delivery_state=TAMIL NADU&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6380442662&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=23/05/2023 22:51:40&bin_country=INDIA",
    "_id": "646cf62abdf587a59bdff1b0"
    },
    {
    "paymentStatus": "order_id=27420236935&tracking_id=112889513893&bank_ref_no=null&order_status=Failure&failure_message=&payment_mode=Debit Card&card_name=State Bank of India&status_code=null&status_message=REJECTED BY GATEWAY(IPAY0100180 - Authentication not available)&currency=INR&amount=5300.00&billing_name=SHAMITA ANGEL S&billing_address=TAMIL NADU&billing_city=TAMIL NADU&billing_state=TAMIL NADU&billing_zip=XXXXXX&billing_country=India&billing_tel=6380442662&billing_email=msebastin342@gmail.com&delivery_name=SHAMITA ANGEL S&delivery_address=TAMIL NADU&delivery_city=TAMIL NADU&delivery_state=TAMIL NADU&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6380442662&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=23/05/2023 22:55:52&bin_country=INDIA",
    "_id": "646cf72cbdf587a59bdff1bb"
    },
    {
    "paymentStatus": "order_id=27420236935&tracking_id=112889524095&bank_ref_no=93&order_status=Failure&failure_message=&payment_mode=Debit Card&card_name=RuPay&status_code=null&status_message=N:93:Transaction cannot be completed&currency=INR&amount=5300.00&billing_name=SHAMITA ANGEL S&billing_address=TAMIL NADU&billing_city=TAMIL NADU&billing_state=TAMIL NADU&billing_zip=XXXXXX&billing_country=India&billing_tel=6380442662&billing_email=msebastin342@gmail.com&delivery_name=SHAMITA ANGEL S&delivery_address=TAMIL NADU&delivery_city=TAMIL NADU&delivery_state=TAMIL NADU&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6380442662&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=23/05/2023 23:13:27&bin_country=INDIA",
    "_id": "646cfb40bdf587a59bdff1de"
    },
    {
    "paymentStatus": "order_id=27420236935&tracking_id=112890036157&bank_ref_no=CHM6757233&order_status=Success&failure_message=&payment_mode=Net Banking&card_name=State Bank of India&status_code=null&status_message=Completed Successfully&currency=INR&amount=5300.00&billing_name=SHAMITA ANGEL S&billing_address=TAMIL NADU&billing_city=TAMIL NADU&billing_state=TAMIL NADU&billing_zip=XXXXXX&billing_country=India&billing_tel=6380442662&billing_email=msebastin342@gmail.com&delivery_name=SHAMITA ANGEL S&delivery_address=TAMIL NADU&delivery_city=TAMIL NADU&delivery_state=TAMIL NADU&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=6380442662&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=24/05/2023 14:29:57&bin_country=",
    "_id": "646dd20ebdf587a59bdff7be"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Freeze",
    "choiceFillingRound1": [
    {
    "id": "644ce27222264cc7e099b92c",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Bio-Technology",
    "state": "TAMILNADU",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b92d",
    "collegeName": "Hindustan Institute of Technology and Science",
    "collegeCode": "TM00005",
    "programme": "Bio-Technology",
    "state": "TAMILNADU",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b93d",
    "collegeName": "DHANALAKSHMI SRINIVASAN ENGINEERING COLLEGE",
    "collegeCode": "TM00017",
    "programme": "Bio-Technology",
    "state": "TAMILNADU",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b93e",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING",
    "collegeCode": "TM00019",
    "programme": "Bio-Technology",
    "state": "TAMILNADU",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b8fe",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Bio-Technology",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b8ff",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Bio-Technology",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b900",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Bio-Technology",
    "state": "ANDHRA PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b901",
    "collegeName": " ITM University ,Raipur",
    "collegeCode": "CH00004",
    "programme": "Bio-Technology",
    "state": "CHATTISGARH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b902",
    "collegeName": "Kalinga University",
    "collegeCode": "CH00010",
    "programme": "Bio-Technology",
    "state": "CHATTISGARH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b903",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Bio-Technology",
    "state": "GUJARAT",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b904",
    "collegeName": "Rai University,Ahmedabad",
    "collegeCode": "GU00024",
    "programme": "Bio-Technology",
    "state": "GUJARAT",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b909",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Bio-Technology",
    "state": "UTTAR PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b90b",
    "collegeName": " IFTM University, Moradabad",
    "collegeCode": "UP00009",
    "programme": "Bio-Technology",
    "state": "UTTAR PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b90c",
    "collegeName": " Integral University, Lucknow",
    "collegeCode": "UP00010",
    "programme": "Bio-Technology",
    "state": "UTTAR PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b90d",
    "collegeName": " Invertis University, Bareilly",
    "collegeCode": "UP00011",
    "programme": "Bio-Technology",
    "state": "UTTAR PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b913",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Bio-Technology",
    "state": "MADHYA PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b918",
    "collegeName": "  Mangalayatan University",
    "collegeCode": "MP00022",
    "programme": "Bio-Technology",
    "state": "MADHYA PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b919",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Bio-Technology",
    "state": "MADHYA PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b91a",
    "collegeName": "Ajeenkya D.Y. Patil  University,Pune",
    "collegeCode": "MH00001",
    "programme": "Bio-Technology",
    "state": "MAHARASHTRA",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b91e",
    "collegeName": "D.A.V. University,Jalandhar",
    "collegeCode": "PN00004",
    "programme": "Bio-Technology",
    "state": "PUNJAB",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b91f",
    "collegeName": " RIMT University,Gobindgarh",
    "collegeCode": "PN00009",
    "programme": "Bio-Technology",
    "state": "PUNJAB",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b921",
    "collegeName": "Bhagwant University,Ajmer",
    "collegeCode": "RJ00005",
    "programme": "Bio-Technology",
    "state": "RAJASTHAN",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b922",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Bio-Technology",
    "state": "RAJASTHAN",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b937",
    "collegeName": "COLLEGE OF ENGINEERING BHUBANESWAR",
    "collegeCode": "OD00018",
    "programme": "Bio-Technology",
    "state": "ODISHA",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b939",
    "collegeName": "CGC COLLEGE OF ENGINEERING",
    "collegeCode": "PN00029",
    "programme": "Bio-Technology",
    "state": "PUNJAB",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b942",
    "collegeName": "IMS ENGINEERING COLLEGE",
    "collegeCode": "UP00056",
    "programme": "Bio-Technology",
    "state": "UTTAR PRADESH",
    "branch": null
    },
    {
    "id": "644ce27222264cc7e099b943",
    "collegeName": "R. R. INSTITUTE OF MODERN TECHNOLOGY",
    "collegeCode": "UP00076",
    "programme": "Bio-Technology",
    "state": "UTTAR PRADESH",
    "branch": null
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581fe5",
    "photo": "https://ucarecdn.com/5b6c37c5-0c88-4b4f-b1e7-17269dec94a3/-/crop/1286x1287/0,157/-/preview/",
    "signature": "https://ucarecdn.com/f51cdd2f-b89e-4ea8-9979-d64ae09828a1/-/crop/362x145/0,53/-/preview/",
    "slNo": "142",
    "applicationNo": "3520233436",
    "studentName": "Kushagra Yadav",
    "emailId": "cy5074392@gmail.com",
    "password": "337640",
    "mobileNo": "9450442742",
    "phy": "67",
    "che": "63",
    "gk": "84",
    "math": "48",
    "markObtained": "262",
    "rank": "6573",
    "addressLine1": "18v/2v/3 Muirabad",
    "addressLine2": "Allahabad",
    "city": "Allahabad",
    "state": "Uttar Pradesh",
    "pincode": "211002",
    "gender": "Male",
    "regDate": "3-5-2023",
    "fatherName": "Ram Chandra Yadav",
    "motherName": "Pushpa Yadav",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 7,
    "allotedCollege": [
    {
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "KARNATAKA"
    }
    ],
    "date": "2023-05-23T13:51:34.336Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=3520233436&tracking_id=112889315515&bank_ref_no=350935550301&order_status=Failure&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Failed&currency=INR&amount=5300.00&billing_name=Kushagra Yadav&billing_address=Uttar Pradesh&billing_city=Uttar Pradesh&billing_state=Uttar Pradesh&billing_zip=XXXXXX&billing_country=India&billing_tel=9450442742&billing_email=cy5074392@gmail.com&delivery_name=Kushagra Yadav&delivery_address=Uttar Pradesh&delivery_city=Uttar Pradesh&delivery_state=Uttar Pradesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9450442742&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=23/05/2023 19:21:29&bin_country=",
    "_id": "646cc4e6bdf587a59bdff076"
    },
    {
    "paymentStatus": "order_id=3520233436&tracking_id=112889320352&bank_ref_no=350935905323&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Kushagra Yadav&billing_address=Uttar Pradesh&billing_city=Uttar Pradesh&billing_state=Uttar Pradesh&billing_zip=XXXXXX&billing_country=India&billing_tel=9450442742&billing_email=cy5074392@gmail.com&delivery_name=Kushagra Yadav&delivery_address=Uttar Pradesh&delivery_city=Uttar Pradesh&delivery_state=Uttar Pradesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9450442742&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=23/05/2023 19:23:13&bin_country=",
    "_id": "646cc557bdf587a59bdff08a"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=3520233436&tracking_id=112898859405&bank_ref_no=315344856985&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=25000.00&billing_name=Kushagra Yadav&billing_address=18v/2v/3&billing_city=Allahabad&billing_state=Uttar Pradesh&billing_zip=211002&billing_country=India&billing_tel=9450442742&billing_email=cy5074392@gmail.com&delivery_name=Kushagra Yadav&delivery_address=18v/2v/3&delivery_city=Allahabad&delivery_state=Uttar Pradesh&delivery_zip=211002&delivery_country=India&delivery_tel=9450442742&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=02/06/2023 20:36:18&bin_country=",
    "_id": "647a0573b744d4edb7e7b953"
    },
    {
    "paymentStatus": "order_id=3520233436&tracking_id=112905799616&bank_ref_no=null&order_status=Aborted&failure_message=&payment_mode=null&card_name=null&status_code=&status_message=Cancel reason is not specified by the customer.&currency=INR&amount=25000.00&billing_name=Kushagra Yadav&billing_address=&billing_city=Allahabad&billing_state=Uttar Pradesh&billing_zip=211002&billing_country=India&billing_tel=9450442742&billing_email=cy5074392@gmail.com&delivery_name=&delivery_address=&delivery_city=&delivery_state=&delivery_zip=&delivery_country=&delivery_tel=&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=null&response_code=&billing_notes=&trans_date=null&bin_country=",
    "_id": "64833897b744d4edb7e7e4b7"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Freeze",
    "tenthCert": "1685718149716ChintooMarksheet.pdf",
    "aadhaar": "1685718282100ChintooAadhar.pdf",
    "SeatAccept": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef1",
    "collegeName": "Adichunchanagiriuniversity,Mandya",
    "collegeCode": "KR00001",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb099",
    "collegeName": "BANGALORE TECHNOLOGICAL INSTITUTE",
    "collegeCode": "KR00030",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0aa",
    "collegeName": "JAIN COLLEGE OF ENGINEERING AND RESEARCH",
    "collegeCode": "KR00058",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef1",
    "collegeName": "Adichunchanagiriuniversity,Mandya",
    "collegeCode": "KR00001",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec6",
    "collegeName": "Babu Banarasi Das University, Lucknow",
    "collegeCode": "UP00003",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaecf",
    "collegeName": "Noida International University, Gautam Budh Nagar",
    "collegeCode": "UP00013",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec8",
    "collegeName": "G.L.A. University,Mathura",
    "collegeCode": "UP00005",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf63",
    "collegeName": "A.M.REDDY MEMORIAL COLLEGE OF ENGINEERING& TECHNOLOGY",
    "collegeCode": "AP00010",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9b",
    "collegeName": "Amitya University,Rajkot",
    "collegeCode": "GU00002",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581ffb",
    "photo": "https://ucarecdn.com/c55b9ca2-5f94-45ce-bfaf-3a43696e16df/-/crop/400x400/0,75/-/preview/",
    "signature": "https://ucarecdn.com/e55ca0c0-ccac-45c3-933b-b178beb8a964/-/crop/149x60/0,3/-/preview/",
    "slNo": "134",
    "applicationNo": "1520231374",
    "studentName": "Arnav Sharma",
    "emailId": "arnavsxs23@gmail.com",
    "password": "759106",
    "mobileNo": "9929100153",
    "phy": "17",
    "che": "9",
    "gk": "19",
    "math": "42",
    "markObtained": "87",
    "rank": "9856",
    "addressLine1": "30, jangid Bhawan M.I. road",
    "addressLine2": "opp gpo jaipur",
    "city": "jaipur",
    "state": "rajasthan",
    "pincode": "302001",
    "gender": "Male",
    "regDate": "1-5-2023",
    "fatherName": "Rahul Sharma",
    "motherName": "Smriti Sharma",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "branch": "Computer Science Engineering with Data science ",
    "programme": "Engineering",
    "state": "GUJARAT"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2e",
    "collegeName": "J.K. Lakshmipat University, Jaipur",
    "collegeCode": "RJ00013",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8c",
    "collegeName": "SIKKIM MANIPAL UNIVERSITY ",
    "collegeCode": "NE00002",
    "programme": "Engineering",
    "state": "NORTH EAST",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9c",
    "collegeName": "Bhagwan Mahavir University",
    "collegeCode": "GU00004",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9b",
    "collegeName": "Amitya University,Rajkot",
    "collegeCode": "GU00002",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae8c",
    "collegeName": "SIKKIM MANIPAL UNIVERSITY ",
    "collegeCode": "NE00002",
    "programme": "Engineering",
    "state": "NORTH EAST",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2e",
    "collegeName": "J.K. Lakshmipat University, Jaipur",
    "collegeCode": "RJ00013",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf36",
    "collegeName": " Nirwan University",
    "collegeCode": "RJ00022",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2e",
    "collegeName": "J.K. Lakshmipat University, Jaipur",
    "collegeCode": "RJ00013",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Mechanical Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    }
    ],
    "date": "2023-05-22T03:04:31.735Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=1520231374&tracking_id=112887684280&bank_ref_no=null&order_status=Timeout&failure_message=&payment_mode=null&card_name=&status_code=&status_message=Session Expired&currency=INR&amount=5300.00&billing_name=Arnav Sharma&billing_address=rajasthan&billing_city=rajasthan&billing_state=rajasthan&billing_zip=XXXXXX&billing_country=India&billing_tel=9929100153&billing_email=arnavsxs23@gmail.com&delivery_name=&delivery_address=&delivery_city=&delivery_state=&delivery_zip=&delivery_country=&delivery_tel=&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=null&bin_country=",
    "_id": "646adbbfbdf587a59bdfdeac"
    },
    {
    "paymentStatus": "order_id=1520231374&tracking_id=112887694559&bank_ref_no=314226838991&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Arnav Sharma&billing_address=rajasthan&billing_city=rajasthan&billing_state=rajasthan&billing_zip=XXXXXX&billing_country=India&billing_tel=9929100153&billing_email=arnavsxs23@gmail.com&delivery_name=Arnav Sharma&delivery_address=rajasthan&delivery_city=rajasthan&delivery_state=rajasthan&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9929100153&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=22/05/2023 08:35:37&bin_country=",
    "_id": "646adc09bdf587a59bdfdec1"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581feb",
    "photo": "https://ucarecdn.com/02058581-7ca6-42fb-b88a-d75f79885fbe/-/crop/962x962/3,0/-/preview/",
    "signature": "https://ucarecdn.com/16d9faea-50e3-4fe7-a745-0ac9e595092d/-/crop/875x350/63,0/-/preview/",
    "slNo": "131",
    "applicationNo": "27420239919",
    "studentName": "Basole Kavish",
    "emailId": "bkeshavrao@gmail.com",
    "password": "Paradise23!!",
    "mobileNo": "9948097875",
    "phy": "39",
    "che": "64",
    "gk": "83",
    "math": "44",
    "markObtained": "230",
    "rank": "7428",
    "addressLine1": "HNO 5-9-470/3/30 Plot No 30 Sai Krishna Enclave Phase 3",
    "addressLine2": "Yapral",
    "city": "HYDERABAD",
    "state": "TELANGANA",
    "pincode": "500087",
    "gender": "Male",
    "regDate": "27-4-2023",
    "fatherName": "B Keshav Rao",
    "motherName": "Veena Basole",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "TELANGANA"
    }
    ],
    "date": "2023-05-25T09:34:16.009Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=27420239919&tracking_id=112891031180&bank_ref_no=144955&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Basole Kavish&billing_address=TELANGANA&billing_city=TELANGANA&billing_state=TELANGANA&billing_zip=XXXXXX&billing_country=India&billing_tel=9948097875&billing_email=bkeshavrao@gmail.com&delivery_name=Basole Kavish&delivery_address=TELANGANA&delivery_city=TELANGANA&delivery_state=TELANGANA&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9948097875&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=25/05/2023 15:04:15&bin_country=INDIA",
    "_id": "646f2b98bdf587a59bdffcb5"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb237",
    "collegeName": "MAHATMA GANDHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00039",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb240",
    "collegeName": "SR INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00048",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb243",
    "collegeName": "TEEGALA KRISHNA REDDY ENGINEERING COLLEGE",
    "collegeCode": "TL00051",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb234",
    "collegeName": "KONERU LAKSHMAIAH EDUCATION FOUNDATION DEEMED TO B UNIVERSITY OFF CAMPUS AZIZNAGAR HYDERABAD",
    "collegeCode": "TL00035",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22b",
    "collegeName": "GEETHANJALI COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "TL00025",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22d",
    "collegeName": "GURU NANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00028",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22e",
    "collegeName": "GURU NANAK INSTITUTIONS TECHNICAL CAMPUS",
    "collegeCode": "TL00029",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb249",
    "collegeName": "VISVESVARAYA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "TL00057",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf50",
    "collegeName": "Malla Reddy University,",
    "collegeCode": "TL00006",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf52",
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf51",
    "collegeName": "SR University,",
    "collegeCode": "TL00007",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb239",
    "collegeName": "MALLA REDDY ENGINEERING COLLEGE",
    "collegeCode": "TL00041",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb226",
    "collegeName": "CMR COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00019",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb227",
    "collegeName": "CMR ENGINEERING COLLEGE",
    "collegeCode": "TL00021",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb242",
    "collegeName": "SREE VISVESVARAYA INSTITUTE OF TECHNOLOGY AND SCIENCE",
    "collegeCode": "TL00050",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb244",
    "collegeName": "TKR COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00052",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4c",
    "collegeName": "Aurora Higher Education & Research Academy,",
    "collegeCode": "TL00002",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "_id": "646a1eec584ecfa43e581ff1",
    "photo": "https://ucarecdn.com/13e10f33-2923-4fa9-83de-ab981150d2b3/-/crop/768x768/0,11/-/preview/",
    "signature": "https://ucarecdn.com/22a5219c-d956-4209-a669-840c377f74c2/-/crop/898x359/0,74/-/preview/",
    "slNo": "108",
    "applicationNo": "7420234060",
    "studentName": "ANUJ RANA",
    "emailId": "anujrp0909@gmail.com",
    "password": "185575",
    "mobileNo": "7807052832",
    "phy": "39",
    "che": "54",
    "gk": "48",
    "math": "52",
    "markObtained": "193",
    "rank": "8493",
    "addressLine1": "VILL. MANDHIYAR PO. UTPUR",
    "addressLine2": "TEHSIL BAMSON AT TAUNI DEVI",
    "city": "HAMIRPUR",
    "state": "HIMACHAL PRADESH",
    "pincode": "177022",
    "gender": "Male",
    "regDate": "7-4-2023",
    "fatherName": "BALBIR SINGH RANA",
    "motherName": "RANJNA DEVI",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "SWAMI VIVEKANAND INSTITUTE OF ENGG. & TECH.",
    "collegeCode": "PN00063",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "PUNJAB"
    }
    ],
    "date": "2023-05-22T14:45:31.142Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=7420234060&tracking_id=112888399621&bank_ref_no=314284388201&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=ANUJ RANA&billing_address=HIMACHAL PRADESH&billing_city=HIMACHAL PRADESH&billing_state=HIMACHAL PRADESH&billing_zip=XXXXXX&billing_country=India&billing_tel=7807052832&billing_email=anujrp0909@gmail.com&delivery_name=ANUJ RANA&delivery_address=HIMACHAL PRADESH&delivery_city=HIMACHAL PRADESH&delivery_state=HIMACHAL PRADESH&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7807052832&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=22/05/2023 20:15:25&bin_country=",
    "_id": "646b800bbdf587a59bdfe6a4"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb1df",
    "collegeName": "SWAMI VIVEKANAND INSTITUTE OF ENGG. & TECH.",
    "collegeCode": "PN00063",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1c5",
    "collegeName": "CGC COLLEGE OF ENGINEERING",
    "collegeCode": "PN00029",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1cc",
    "collegeName": "GURU NANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "PN00038",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1d",
    "collegeName": "D.A.V. University,Jalandhar",
    "collegeCode": "PN00004",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1c7",
    "collegeName": "GGS COLLEGE OF MODERN TECHNOLOGY",
    "collegeCode": "PN00032",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedf",
    "collegeName": " Chitkara University,Solan",
    "collegeCode": "HP00006",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28c",
    "collegeName": "COLLEGE OF ENGINEERING ROORKEE",
    "collegeCode": "UT00017",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb295",
    "collegeName": "ROORKEE COLLEGE OF ENGINEERING",
    "collegeCode": "UT00028",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1e",
    "collegeName": " GNA University,Kapurthala",
    "collegeCode": "PN00005",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1d0",
    "collegeName": "KHALSA COLLEGE OF ENGINEERING & TECHNOLOGY, AMRITSAR",
    "collegeCode": "PN00043",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf24",
    "collegeName": "Sri Guru Granth Sahib World University",
    "collegeCode": "PN00011",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1d6",
    "collegeName": "SATYAM INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    "collegeCode": "PN00053",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1b9",
    "collegeName": "AMRITSAR GROUP OF COLLEGES",
    "collegeCode": "PN00014",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedb",
    "collegeName": "AP Goyal Shimla University,Shimla ",
    "collegeCode": "HP00002",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaede",
    "collegeName": "Career Point University, Hamirpur",
    "collegeCode": "HP00005",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeda",
    "collegeName": " Arni University , Kangra",
    "collegeCode": "HP00001",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee6",
    "collegeName": "Sri Sai University, Palampur",
    "collegeCode": "HP00013",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1c",
    "collegeName": " C.T. University,Ludhiana",
    "collegeCode": "PN00003",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1d1",
    "collegeName": "LUDHIANA GROUP OF COLLEGES",
    "collegeCode": "PN00045",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1c9",
    "collegeName": "GLOBAL GROUP OF INSTITUTES",
    "collegeCode": "PN00034",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedd",
    "collegeName": "Bahra University,Solan",
    "collegeCode": "HP00004",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee7",
    "collegeName": " Shoolini University of Biotechnology and Management Sciences",
    "collegeCode": "HP00014",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedc",
    "collegeName": "Baddi University of Emerging Sciences andTechnologies,Solan",
    "collegeCode": "HP00003",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaee4",
    "collegeName": " Maharishi Markandeshwar University",
    "collegeCode": "HP00011",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound": [],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581ff5",
    "photo": "https://ucarecdn.com/c4819e96-c121-4a1b-adc7-294eec094afb/-/crop/413x413/0,31/-/preview/",
    "signature": "https://ucarecdn.com/9fda5f85-2b4f-4869-bb56-df6c0d855fbc/-/crop/805x323/0,69/-/preview/",
    "slNo": "132",
    "applicationNo": "29420236747",
    "studentName": "KRISH TARUN ARORA",
    "emailId": "tarunarora1975@gmail.com",
    "password": "235841",
    "mobileNo": "9920442817",
    "phy": "48",
    "che": "35",
    "gk": "74",
    "math": "11",
    "markObtained": "168",
    "rank": "8806",
    "addressLine1": "B-503 AAKASH NIDHI RAMDEV PARK ROAD",
    "addressLine2": "MIRA ROAD EAST",
    "city": "Mira-Bhayandar",
    "state": "Maharashtra",
    "pincode": "401107",
    "gender": "Male",
    "regDate": "29-4-2023",
    "fatherName": "TARUN ARORA",
    "motherName": "LALITA ARORA",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "UTTAR PRADESH"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeb6",
    "collegeName": "BML Munjal University, Gurugram",
    "collegeCode": "HR00005",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeb6",
    "collegeName": "BML Munjal University, Gurugram",
    "collegeCode": "HR00005",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb265",
    "collegeName": "IIMT COLLEGE OF ENGINEERING",
    "collegeCode": "UP00054",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec6",
    "collegeName": "Babu Banarasi Das University, Lucknow",
    "collegeCode": "UP00003",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec6",
    "collegeName": "Babu Banarasi Das University, Lucknow",
    "collegeCode": "UP00003",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bb",
    "collegeName": "NEW HORIZON COLLEGE OF ENGINEERING ",
    "collegeCode": "KR00082",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb7",
    "collegeName": " G D Goenka University, Sohna Road, Gurugram",
    "collegeCode": "HR00006",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb7",
    "collegeName": " G D Goenka University, Sohna Road, Gurugram",
    "collegeCode": "HR00006",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering with AI Specialization"
    }
    ],
    "date": "2023-05-24T10:29:47.269Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=29420236747&tracking_id=112890133267&bank_ref_no=314493575580&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=KRISH TARUN ARORA&billing_address=Maharashtra&billing_city=Maharashtra&billing_state=Maharashtra&billing_zip=XXXXXX&billing_country=India&billing_tel=9920442817&billing_email=tarunarora1975@gmail.com&delivery_name=KRISH TARUN ARORA&delivery_address=Maharashtra&delivery_city=Maharashtra&delivery_state=Maharashtra&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9920442817&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=24/05/2023 15:59:44&bin_country=",
    "_id": "646de71bbdf587a59bdff83e"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=29420236747&tracking_id=112893207818&bank_ref_no=14348312&order_status=Success&failure_message=&payment_mode=Net Banking&card_name=Axis Bank&status_code=null&status_message=&currency=INR&amount=25000.00&billing_name=KRISH TARUN ARORA&billing_address=B-503 AAKASH NIDHI, RAMDEV PARK ROAD MIRA ROAD EAST&billing_city=Mira-Bhayandar&billing_state=Maharashtra&billing_zip=401107&billing_country=India&billing_tel=9920442817&billing_email=tarunarora1975@gmail.com&delivery_name=KRISH TARUN ARORA&delivery_address=B-503 AAKASH NIDHI, RAMDEV PARK ROAD MIRA ROAD EAST&delivery_city=Mira-Bhayandar&delivery_state=Maharashtra&delivery_zip=401107&delivery_country=India&delivery_tel=9920442817&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=27/05/2023 21:54:02&bin_country=",
    "_id": "64722ea6bdf587a59be013aa"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Float",
    "tenthCert": "1685204413717marksheet_xth_compress[1].pdf",
    "aadhaar": "1685204467197krish_aadhar.pdf",
    "SeatAccept": true,
    "loginEnabled": true
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "646a1eec584ecfa43e581ffe",
    "photo": "https://ucarecdn.com/595ec2be-6bce-48ee-b3bd-7f57424010de/-/crop/447x447/0,20/-/preview/",
    "signature": "https://ucarecdn.com/24b0f575-027a-4082-9d77-e06809508ba2/-/crop/190x76/0,0/-/preview/",
    "slNo": "115",
    "applicationNo": "13420238699",
    "studentName": "Debangshu Sengupta",
    "emailId": "debangshu1.sengupta@gmail.com",
    "password": "703703",
    "mobileNo": "9830953515",
    "phy": "31",
    "che": "19",
    "gk": "10",
    "math": "7",
    "markObtained": "67",
    "rank": "10265",
    "addressLine1": "FE-169, Sector-3",
    "addressLine2": "Salt Lake City",
    "city": "Kolkata",
    "state": "West Bengal",
    "pincode": "700106",
    "gender": "Male",
    "regDate": "13-4-2023",
    "fatherName": "Debashis Sengupta",
    "motherName": "Sukanya Sengupta",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 5,
    "allotedCollege": [
    {
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "TELANGANA"
    },
    {
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "TELANGANA"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf52",
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbb0ab",
    "collegeName": "JAIN DEEMED TO BE UNIVERSITY FACULTY OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "KR00059",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf45",
    "collegeName": "Dr. M.G.R. Educational and Research Institute,Chennai",
    "collegeCode": "TM00004",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf46",
    "collegeName": "Hindustan Institute of Technology and Science",
    "collegeCode": "TM00005",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf35",
    "collegeName": " NIIT university, Neemrana",
    "collegeCode": "RJ00021",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2c2",
    "collegeName": "ST. THOMAS' COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00060",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb29e",
    "collegeName": "BENGAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "WB00013",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2ab",
    "collegeName": "DURGAPUR INSTITUTE OF ADVANCED TECHNOLOGY & MANAGEMENT",
    "collegeCode": "WB00027",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf41",
    "collegeName": "University of Engineering & Management, Jaipur,",
    "collegeCode": "RJ00033",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28c",
    "collegeName": "COLLEGE OF ENGINEERING ROORKEE",
    "collegeCode": "UT00017",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb198",
    "collegeName": "COLLEGE OF ENGINEERING BHUBANESWAR",
    "collegeCode": "OD00018",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0b8",
    "collegeName": "MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT",
    "collegeCode": "KR00077",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28e",
    "collegeName": "DOON INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "UT00021",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a6",
    "collegeName": "NALANDA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00032",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    }
    ],
    "date": "2023-05-23T19:42:44.138Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=13420238699&tracking_id=112889566649&bank_ref_no=009202&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=Visa Debit Card&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Debangshu Sengupta&billing_address=FE-169, Sector-3, Salt Lake City, Kolkata&billing_city=Kolkata&billing_state=West Bengal&billing_zip=700106&billing_country=India&billing_tel=9830953515&billing_email=debangshu1.sengupta@gmail.com&delivery_name=Debangshu Sengupta&delivery_address=FE-169, Sector-3, Salt Lake City, Kolkata&delivery_city=Kolkata&delivery_state=West Bengal&delivery_zip=700106&delivery_country=India&delivery_tel=9830953515&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=24/05/2023 01:12:43&bin_country=INDIA",
    "_id": "646d1734bdf587a59bdff21a"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "",
    "tenthCert": "1688479666075Debangshu Sengupta ICSE 2021 Mark Sheet.pdf",
    "aadhaar": "1688479927523Aadhar Card Debangshu 1 Page.pdf",
    "twelveCert": "1688479953113Statement of Marks ISC 2023 -  Debangshu Sengupta.pdf",
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "6486d9771554d71edae1ebbc",
    "slNo": "1111",
    "applicationNo": "29520235091",
    "studentName": "Abhishek kumar Singh ",
    "emailId": "singh.abhishek73821@gmail.com",
    "mobileNo": "9652809593",
    "password": "417805",
    "phy": "77",
    "che": "77",
    "gk": "76",
    "math": "63",
    "total": "293",
    "rank": "3878",
    "dob": "04/07/2005",
    "addressLine1": "G4, SRI SAI RESIDENCY, SRI VENKATESHWARA OFFICERS COLONY",
    "addressLine2": "RK PURAM, SECUNDERABAD",
    "city": "SECUNDERABAD",
    "state": "Telangana",
    "pincode": 500056,
    "gender": "Male",
    "regdDate": "12/05/2023",
    "fatherName": "MANOJ KUMAR SINGH",
    "motherName": "SUNITA SINGH",
    "photo": "https://ucarecdn.com/68e6bc40-ced3-4c57-92aa-185fc890148a/-/crop/1400x1402/0,50/-/preview/",
    "signature": "https://ucarecdn.com/8b853353-a9e6-4744-8284-819b9c83f180/-/crop/755x302/0,327/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "phase": "4",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "TELANGANA"
    }
    ],
    "date": "2023-06-15T10:01:42.616Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=29520235091&tracking_id=112911646679&bank_ref_no=316688566951&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Abhishek kumar Singh&billing_address=Telangana&billing_city=Telangana&billing_state=Telangana&billing_zip=XXXXXX&billing_country=India&billing_tel=9652809593&billing_email=singh.abhishek73821@gmail.com&delivery_name=Abhishek kumar Singh&delivery_address=Telangana&delivery_city=Telangana&delivery_state=Telangana&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9652809593&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=15/06/2023 15:31:38&bin_country=",
    "_id": "648ae186b744d4edb7e7fd60"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf7e",
    "collegeName": "CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00044",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf55",
    "collegeName": "Graphic Era,Dehradun",
    "collegeCode": "UT00003",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf51",
    "collegeName": "SR University,",
    "collegeCode": "TL00007",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb255",
    "collegeName": "ASHOKA INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    "collegeCode": "UP00035",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb107",
    "collegeName": "TRINITY COLLEGE OF ENGINEERING",
    "collegeCode": "KR00051",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9c",
    "collegeName": "Bhagwan Mahavir University",
    "collegeCode": "GU00004",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec1",
    "collegeName": "SGT University,",
    "collegeCode": "HR00018",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb03a",
    "collegeName": "SARDAR VALLABHBHAI PATEL INSTITUTE OF TECHNOLOGY (SVIT), ANAND",
    "collegeCode": "GU00090",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb050",
    "collegeName": "ARAVALI COLLEGE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "HR00025",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb058",
    "collegeName": "DELHI GLOBAL INSTITUTE OF MANAGEMENT",
    "collegeCode": "HR00036",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf61",
    "collegeName": "The Neotia University, Sarisa",
    "collegeCode": "WB00008",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf68",
    "collegeName": "ADITYA ENGINEERING COLLEGE",
    "collegeCode": "AP00016",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb8",
    "collegeName": " Jagannath University ,Jhajjar",
    "collegeCode": "HR00008",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb269",
    "collegeName": "JMS GROUP OF INSTITUTIONS",
    "collegeCode": "UP00061",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb11b",
    "collegeName": "INFINITY MANAGEMENT & ENGINEERING COLLEGE",
    "collegeCode": "MP00035",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19b",
    "collegeName": "Gandhi Institute of Technological Advancement (GITA)",
    "collegeCode": "OD00021",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "6486d9771554d71edae1ebc7",
    "slNo": "158",
    "applicationNo": "19520234409",
    "studentName": "Ketan Shukla",
    "emailId": "shuklaketan750@gmail.com",
    "mobileNo": "7995895685",
    "password": "467938",
    "phy": "48",
    "che": "70",
    "gk": "83",
    "math": "51",
    "total": "252",
    "rank": "4876",
    "dob": "02/23/2005",
    "addressLine1": "Bhramrambika Nagar Colony, Balaji Nagar,Road no: 2f, Guru prudhvi towers",
    "addressLine2": "Beerumguda",
    "city": "Hyderabad",
    "state": "Telangana",
    "pincode": 502032,
    "gender": "Male",
    "regdDate": "19-5-2023",
    "fatherName": "Anand Ganesh prasad Shukla",
    "motherName": "Durga Shukla",
    "photo": "https://ucarecdn.com/2b813f34-db19-423f-a780-d19b22c6d997/-/crop/569x568/64,131/-/preview/",
    "signature": "https://ucarecdn.com/38247353-93be-4e02-bb40-f1cbdf4e330b/-/crop/1768x709/74,0/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "phase": "4",
    "__v": 5,
    "allotedCollege": [
    {
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "TELANGANA"
    }
    ],
    "date": "2023-06-13T03:30:26.861Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=19520234409&tracking_id=112909107802&bank_ref_no=316475214752&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=Ketan Shukla&billing_address=Telangana&billing_city=Telangana&billing_state=Telangana&billing_zip=XXXXXX&billing_country=India&billing_tel=7995895685&billing_email=shuklaketan750@gmail.com&delivery_name=Ketan Shukla&delivery_address=Telangana&delivery_city=Telangana&delivery_state=Telangana&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7995895685&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=9032404851&trans_date=13/06/2023 09:00:20&bin_country=",
    "_id": "6487e2d2b744d4edb7e7f4e3"
    }
    ],
    "paymentResponseAcceptanceFee": [
    {
    "paymentStatus": "order_id=19520234409&tracking_id=112913597945&bank_ref_no=316886915470&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=25000.00&billing_name=Ketan Shukla&billing_address=9/45,206,second floor,guru prudhvi towers, balaji nagar colony,road no 2f,Beerumguda&billing_city=Hyderabad&billing_state=Telangana&billing_zip=502032&billing_country=India&billing_tel=7995895685&billing_email=shuklaketan750@gmail.com&delivery_name=Ketan Shukla&delivery_address=9/45,206,second floor,guru prudhvi towers, balaji nagar colony,road no 2f,Beerumguda&delivery_city=Hyderabad&delivery_state=Telangana&delivery_zip=502032&delivery_country=India&delivery_tel=7995895685&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=25000.00&eci_value=&retry=N&response_code=&billing_notes=9032404851&trans_date=17/06/2023 15:04:22&bin_country=",
    "_id": "648d7e22b744d4edb7e80b35"
    }
    ],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Freeze",
    "aadhaar": "1686993556442ketan adhar card front.pdf",
    "tenthCert": "1686993660043Ketan shukla 10 memo.pdf",
    "twelveCert": "1686993715976Ketan Shukla 12 th memo.pdf",
    "SeatAccept": true,
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb237",
    "collegeName": "MAHATMA GANDHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00039",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf50",
    "collegeName": "Malla Reddy University,",
    "collegeCode": "TL00006",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb226",
    "collegeName": "CMR COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00019",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22f",
    "collegeName": "HOLY MARY INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00030",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb227",
    "collegeName": "CMR ENGINEERING COLLEGE",
    "collegeCode": "TL00021",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22e",
    "collegeName": "GURU NANAK INSTITUTIONS TECHNICAL CAMPUS",
    "collegeCode": "TL00029",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb230",
    "collegeName": "HOLY MARY INSTITUTE OF TECHNOLOGY & SCIENCE",
    "collegeCode": "TL00031",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb248",
    "collegeName": "VIDYA JYOTHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00056",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb232",
    "collegeName": "K.G.REDDY COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00033",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb234",
    "collegeName": "KONERU LAKSHMAIAH EDUCATION FOUNDATION DEEMED TO B UNIVERSITY OFF CAMPUS AZIZNAGAR HYDERABAD",
    "collegeCode": "TL00035",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb240",
    "collegeName": "SR INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00048",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4c",
    "collegeName": "Aurora Higher Education & Research Academy,",
    "collegeCode": "TL00002",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4c",
    "collegeName": "Aurora Higher Education & Research Academy,",
    "collegeCode": "TL00002",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf50",
    "collegeName": "Malla Reddy University,",
    "collegeCode": "TL00006",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf4c",
    "collegeName": "Aurora Higher Education & Research Academy,",
    "collegeCode": "TL00002",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf92",
    "collegeName": "GANDHI INSTITUTE OF TECHNOLOGY AND MANAGEMENT GITAM VISAKHAPATNAM",
    "collegeCode": "AP00068",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea0",
    "collegeName": "Gokul Global University,Patan",
    "collegeCode": "GU00010",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb239",
    "collegeName": "MALLA REDDY ENGINEERING COLLEGE",
    "collegeCode": "TL00041",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf52",
    "collegeName": "Woxsen University,",
    "collegeCode": "TL00008",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": true
    },
    {
    "_id": "6486d9771554d71edae1ebcc",
    "slNo": "178",
    "applicationNo": "30520233325",
    "studentName": "KARTIK VINOD KOHALE",
    "emailId": "kohalekartikvinod27@gmail.com",
    "mobileNo": "7888211155",
    "password": "452350",
    "phy": "74",
    "che": "55",
    "gk": "26",
    "math": "42",
    "total": "197",
    "rank": "5867",
    "dob": "05/27/2005",
    "addressLine1": "PLOT NO 87 NANADANVAN COLONY OLD BYPASS ROAD AMRAVATI MAHARASHTRA",
    "addressLine2": "BEHIND KRISHNA SUPER BAZAR",
    "city": "AMRAVATI",
    "state": "MAHARASHTRA",
    "pincode": 444606,
    "gender": "Male",
    "regdDate": "30-5-2023",
    "fatherName": "VINOD KESHVRAO KOHALE",
    "motherName": "JAYASHREE VINOD KOHALE",
    "photo": "https://ucarecdn.com/f22fb821-5e54-49aa-8256-51d2ec3b0c6f/-/crop/585x585/0,3/-/preview/",
    "signature": "https://ucarecdn.com/b7578cdf-3fb1-4790-b0e6-d5011c7ac16b/-/crop/500x200/0,68/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "phase": "4",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": "NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY ",
    "collegeCode": "UP00071",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "UTTAR PRADESH"
    }
    ],
    "date": "2023-06-12T09:11:06.710Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=30520233325&tracking_id=112908456775&bank_ref_no=222483&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=Visa Debit Card&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=KARTIK VINOD KOHALE&billing_address=MAHARASHTRA&billing_city=MAHARASHTRA&billing_state=MAHARASHTRA&billing_zip=XXXXXX&billing_country=India&billing_tel=7888211155&billing_email=kohalekartikvinod27@gmail.com&delivery_name=KARTIK VINOD KOHALE&delivery_address=MAHARASHTRA&delivery_city=MAHARASHTRA&delivery_state=MAHARASHTRA&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7888211155&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=12/06/2023 14:40:43&bin_country=INDIA",
    "_id": "6486e12ab744d4edb7e7ec4e"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "tenthCert": "168854617911810th Marksheet new.pdf",
    "twelveCert": "168854620571912th Marksheet new.pdf",
    "aadhaar": "1688546227420Aadhar card 60-1.pdf",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafef",
    "collegeName": "VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE",
    "collegeCode": "AP00176",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf9c",
    "collegeName": "GMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00079",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafdf",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "AP00158",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafd6",
    "collegeName": "SREE VIDYANIKETHAN ENGINEERING COLLEGE",
    "collegeCode": "AP00148",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb271",
    "collegeName": "NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY ",
    "collegeCode": "UP00071",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09b",
    "collegeName": "BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    "collegeCode": "KR00032",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Electronics & Communication Engineering"
    }
    ],
    "choiceFillingSpotRound": [
    {
    "id": "64017440560a04ddc6dbaf54",
    "collegeName": "DIT University",
    "collegeCode": "UT00002",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbafd6",
    "collegeName": "SREE VIDYANIKETHAN ENGINEERING COLLEGE",
    "collegeCode": "AP00148",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafef",
    "collegeName": "VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE",
    "collegeCode": "AP00176",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c2",
    "collegeName": "RNS INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00093",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Electronics & Communication Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb248",
    "collegeName": "VIDYA JYOTHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00056",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Electronics & Communication Engineering"
    }
    ],
    "choiceFillingSpotRound1Submit": true,
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "6486d9771554d71edae1ebbf",
    "slNo": "151",
    "applicationNo": "15520238348",
    "studentName": "Nilesh De",
    "emailId": "nileshde2023@gmail.com",
    "mobileNo": "9347086483",
    "password": "496834",
    "phy": "77",
    "che": "78",
    "gk": "77",
    "math": "44",
    "total": "276",
    "rank": "4276",
    "dob": "11/30/2004",
    "addressLine1": "kailashpuram",
    "addressLine2": "Cisf new complex kailasapuram, Qtr No T II/834 vizag AP",
    "city": "vizag",
    "state": "Andhra Pradesh",
    "pincode": 530008,
    "gender": "Male",
    "regdDate": "15-5-2023",
    "fatherName": "Jaharlal de",
    "motherName": "Champa Datta De",
    "photo": "https://ucarecdn.com/c64164ae-ba5b-4628-92e1-19221626cf22/-/crop/768x768/0,33/-/preview/",
    "signature": "https://ucarecdn.com/88b0c019-0217-4651-9aee-c3f4a5e81678/-/crop/1403x561/0,15/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "phase": "4",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "RAJASTHAN"
    }
    ],
    "date": "2023-06-13T08:56:04.638Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=15520238348&tracking_id=112909502925&bank_ref_no=754787&order_status=Success&failure_message=&payment_mode=Debit Card&card_name=Visa Debit Card&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Nilesh De&billing_address=Andhra Pradesh&billing_city=Andhra Pradesh&billing_state=Andhra Pradesh&billing_zip=XXXXXX&billing_country=India&billing_tel=9347086483&billing_email=nileshde2023@gmail.com&delivery_name=Nilesh De&delivery_address=Andhra Pradesh&delivery_city=Andhra Pradesh&delivery_state=Andhra Pradesh&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9347086483&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=13/06/2023 14:26:02&bin_country=INDIA",
    "_id": "64882f24b744d4edb7e7f5c1"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaf95",
    "collegeName": "GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING",
    "collegeCode": "AP00071",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22d",
    "collegeName": "GURU NANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00028",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb2b0",
    "collegeName": "GURUNANAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "WB00033",
    "programme": "Engineering",
    "state": "WEST BENGAL",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb22e",
    "collegeName": "GURU NANAK INSTITUTIONS TECHNICAL CAMPUS",
    "collegeCode": "TL00029",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf6f",
    "collegeName": "ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES",
    "collegeCode": "AP00027",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafc2",
    "collegeName": "RAGHU ENGINEERING COLLEGE",
    "collegeCode": "AP00126",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb226",
    "collegeName": "CMR COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "TL00019",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb227",
    "collegeName": "CMR ENGINEERING COLLEGE",
    "collegeCode": "TL00021",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb248",
    "collegeName": "VIDYA JYOTHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00056",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf41",
    "collegeName": "University of Engineering & Management, Jaipur,",
    "collegeCode": "RJ00033",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf99",
    "collegeName": "GIET ENGINEERING COLLEGE",
    "collegeCode": "AP00075",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1c5",
    "collegeName": "CGC COLLEGE OF ENGINEERING",
    "collegeCode": "PN00029",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf42",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f4",
    "collegeName": "JODHPUR INSTITUTE OF ENGINEERING & TECHNOLOGY, JODHPUR",
    "collegeCode": "RJ00056",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb237",
    "collegeName": "MAHATMA GANDHI INSTITUTE OF TECHNOLOGY",
    "collegeCode": "TL00039",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafc3",
    "collegeName": "RAGHU INSTITUTE OF TECHNOLOGY",
    "collegeCode": "AP00127",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb051",
    "collegeName": "ASIA PACIFIC INSTITUTE OF INFORMATION TECHNOLOGY OF INDIA ",
    "collegeCode": "HR00026",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb9",
    "collegeName": "K R Mangalam University, Gurugram",
    "collegeCode": "HR00009",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb239",
    "collegeName": "MALLA REDDY ENGINEERING COLLEGE",
    "collegeCode": "TL00041",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf47",
    "collegeName": "Kalasalingam Academy of Research and Higher Education",
    "collegeCode": "TM00006",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "6486d9771554d71edae1ebca",
    "slNo": "147",
    "applicationNo": "12520236178",
    "studentName": "Ritesh",
    "emailId": "phedvinod@gmail.com",
    "mobileNo": "9467135468",
    "password": "Rit3sh",
    "phy": "58",
    "che": "65",
    "gk": "78",
    "math": "43",
    "total": "244",
    "rank": "4965",
    "dob": "02/05/2006",
    "addressLine1": "Hno 815-816/3 Huda colony Ballabgarh Faridabad",
    "addressLine2": "H.no.815-816,sec-3 Huda colony, Ballabgarh, Faridabad, Haryana",
    "city": "Faridabad",
    "state": "Haryana",
    "pincode": 121004,
    "gender": "Male",
    "regdDate": "12-5-2023",
    "fatherName": "Vinod Kumar",
    "motherName": "Rajkumari",
    "photo": "https://ucarecdn.com/3d73a75a-170c-40e8-a469-91822ec8a7fd/-/crop/589x588/0,413/-/preview/",
    "signature": "https://ucarecdn.com/d4f76d47-0bd0-4e28-a623-2b34763b87c0/-/crop/590x236/0,522/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "phase": "4",
    "__v": 4,
    "allotedCollege": [
    {
    "collegeName": " Maharishi Markandeshwar University ,Mullana,Amballa",
    "collegeCode": "HR00011",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "HARYANA"
    }
    ],
    "date": "2023-06-12T15:10:08.837Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=12520236178&tracking_id=112908849560&bank_ref_no=337413&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Ritesh&billing_address=Haryana&billing_city=Haryana&billing_state=Haryana&billing_zip=XXXXXX&billing_country=India&billing_tel=9467135468&billing_email=phedvinod@gmail.com&delivery_name=Ritesh&delivery_address=Haryana&delivery_city=Haryana&delivery_state=Haryana&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9467135468&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=12/06/2023 20:40:07&bin_country=INDIA",
    "_id": "64873550b744d4edb7e7f1f2"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaebb",
    "collegeName": " Maharishi Markandeshwar University ,Mullana,Amballa",
    "collegeCode": "HR00011",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf20",
    "collegeName": " Lovely ProfessionalUniversity,Phagwara",
    "collegeCode": "PN00007",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4d",
    "collegeName": "Chaitanya (Deemed to be University),",
    "collegeCode": "TL00003",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb268",
    "collegeName": "JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "UP00060",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf1b",
    "collegeName": " Chitkara University,Patiala",
    "collegeCode": "PN00002",
    "programme": "Engineering",
    "state": "PUNJAB",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea2",
    "collegeName": " INDUS University,Ahmedabad",
    "collegeCode": "GU00012",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb066",
    "collegeName": "HINDU COLLEGE OF ENGINEERING",
    "collegeCode": "HR00050",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb19a",
    "collegeName": "Gandhi Engineering College",
    "collegeCode": "OD00020",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf26",
    "collegeName": " Apex University, Jaipur",
    "collegeCode": "RJ00002",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb271",
    "collegeName": "NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY ",
    "collegeCode": "UP00071",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4a",
    "collegeName": "VELS University, Pallavaram",
    "collegeCode": "TM00012",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    }
    ],
    "loginEnabled": false
    },
    {
    "choiceFillingSpotRound": [],
    "_id": "6486d9771554d71edae1ebc8",
    "slNo": "149",
    "applicationNo": "13520237000",
    "studentName": "Dennis Pradhan",
    "emailId": "swayam210406@gmail.com",
    "mobileNo": "9426968640",
    "password": "112518",
    "phy": "53",
    "che": "65",
    "gk": "68",
    "math": "65",
    "total": "251",
    "rank": "4877",
    "dob": "04/21/2006",
    "addressLine1": "Iscon Flower C12/103 Ghuma Ahmedabad",
    "addressLine2": "Iscon Flower C12/103 Ghuma Ahmedabad",
    "city": "Ahmedabad",
    "state": "Gujurat",
    "pincode": 380058,
    "gender": "Male",
    "regdDate": "13-5-2023",
    "fatherName": "Debasis Pradhan",
    "motherName": "Sasmita Pradhan",
    "photo": "https://ucarecdn.com/4b0aa65a-1c45-43b1-aaa1-261fda3af7ea/-/crop/1373x1374/0,66/-/preview/",
    "signature": "https://ucarecdn.com/187057cf-dce7-4e4b-886d-a8e90367ff48/-/crop/1368x548/76,0/-/preview/",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "phase": "4",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "GUJARAT"
    }
    ],
    "date": "2023-06-13T06:38:56.639Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=13520237000&tracking_id=112909332602&bank_ref_no=316475732256&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Success-NA-0&currency=INR&amount=5300.00&billing_name=Dennis Pradhan&billing_address=Gujurat&billing_city=Gujurat&billing_state=Gujurat&billing_zip=XXXXXX&billing_country=India&billing_tel=9426968640&billing_email=swayam210406@gmail.com&delivery_name=Dennis Pradhan&delivery_address=Gujurat&delivery_city=Gujurat&delivery_state=Gujurat&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9426968640&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=13/06/2023 12:08:54&bin_country=",
    "_id": "64880f00b744d4edb7e7f580"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb005",
    "collegeName": "AHMEDABAD INSTITUTE OF TECHNOLOGY, AHMEDABAD",
    "collegeCode": "GU00032",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9a",
    "collegeName": "Ahmedabad University,Ahmedabad ",
    "collegeCode": "GU00001",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb01f",
    "collegeName": "L. J. INSTITUTE OF ENGINEERING & TECHNOLOGY, AHMEDABAD",
    "collegeCode": "GU00058",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb02b",
    "collegeName": "NEW L J INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "GU00071",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb014",
    "collegeName": "GANDHINAGAR INSTITUTE OF TECHNOLOGY, GANDHINAGAR",
    "collegeCode": "GU00047",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb04a",
    "collegeName": "VADODARA INSTITUTE OF ENGINEERING, VADODARA",
    "collegeCode": "GU00107",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea2",
    "collegeName": " INDUS University,Ahmedabad",
    "collegeCode": "GU00012",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea2",
    "collegeName": " INDUS University,Ahmedabad",
    "collegeCode": "GU00012",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea2",
    "collegeName": " INDUS University,Ahmedabad",
    "collegeCode": "GU00012",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbb01a",
    "collegeName": "INSTITUTE OF TECHNOLOGY AND MANAGEMENT UNIVERSE TECHNICAL CAMPUS, VADODARA",
    "collegeCode": "GU00053",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaf",
    "collegeName": "Silver Oak University",
    "collegeCode": "GU00028",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb045",
    "collegeName": "SILVER OAK COLLEGE OF ENGINEERING AND TECHNOLOGY WITHIN AHMEDABAD MUNICIPAL CORPORATION LIMIT, AHMEDABAD",
    "collegeCode": "GU00102",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaf",
    "collegeName": "Silver Oak University",
    "collegeCode": "GU00028",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeaf",
    "collegeName": "Silver Oak University",
    "collegeCode": "GU00028",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    }
    ],
    "loginEnabled": false
    },
    {
    "_id": "64a7a0ec6816cf682bd83865",
    "applicationNo": "14620232130",
    "studentName": "ALOK KUMAR",
    "emailId": "alok280503@gmail.com",
    "password": "alok280503",
    "phy": "68",
    "che": "72",
    "gk": "50",
    "math": "50",
    "markObtained": "240",
    "rank": "2968",
    "mobileNo": "7004524782",
    "dob": "12/14/2004",
    "addressLine1": "Mohnalya apartment A3,Chiragora Dhanbad",
    "addressLine2": "Assusmsaan Road , near B.D.O. office Hirapur Dhanbad",
    "city": "Dhanbad",
    "state": "Jharkhand",
    "pincode": "826001",
    "gender": "Male",
    "regDate": "14-6-2023",
    "fatherName": "Niranjan Thakur",
    "motherName": "Kanak Lata Devi",
    "photo": "https://ucarecdn.com/206fc07b-3a3f-4133-936b-d025798a6c97/-/crop/200x200/0,14/-/preview/",
    "signature": "https://ucarecdn.com/aafec87c-996a-4c5e-9570-5d65dc07aa50/-/crop/231x92/12,94/-/preview/",
    "branchPref1": "Computer Science Engg with sepecialization (like AI, Data Science, ML or any other)",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": " GIET University,Gunupur",
    "collegeCode": "OD00004",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "ODISHA"
    },
    {
    "collegeName": " GIET University,Gunupur",
    "collegeCode": "OD00004",
    "branch": "Computer Science Engineering with AI Specialization",
    "programme": "Engineering",
    "state": "ODISHA"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae96",
    "collegeName": "O P Jindal University, Raigarh",
    "collegeCode": "CH00006",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae96",
    "collegeName": "O P Jindal University, Raigarh",
    "collegeCode": "CH00006",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae96",
    "collegeName": "O P Jindal University, Raigarh",
    "collegeCode": "CH00006",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf1a",
    "collegeName": " GIET University,Gunupur",
    "collegeCode": "OD00004",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf19",
    "collegeName": "C.V.Raman Global University",
    "collegeCode": "OD00003",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed1",
    "collegeName": "Sanskriti University, Mathura",
    "collegeCode": "UP00015",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaecd",
    "collegeName": " Invertis University, Bareilly",
    "collegeCode": "UP00011",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaecd",
    "collegeName": " Invertis University, Bareilly",
    "collegeCode": "UP00011",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaed1",
    "collegeName": "Sanskriti University, Mathura",
    "collegeCode": "UP00015",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaed9",
    "collegeName": " The Glocal University,Saharanpur",
    "collegeCode": "UP00023",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaecd",
    "collegeName": " Invertis University, Bareilly",
    "collegeCode": "UP00011",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb26c",
    "collegeName": "KCC INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    "collegeCode": "UP00065",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec5",
    "collegeName": "Bennett University, Greater Noida",
    "collegeCode": "UP00002",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed9",
    "collegeName": " The Glocal University,Saharanpur",
    "collegeCode": "UP00023",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec7",
    "collegeName": "IILM University, Greater Noida",
    "collegeCode": "UP00004",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef2",
    "collegeName": "Alliance University, Bangalore",
    "collegeCode": "KR00002",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0ff",
    "collegeName": "ST THOMAS INSTITUTE FOR SCIENCE AND TECHNOLOGY",
    "collegeCode": "KR00043",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c5",
    "collegeName": "S.D.M. COLLEGE OF ENGINEERING & TECHNOLOGY",
    "collegeCode": "KR00096",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef7",
    "collegeName": "Presidency University,Bangalore",
    "collegeCode": "KR00012",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0c6",
    "collegeName": "SAHYADRI COLLEGE OF ENGINEERING & MANAGEMENT",
    "collegeCode": "KR00097",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0ad",
    "collegeName": "JYOTHY INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00061",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0d0",
    "collegeName": "SRI SIDDHARTHA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00109",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0f7",
    "collegeName": "SARABHAI INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "KR00033",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef8",
    "collegeName": "Rai Technology University, Bangalore",
    "collegeCode": "KR00013",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb099",
    "collegeName": "BANGALORE TECHNOLOGICAL INSTITUTE",
    "collegeCode": "KR00030",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea6",
    "collegeName": "Karnavati University",
    "collegeCode": "GU00016",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea6",
    "collegeName": "Karnavati University",
    "collegeCode": "GU00016",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaeaa",
    "collegeName": "PARUL University,Vadodara",
    "collegeCode": "GU00020",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea7",
    "collegeName": "Marwadi University",
    "collegeCode": "GU00017",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea6",
    "collegeName": "Karnavati University",
    "collegeCode": "GU00016",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2b",
    "collegeName": " ICFAI University, Jaipur",
    "collegeCode": "RJ00010",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf33",
    "collegeName": "Mody University of Science and Technology, Sikar",
    "collegeCode": "RJ00019",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf32",
    "collegeName": " Mewar University",
    "collegeCode": "RJ00018",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf42",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf32",
    "collegeName": " Mewar University",
    "collegeCode": "RJ00018",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf2b",
    "collegeName": " ICFAI University, Jaipur",
    "collegeCode": "RJ00010",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf33",
    "collegeName": "Mody University of Science and Technology, Sikar",
    "collegeCode": "RJ00019",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf32",
    "collegeName": " Mewar University",
    "collegeCode": "RJ00018",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2d",
    "collegeName": "Jaipur National University",
    "collegeCode": "RJ00012",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f3",
    "collegeName": "JAIPUR INSTITUTE OF TECHNOLOGY- GROUP OF INSTITUTIONS, JAIPUR",
    "collegeCode": "RJ00054",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2b",
    "collegeName": " ICFAI University, Jaipur",
    "collegeCode": "RJ00010",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f0",
    "collegeName": "JAIPUR ENGINEERING COLLEGE",
    "collegeCode": "RJ00051",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf33",
    "collegeName": "Mody University of Science and Technology, Sikar",
    "collegeCode": "RJ00019",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f1",
    "collegeName": "JAIPUR ENGINEERING COLLEGE AND RESEARCH CENTRE, JAIPUR",
    "collegeCode": "RJ00052",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f2",
    "collegeName": "JAIPUR INSTITUTE OF ENGINEERING AND MANAGEMENT, JAIPUR",
    "collegeCode": "RJ00053",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf55",
    "collegeName": "Graphic Era,Dehradun",
    "collegeCode": "UT00003",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf54",
    "collegeName": "DIT University",
    "collegeCode": "UT00002",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf5a",
    "collegeName": "Quantum University, Roorkee",
    "collegeCode": "UT00010",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf55",
    "collegeName": "Graphic Era,Dehradun",
    "collegeCode": "UT00003",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf59",
    "collegeName": "Uttaranchal University",
    "collegeCode": "UT00009",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf58",
    "collegeName": "University of Petroleum and Energy Studies, ",
    "collegeCode": "UT00008",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb292",
    "collegeName": "MODERN INSTITUTE OF TECHNOLOGY",
    "collegeCode": "UT00025",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb295",
    "collegeName": "ROORKEE COLLEGE OF ENGINEERING",
    "collegeCode": "UT00028",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28c",
    "collegeName": "COLLEGE OF ENGINEERING ROORKEE",
    "collegeCode": "UT00017",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017441560a04ddc6dbb28e",
    "collegeName": "DOON INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "UT00021",
    "programme": "Engineering",
    "state": "UTTRAKHAND",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf00",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MP00004",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf01",
    "collegeName": " Dr. A.P.J. Abdul KalamUniversity,Indore",
    "collegeCode": "MP00005",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf0c",
    "collegeName": "Madhyanchal Professional University,Bhopal",
    "collegeCode": "MP00018",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf00",
    "collegeName": " Bhabha University,Bhopal",
    "collegeCode": "MP00004",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf01",
    "collegeName": " Dr. A.P.J. Abdul KalamUniversity,Indore",
    "collegeCode": "MP00005",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaf11",
    "collegeName": " Medi-caps university, indore",
    "collegeCode": "MP00023",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0c",
    "collegeName": "Madhyanchal Professional University,Bhopal",
    "collegeCode": "MP00018",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb11c",
    "collegeName": "ITM UNIVERSITY - GWALIOR (SCHOOL OF ENGINEERING & TECHNOLOGY)",
    "collegeCode": "MP00036",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf01",
    "collegeName": " Dr. A.P.J. Abdul KalamUniversity,Indore",
    "collegeCode": "MP00005",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf0a",
    "collegeName": "Rabindranath Tagore University (Formerly AISECTUniversity),Bhopal",
    "collegeCode": "MP00015",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb133",
    "collegeName": "SAGAR INSTITUTE OF RESEARCH & TECHNOLOGY, INDORE",
    "collegeCode": "MP00063",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb117",
    "collegeName": "GWALIOR INSTITUTE OF INFORMATION TECHNOLOGY",
    "collegeCode": "MP00031",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf04",
    "collegeName": " ITM University,Gwalior",
    "collegeCode": "MP00009",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb129",
    "collegeName": "ORIENTAL COLLEGE OF TECHNOLOGY",
    "collegeCode": "MP00053",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb124",
    "collegeName": "MEDI CAPS UNIVERSITY INDORE",
    "collegeCode": "MP00047",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf05",
    "collegeName": " LNCT University,Bhopal",
    "collegeCode": "MP00010",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf06",
    "collegeName": "Oriental University, Indore",
    "collegeCode": "MP00011",
    "programme": "Engineering",
    "state": "MADHYA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf15",
    "collegeName": " Sandip University,Nashik",
    "collegeCode": "MH00004",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbb15b",
    "collegeName": "MET'S INSTITUTE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00037",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb148",
    "collegeName": "D Y PATIL EDUCATION SOCIETY'S D Y PAIL TECHNICAL CAMPUS FACULTY OF ENGINEERING AND FACULTY OF MANAGEMENT TALSANDE KOLHAPUR",
    "collegeCode": "MH00016",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefc",
    "collegeName": "Srinivas University, Mangalore",
    "collegeCode": "KR00017",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef6",
    "collegeName": "kle technological university ,Hubballi",
    "collegeCode": "KR00009",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef8",
    "collegeName": "Rai Technology University, Bangalore",
    "collegeCode": "KR00013",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaefb",
    "collegeName": "Sri Siddhartha Academy of Higher Education, Tumkur",
    "collegeCode": "KR00016",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef5",
    "collegeName": "Jain University, Bangalore",
    "collegeCode": "KR00007",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef1",
    "collegeName": "Adichunchanagiriuniversity,Mandya",
    "collegeCode": "KR00001",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaef9",
    "collegeName": "Ramaiah University of Applied Sciences",
    "collegeCode": "KR00014",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaefc",
    "collegeName": "Srinivas University, Mangalore",
    "collegeCode": "KR00017",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaef8",
    "collegeName": "Rai Technology University, Bangalore",
    "collegeCode": "KR00013",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering with Data science "
    },
    {
    "id": "64017440560a04ddc6dbaea4",
    "collegeName": "ITM (SLS) Baroda University",
    "collegeCode": "GU00014",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea8",
    "collegeName": "Monark University",
    "collegeCode": "GU00018",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea1",
    "collegeName": " GSFC University,Vadodara",
    "collegeCode": "GU00011",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea2",
    "collegeName": " INDUS University,Ahmedabad",
    "collegeCode": "GU00012",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9c",
    "collegeName": "Bhagwan Mahavir University",
    "collegeCode": "GU00004",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaea0",
    "collegeName": "Gokul Global University,Patan",
    "collegeCode": "GU00010",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae9d",
    "collegeName": " Charotar University of Science and Technology(CHARUSAT),Anand",
    "collegeCode": "GU00005",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaead",
    "collegeName": "Rai University,Ahmedabad",
    "collegeCode": "GU00024",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeac",
    "collegeName": " R.K. University",
    "collegeCode": "GU00023",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee4",
    "collegeName": " Maharishi Markandeshwar University",
    "collegeCode": "HP00011",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaede",
    "collegeName": "Career Point University, Hamirpur",
    "collegeCode": "HP00005",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee0",
    "collegeName": "Eternal University,Sirmour",
    "collegeCode": "HP00007",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee6",
    "collegeName": "Sri Sai University, Palampur",
    "collegeCode": "HP00013",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee2",
    "collegeName": " Indus international university, Una",
    "collegeCode": "HP00009",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee7",
    "collegeName": " Shoolini University of Biotechnology and Management Sciences",
    "collegeCode": "HP00014",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeda",
    "collegeName": " Arni University , Kangra",
    "collegeCode": "HP00001",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeef",
    "collegeName": " Sarla Birla University",
    "collegeCode": "JH00010",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeee",
    "collegeName": "Sai Nath University,Ranchi",
    "collegeCode": "JH00009",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaee9",
    "collegeName": " ARKA Jain University",
    "collegeCode": "JH00003",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeed",
    "collegeName": "Ram Krishana Dharmarth Foundation University",
    "collegeCode": "JH00008",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeeb",
    "collegeName": "Jharkhand Rai University",
    "collegeCode": "JH00006",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeec",
    "collegeName": "Radha Govind University",
    "collegeCode": "JH00007",
    "programme": "Engineering",
    "state": "JHARKHAND",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed4",
    "collegeName": "Shobhit University",
    "collegeCode": "UP00018",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaecf",
    "collegeName": "Noida International University, Gautam Budh Nagar",
    "collegeCode": "UP00013",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed0",
    "collegeName": "Rama University, Kanpur,Delhi Ncr",
    "collegeCode": "UP00014",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed6",
    "collegeName": "Swami Vivekanand Subharti University, Meerut",
    "collegeCode": "UP00020",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeca",
    "collegeName": "IIMT University, Meerut",
    "collegeCode": "UP00008",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaec8",
    "collegeName": "G.L.A. University,Mathura",
    "collegeCode": "UP00005",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaed9",
    "collegeName": " The Glocal University,Saharanpur",
    "collegeCode": "UP00023",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaeb3",
    "collegeName": "Amity University ,Gurugram",
    "collegeCode": "HR00002",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaebe",
    "collegeName": " NIILM University,Kaithal",
    "collegeCode": "HR00014",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf46",
    "collegeName": "Hindustan Institute of Technology and Science",
    "collegeCode": "TM00005",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf48",
    "collegeName": "Karunya Institute of Technology and Sciences,Coimbatore",
    "collegeCode": "TM00007",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf3c",
    "collegeName": " Shridhar University",
    "collegeCode": "RJ00028",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf38",
    "collegeName": " Pratap University",
    "collegeCode": "RJ00024",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf41",
    "collegeName": "University of Engineering & Management, Jaipur,",
    "collegeCode": "RJ00033",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf40",
    "collegeName": "Suresh Gyan Vihar University, Jaipur",
    "collegeCode": "RJ00032",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2e",
    "collegeName": "J.K. Lakshmipat University, Jaipur",
    "collegeCode": "RJ00013",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbaf2c",
    "collegeName": " Jagan Nath University, Jaipur",
    "collegeCode": "RJ00011",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae87",
    "collegeName": "GITAM university,Visakhapatnam",
    "collegeCode": "AP00003",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    },
    {
    "id": "64017440560a04ddc6dbae89",
    "collegeName": "Vignan's Foundation for,Science, Technology & Research ,Guntur",
    "collegeCode": "AP00006",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering with AI Specialization"
    }
    ],
    "choiceFillingSpotRound": [],
    "date": "2023-07-09T04:05:37.152Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=14620232130&tracking_id=112935039866&bank_ref_no=319070337311&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=ALOK KUMAR&billing_address=Jharkhand&billing_city=Jharkhand&billing_state=Jharkhand&billing_zip=XXXXXX&billing_country=India&billing_tel=7004524782&billing_email=alok280503@gmail.com&delivery_name=ALOK KUMAR&delivery_address=Jharkhand&delivery_city=Jharkhand&delivery_state=Jharkhand&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7004524782&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=09/07/2023 09:35:18&bin_country=",
    "_id": "64aa3211c0eaa70fe1323632"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "action": "Exit",
    "loginEnabled": false
    },
    {
    "_id": "64a7a0ec6816cf682bd83868",
    "applicationNo": "27620239264",
    "studentName": "Prathmesh Desai",
    "emailId": "prathmeshpdesai@gmail.com",
    "password": "804654",
    "phy": "23",
    "che": "32",
    "gk": "9",
    "math": "31",
    "markObtained": "95",
    "rank": "6285",
    "mobileNo": "9923464748",
    "dob": "8/19/2004",
    "addressLine1": "Jain Galli Near Jain Basti",
    "addressLine2": "At Minche Taluka Hatknangle Dist Kolhapur",
    "city": "Kolhapur",
    "state": "Maharashtra",
    "pincode": "416109",
    "gender": "Male",
    "regDate": "27-6-2023",
    "fatherName": "Prakash Annaso Desai",
    "motherName": "Padamashri Prakash Desai",
    "photo": "https://ucarecdn.com/0e19334c-ea81-4ae4-b0eb-fad38299b2d5/-/crop/746x745/514,620/-/preview/",
    "signature": "https://ucarecdn.com/3868deda-ceed-4a73-bc86-0d8a231edd99/-/crop/301x121/11,667/-/preview/",
    "branchPref1": "Computer Science Engg",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 2,
    "allotedCollege": [
    {
    "collegeName": "GANDHI INSTITUTE OF TECHNOLOGY AND MANAGEMENT GITAM VISAKHAPATNAM",
    "collegeCode": "AP00068",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb14e",
    "collegeName": "G.H.RAISONI COLLEGE OF ENGINEERING AND MANAGEMENT, AHMEDNAGAR",
    "collegeCode": "MH00022",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14d",
    "collegeName": "G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY, PUNE",
    "collegeCode": "MH00021",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafd6",
    "collegeName": "SREE VIDYANIKETHAN ENGINEERING COLLEGE",
    "collegeCode": "AP00148",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf92",
    "collegeName": "GANDHI INSTITUTE OF TECHNOLOGY AND MANAGEMENT GITAM VISAKHAPATNAM",
    "collegeCode": "AP00068",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafef",
    "collegeName": "VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE",
    "collegeCode": "AP00176",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbafe0",
    "collegeName": "SRI VENKATESWARA INSTITUTE OF SCIENCE AND TECHNOLOGY",
    "collegeCode": "AP00159",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb228",
    "collegeName": "CVR COLLEGE OF ENGINEERING",
    "collegeCode": "TL00022",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4f",
    "collegeName": "Mahindra University",
    "collegeCode": "TL00005",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf4b",
    "collegeName": "Anurag University,",
    "collegeCode": "TL00001",
    "programme": "Engineering",
    "state": "TELANGANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf46",
    "collegeName": "Hindustan Institute of Technology and Science",
    "collegeCode": "TM00005",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf48",
    "collegeName": "Karunya Institute of Technology and Sciences,Coimbatore",
    "collegeCode": "TM00007",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb21b",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEEIRNG & TECHNOLOGY",
    "collegeCode": "TM00018",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb21c",
    "collegeName": "SRI VENKATESWARA COLLEGE OF ENGINEERING",
    "collegeCode": "TM00019",
    "programme": "Engineering",
    "state": "TAMIL NADU",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb09e",
    "collegeName": "CMR INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00035",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bb",
    "collegeName": "NEW HORIZON COLLEGE OF ENGINEERING ",
    "collegeCode": "KR00082",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bc",
    "collegeName": "NITTE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00083",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14f",
    "collegeName": "G. M. VEDAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "MH00023",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb164",
    "collegeName": "SAHYADRI VALLEY COLLEGE OF ENGINEERING & TECHNOLOGY,PUNE",
    "collegeCode": "MH00050",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb153",
    "collegeName": "GURU NANAK INSTITUTE OF ENGNEERING & TECHNOLOGY",
    "collegeCode": "MH00028",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb166",
    "collegeName": "SANGHAVI COLLEGE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00052",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf18",
    "collegeName": "MIT ADT ",
    "collegeCode": "MH00007",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14c",
    "collegeName": "G.H. RAISONI COLLEGE OF ENGINEERING AND MANAGEMENT, AMRAVATI",
    "collegeCode": "MH00020",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1e8",
    "collegeName": "B.K. BIRLA INSTITUTE OF ENGINEERING AND TECHNOLOGY, JHUNJHUNU",
    "collegeCode": "RJ00043",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1fc",
    "collegeName": "MAHAVEER INSTITUTE OF TECHNOLOGY & SCIENCE, PALI",
    "collegeCode": "RJ00064",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf42",
    "collegeName": " Vivekananda Global University, Jaipur",
    "collegeCode": "RJ00034",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf32",
    "collegeName": " Mewar University",
    "collegeCode": "RJ00018",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf34",
    "collegeName": " NIMS university jaipur",
    "collegeCode": "RJ00020",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf35",
    "collegeName": " NIIT university, Neemrana",
    "collegeCode": "RJ00021",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound": [],
    "date": "2023-07-09T16:45:27.779Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=27620239264&tracking_id=112935658318&bank_ref_no=132389&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=CAPTURED&currency=INR&amount=5300.00&billing_name=Prathmesh Desai&billing_address=Maharashtra&billing_city=Maharashtra&billing_state=Maharashtra&billing_zip=XXXXXX&billing_country=India&billing_tel=9923464748&billing_email=prathmeshpdesai@gmail.com&delivery_name=Prathmesh Desai&delivery_address=Maharashtra&delivery_city=Maharashtra&delivery_state=Maharashtra&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9923464748&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=null&retry=N&response_code=0&billing_notes=&trans_date=09/07/2023 22:15:27&bin_country=INDIA",
    "_id": "64aae427c0eaa70fe1323872"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "loginEnabled": false
    },
    {
    "_id": "64a7a0ec6816cf682bd8386a",
    "applicationNo": "30620231796",
    "studentName": "Parth Bhatnagar",
    "emailId": "ptg112233@gmail.com",
    "password": "13154",
    "phy": "67",
    "che": "65",
    "gk": "71",
    "math": "62",
    "markObtained": "265",
    "rank": "2464",
    "mobileNo": "9625560335",
    "dob": "8/26/2004",
    "addressLine1": "C-3",
    "addressLine2": "vasant kunj",
    "city": "new delhi",
    "state": "Delhi",
    "pincode": "110070",
    "gender": "Male",
    "regDate": "30-6-2023",
    "fatherName": "Gopal Bhatnagar",
    "motherName": "Uma Bhatnagar",
    "photo": "https://ucarecdn.com/f21121f4-ea77-46c1-9c8c-f7b46a9bb9ae/-/crop/1470x1470/0,245/-/preview/",
    "signature": "https://ucarecdn.com/8897a5bd-6eaf-4178-a0ed-f64b6d80325f/-/crop/1371x548/0,241/-/preview/",
    "branchPref1": "Computer Science Engg",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 3,
    "allotedCollege": [
    {
    "collegeName": "Manav RachnaUniversity,Faridabad",
    "collegeCode": "HR00012",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "HARYANA"
    },
    {
    "collegeName": "Manav RachnaUniversity,Faridabad",
    "collegeCode": "HR00012",
    "branch": "Computer Science Engineering",
    "programme": "Engineering",
    "state": "HARYANA"
    }
    ],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbaed3",
    "collegeName": " Shiv Nadar University, Gautham Budh Nagar",
    "collegeCode": "UP00017",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaebc",
    "collegeName": "Manav RachnaUniversity,Faridabad",
    "collegeCode": "HR00012",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb183",
    "collegeName": "KALINGA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "CH00022",
    "programme": "Engineering",
    "state": "CHATTISGARH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf17",
    "collegeName": "MIT WPU",
    "collegeCode": "MH00006",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec9",
    "collegeName": "Galgotias University",
    "collegeCode": "UP00007",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaea9",
    "collegeName": " Nirma University",
    "collegeCode": "GU00019",
    "programme": "Engineering",
    "state": "GUJARAT",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaed2",
    "collegeName": "Sharda University, Greater Noida",
    "collegeCode": "UP00016",
    "programme": "Engineering",
    "state": "UTTAR PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaeb6",
    "collegeName": "BML Munjal University, Gurugram",
    "collegeCode": "HR00005",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f0",
    "collegeName": "JAIPUR ENGINEERING COLLEGE",
    "collegeCode": "RJ00051",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaedf",
    "collegeName": " Chitkara University,Solan",
    "collegeCode": "HP00006",
    "programme": "Engineering",
    "state": "HIMANCHAL PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbae88",
    "collegeName": " K L University ,Guntur",
    "collegeCode": "AP00005",
    "programme": "Engineering",
    "state": "ANDHRA PRADESH",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf35",
    "collegeName": " NIIT university, Neemrana",
    "collegeCode": "RJ00021",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf2f",
    "collegeName": "J.E.C.R.C. University, Jaipur,",
    "collegeCode": "RJ00014",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1f1",
    "collegeName": "JAIPUR ENGINEERING COLLEGE AND RESEARCH CENTRE, JAIPUR",
    "collegeCode": "RJ00052",
    "programme": "Engineering",
    "state": "RAJASTHAN",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0bd",
    "collegeName": "NMAM INSTITUTE OF TECHNOLOGY, NITTE",
    "collegeCode": "KR00085",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb098",
    "collegeName": "BANGALORE INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00029",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef4",
    "collegeName": "Dayanand SagarUniversity,Bangalore",
    "collegeCode": "KR00005",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0d2",
    "collegeName": "SRI VENKATESHWARA COLLEGE OF ENGINEERING",
    "collegeCode": "KR00111",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaefa",
    "collegeName": "Reva University, Bangalore",
    "collegeCode": "KR00015",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaec3",
    "collegeName": "The Northcap University,Gurugram",
    "collegeCode": "HR00021",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaef3",
    "collegeName": "CMR University,Bangalore",
    "collegeCode": "KR00004",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb0cd",
    "collegeName": "SJB INSTITUTE OF TECHNOLOGY",
    "collegeCode": "KR00105",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb096",
    "collegeName": "ATRIA INSTITUTE OF TECHNOLOGY ",
    "collegeCode": "KR00027",
    "programme": "Engineering",
    "state": "KARNATAKA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb066",
    "collegeName": "HINDU COLLEGE OF ENGINEERING",
    "collegeCode": "HR00050",
    "programme": "Engineering",
    "state": "HARYANA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb1a6",
    "collegeName": "NALANDA INSTITUTE OF TECHNOLOGY",
    "collegeCode": "OD00032",
    "programme": "Engineering",
    "state": "ODISHA",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound": [],
    "date": "2023-07-09T13:00:43.266Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=30620231796&tracking_id=112935480591&bank_ref_no=319038547782&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Parth Bhatnagar&billing_address=Delhi&billing_city=Delhi&billing_state=Delhi&billing_zip=XXXXXX&billing_country=India&billing_tel=9625560335&billing_email=ptg112233@gmail.com&delivery_name=Parth Bhatnagar&delivery_address=Delhi&delivery_city=Delhi&delivery_state=Delhi&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=9625560335&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=09/07/2023 18:30:24&bin_country=",
    "_id": "64aaaf7bc0eaa70fe1323794"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "loginEnabled": false
    },
    {
    "_id": "64a7a0ec6816cf682bd83862",
    "applicationNo": "7620239346",
    "studentName": "Mansi pandit",
    "emailId": "panditmansi027@gmail.com",
    "password": "240444",
    "phy": "78",
    "che": "77",
    "gk": "36",
    "math": "0",
    "markObtained": "191",
    "rank": "4269",
    "mobileNo": "7558772720",
    "dob": "2/27/2004",
    "addressLine1": "Flat No 201 Shree Avenue",
    "addressLine2": "Abhinav College Road Narhe Pune",
    "city": "Pune",
    "state": "Maharashtra",
    "pincode": "411041",
    "gender": "Female",
    "regDate": "7-6-2023",
    "fatherName": "Raghunath gangadhar pandit",
    "motherName": "Kamal pandit",
    "photo": "https://ucarecdn.com/07966321-51e0-4c10-ab25-c8490d3192ba/-/crop/310x310/0,8/-/preview/",
    "signature": "https://ucarecdn.com/305c89d6-66b0-4e1c-8c7f-153701ed1771/-/crop/533x213/0,20/-/preview/",
    "branchPref1": "Computer Science Engg",
    "progOpted": "engg",
    "loe": "PCM (12th Level)",
    "moe": "HBE",
    "__v": 3,
    "allotedCollege": [],
    "choiceFillingRound1": [
    {
    "id": "64017440560a04ddc6dbb177",
    "collegeName": "ZEAL COLLEGE OF ENGINEERING AND RESEARCH ,PUNE",
    "collegeCode": "MH00071",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb157",
    "collegeName": "JSPM NARHE TECHNICAL CAMPUS, PUNE",
    "collegeCode": "MH00032",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb158",
    "collegeName": "K J COLLEGE OF ENGINEERING & MANAGEMENT RESEARCH",
    "collegeCode": "MH00033",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16f",
    "collegeName": "TRINITY COLLEGE OF ENGINEERING & RESEARCH",
    "collegeCode": "MH00061",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14b",
    "collegeName": "DHOLE PATIL EDUCATION SOCIETY'S, DHOLE PATIL COLLEGE OF ENGINEERING. PUNE",
    "collegeCode": "MH00019",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb151",
    "collegeName": "GENBA SOPANRAO MOZE TRUST'S PARVATIBAI GENBA MOZE COLLEGE OF ENGINEERING,WAGHOLI, PUNE",
    "collegeCode": "MH00025",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb159",
    "collegeName": "KEYSTONE SCHOOL OF ENGINEERING, PUNE",
    "collegeCode": "MH00034",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb15f",
    "collegeName": "P K TECHNICAL CAMPUS,PUNE",
    "collegeCode": "MH00043",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb164",
    "collegeName": "SAHYADRI VALLEY COLLEGE OF ENGINEERING & TECHNOLOGY,PUNE",
    "collegeCode": "MH00050",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14d",
    "collegeName": "G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY, PUNE",
    "collegeCode": "MH00021",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbaf13",
    "collegeName": "Ajeenkya D.Y. PatilUniversity,Pune",
    "collegeCode": "MH00001",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb143",
    "collegeName": "ALARD COLLEGE OF ENGINEERING & MANAGEMENT ,PUNE",
    "collegeCode": "MH00010",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb156",
    "collegeName": "JAIHIND COLLEGE OF ENGINEERING PUNE",
    "collegeCode": "MH00031",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb149",
    "collegeName": "DATTAKALA GROUP OF INSTITUTIONS, PUNE",
    "collegeCode": "MH00017",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb15a",
    "collegeName": "MATOSHRI COLLEGE OF ENGINEERING & RESEARCH CENTRE, NASHIK",
    "collegeCode": "MH00036",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb162",
    "collegeName": "RAJIV GANDHI COLLEGE OF ENGINEERING,AHMEDNAGAR",
    "collegeCode": "MH00048",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb15b",
    "collegeName": "MET'S INSTITUTE OF ENGINEERING,NASHIK",
    "collegeCode": "MH00037",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb141",
    "collegeName": "ASHOK INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    "collegeCode": "MH00008",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb14f",
    "collegeName": "G. M. VEDAK INSTITUTE OF TECHNOLOGY",
    "collegeCode": "MH00023",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb148",
    "collegeName": "D Y PATIL EDUCATION SOCIETY'S D Y PAIL TECHNICAL CAMPUS FACULTY OF ENGINEERING AND FACULTY OF MANAGEMENT TALSANDE KOLHAPUR",
    "collegeCode": "MH00016",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb176",
    "collegeName": "YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING",
    "collegeCode": "MH00070",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb168",
    "collegeName": "SHATABDI INSTITUTE OF ENGINEERING & RESEARCH,NASHIK",
    "collegeCode": "MH00054",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb146",
    "collegeName": "BRAHMA VALLEY COLLEGE OF ENGINEERING AND REASERACH INSTITUTE",
    "collegeCode": "MH00014",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb169",
    "collegeName": "SHRI TULJABHAVANI COLLEGE OF ENGINEERING,OSMANABAD",
    "collegeCode": "MH00055",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    },
    {
    "id": "64017440560a04ddc6dbb16e",
    "collegeName": "ST. VINCENT PALLOTTI COLLEGE OF ENGG. & TECHNOLOGY, NAGPUR",
    "collegeCode": "MH00060",
    "programme": "Engineering",
    "state": "MAHARASHTRA",
    "branch": "Computer Science Engineering"
    }
    ],
    "choiceFillingSpotRound": [],
    "date": "2023-07-08T13:56:03.062Z",
    "paymentResponse": [
    {
    "paymentStatus": "order_id=7620239346&tracking_id=112934719387&bank_ref_no=318948787419&order_status=Success&failure_message=&payment_mode=Unified Payments&card_name=UPI&status_code=&status_message=Transaction Successful-NA-0&currency=INR&amount=5300.00&billing_name=Mansi pandit&billing_address=Maharashtra&billing_city=Maharashtra&billing_state=Maharashtra&billing_zip=XXXXXX&billing_country=India&billing_tel=7558772720&billing_email=panditmansi027@gmail.com&delivery_name=Mansi pandit&delivery_address=Maharashtra&delivery_city=Maharashtra&delivery_state=Maharashtra&delivery_zip=XXXXXX&delivery_country=India&delivery_tel=7558772720&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=5300.00&eci_value=&retry=N&response_code=&billing_notes=&trans_date=08/07/2023 19:25:58&bin_country=",
    "_id": "64a96af3c0eaa70fe1323416"
    }
    ],
    "paymentResponseAcceptanceFee": [],
    "counsellingFeeStatus": true,
    "choiceFillingRound1Submit": true,
    "loginEnabled": false
    }
    ]

    export default Data
    
import React, { useState, useEffect, useRef } from 'react'
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
import { useReactToPrint } from "react-to-print";
import axios from 'axios'
function PaymentReceipt() {
    const [studentData, setStudentData] = useState({})
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [status, setStatus] = useState('')
    console.log(status)


    const getData = () => {
        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success
                // console.log(response)
                setStudentData(response.data.user)
                if (response.data.user.paymentResponseAcceptanceFee) {
                    console.log(response.data.user.paymentResponseAcceptanceFee[0])
                    const payment = response.data.user.paymentResponseAcceptanceFee[response.data.user.paymentResponseAcceptanceFee.length - 1].paymentStatus
                    const paymentTrim = payment.trim().split("&")
                    const transDate = paymentTrim[40].trim().split('=')
                    const paymentMode = paymentTrim[5].trim().split('=')
                    const paymentAmount = paymentTrim[10].trim().split('=')
                    const paymentTransaction = paymentTrim[1].trim().split('=')
                    const orderStatus = paymentTrim[3].trim().split('=')
                    setPaymentDate(transDate[1])
                    setPaymentMethod(paymentMode[1])
                    setAmount(paymentAmount[1])
                    setTransactionId(paymentTransaction[1])
                    setStatus(orderStatus[1])
                }
                console.log(response.data.user)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })


    }
    useEffect(() => {
        getData()
    }, [])

    //    MBA APPLICATION OPEN FUNCTIONALLITY
    const [fullscreenXlModal, setFullscreenXlModal] = useState(true);

    const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

    ///   print functionallity
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        //   pageStyle: "@page { margin-top: 0px;  } ",
        content: () => componentRef.current,
    });
    //   useEffect(() => {
    //     userAuthenticationData()
    //   }, [])

    return (
        <div className='text-center paymentPage'>

            {/* // modal start // */}
            <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog size='fullscreen-xxl-down'>
                    <MDBModalContent>
                        <MDBModalBody>
                            <div style={{ width: '700px', margin: 'auto' }} className='border border-black' ref={componentRef}>
                                <div className='m-5 border border-black'>
                                    <center><img style={{ width: '11rem', margin: 'auto', marginTop: '1rem' }} src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="" /></center>
                                    <div className='m-4'>
                                    </div>
                                    <div className='text-center text-black'>
                                        <h5 >All India Combined Entrance Examintaion</h5>
                                    </div>
                                    <div className='text-center text-black font-bold '>
                                        <h5 className='font-bold' style={{ marginTop: '1.3rem', textDecoration: 'underLine' }}>Electronic Receipt</h5>
                                    </div>
                                    <div className='text-center text-black font-bold '>
                                        <h6 className='' style={{ marginTop: '1.3rem' }}>Application No : <span className='font-bold'>{studentData.applicationNo}</span></h6>
                                    </div>
                                    <div className='text-start pl-3 text-black mt-1' style={{ fontSize: '0.9rem', paddingLeft: '1rem' }}>
                                        <p className='' style={{ marginTop: '1.8rem' }}>To  <span className='font-bold'>{studentData.studentName}</span></p>
                                        <p className='font-bold' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Seat Acceptance fee successfully Submitted. </p>
                                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Amount received towards seat Acceptance  fee of AICEE 2023. </p>
                                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Date of Receipt : <span className='font-bold'>{paymentDate}</span> </p>
                                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Payment Type : <span className='font-bold'>Online</span> </p>
                                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Application Fee : <span className='font-bold'>{amount}</span> </p>
                                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem', marginBottom: '3rem' }}>Transaction Id : <span className='font-bold'>{transactionId}</span> </p>
                                    </div>
                                </div>
                            </div>
                            <center className='pb-5 mt-2'>
                                <button onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} color='success' style={{ backgroundColor: 'gray', color: 'white', padding: '4px 12px', borderRadius: '8px' }}>Close</button>&nbsp; &nbsp;
                                <button className='ml-3' color='primary' onClick={handlePrint} style={{ backgroundColor: 'green', color: 'white', padding: '4px 12px', borderRadius: '8px' }}> Print</button>
                            </center>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            {/* // modal end // */}

        </div>
    )
}

export default PaymentReceipt
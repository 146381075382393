import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
export const AppSidebarNav = ({ items }) => {
  const Navigate = useNavigate()

  return (
    <NavbarReact>
      {/* {items && 
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))} */}
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1.1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard') }}>
        <i className="fa fa-tachometer "> </i> Home
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/rank-card') }}>
        <i className="fa-solid fa-ranking-star "> </i>   Result
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/participating-colleges') }}>
        <i className="fa-solid fa-building-columns "> </i>  Participating Colleges
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/my-payments') }}>
        <i className="fa fa-credit-card"> </i>   My Payments
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/declaration') }}>
        <i className="fa fa-credit-card"> </i>   Declaration
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/refund-rules') }}>
        <i className="fa fa-address-book "> </i>   Refund Rules
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/important-Dates') }}>
        <i className="fa fa-address-book "> </i>   Important Dates
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/instructions') }}>
        <i className="fa fa-address-book "> </i>   Instructions
      </div>
      <div className="alert " style={{ width: '95%', margin: 'auto', textAlign: 'start', fontSize: '1rem', cursor: 'pointer' }} role="alert" onClick={() => { Navigate('/dashboard/support&contacts') }}>
        <i className="fa fa-address-book "> </i>   Support & Contacts
      </div>

    </NavbarReact>
  )
}

const NavbarReact = styled.main`
div{
  color:#fdfcfc
}

*{
  font-family: Montserrat, sans-serif;
  font-size: 17px;
}
`



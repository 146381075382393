import React from 'react'
import CollegeLists from '../views/pages/collegeChoice/CollegeListss'
import Dashboard from '../components/spotDashboard/Dashboard'
function ParticipatingColleges() {
  return (
    <div>
      <Dashboard/>
      <CollegeLists/>
    </div>
  )
}

export default ParticipatingColleges
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
function SpotLogout() {
    const navigate = useNavigate()
    const logoutUser = () => {
        axios.get('/api/v1/logouts')
            .then(function (response) {
                // handle success
                navigate('/')
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                navigate('/spot/student/login')
            })

    }
    useEffect(() => {
        logoutUser()
    }, [])
    return (
        <div>
logout
        </div>
    )
}

export default SpotLogout
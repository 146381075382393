import React from 'react'
import styled from 'styled-components'
const ChoiceFillingInstruction = ({ action,onChange }) => {

    return (
        <CSS>
            <center className='counsellingCss' >
                {
                    action == 'Not Started' &&

                    <>
                        <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', marginTop: '7rem' }}>College Choice Filling Round 1  will begin from 13th July, 2024, 12:00 PM</p>
                        <center><button className='counsellingButton'
                            onClick={() => { alert('College Choice Filling Round 1  will begin from 13th July, 2024, 12:00 PM') }}

                        >College Choice Filling Round 1 (Click here)</button></center>

                    </>
                }
                {/* {
                    action == 'Started' &&

                    <>
                        <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', marginTop: '10rem', fontSize: '20px' }}>Time Over for College Choice Filling Round 1 </p>
                    </>
                } */}
                {
                    action == 'Started' &&
                    <>
                        <div>
                            <div className='proceedButton' style={{ backgroundColor: '#042e2e' }}>Important Instructions for Choice Filling</div>

                            <br></br>
                        </div>

                        <li>Always save Your College Choices Before Logging Out.</li>
                        <li>Do not Click on <strong>"Final Submit"</strong> button until you want to lock all your filled Choices.</li>
                        <li>Students won't be able to modify or change their college choice after <strong>"Final Submit"</strong>.</li>
                        <li>Do not Share Your <span style={{ color: 'red' }}>Login Id & Password</span> with anyone .</li>
                        <li> <span style={{ color: 'red' }}>Last Date to submit College Choices for Round 3 is 24th July, 2024, 11:00 PM</span> </li>

                        {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', marginTop: '1rem' }}> </p> */}
                        <center><button className='counsellingButton mt-3'
                            onClick={()=>{onChange('Choice Fill')}}
                            // onClick={() => { alert('Time Over') }}
                        >Proceed for College Choice filling Round 3 (Click here)</button></center>
                    </>
                }
            </center>

        </CSS>
    )
}


const CSS = styled.main`
.proceedButton{
  width: 80%;
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin: auto;
    text-align: center;
}
.counsellingCss{
  /* border: 2px solid black; */
  /* width: 45%;
  margin: auto; */
  margin-top: 1rem;
  text-align: justify;
  /* justify-content: start; */
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
.counsellingCss{
  /* margin-left: 11rem; */
  padding-top: 2rem;
  width: 60%;
  margin:auto
}
.counsellingCss>li{
  margin-left: 10%;
 
}
@media only screen and (max-width: 480px){
.counsellingCss{
  text-align: justify;
  border: 1px solid black;
}
li{
  text-align: justify;
  padding-left: 1rem;
  padding-right: 1rem;
}
  /* .counsellingCss>li{
  margin-left: 0rem;
} */
  .counsellingCss{
  /* border: 2px solid black; */
  /* margin: 1rem; */
  padding: 1rem;
  width: 100%;
  text-align: justify;
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
.counsellingButton{
  border: 3px solid black;
  width: 80%;
  margin: auto;
}
.counsellingCss>li{
  margin-left: 0rem;
}
}
@media only screen and (max-width: 1280px){
  
  .counsellingCss{
  /* border: 2px solid black; */
  /* margin: 1rem; */
  padding: 1rem;
  width: 100%;
  margin: auto;
  text-align: justify;
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
}

.counsellingButton{
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
.counsellingButton2{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
  margin-top: 12rem;
}
`

export default ChoiceFillingInstruction
import React from 'react'
import styled from 'styled-components'
function Footer() {
  return (
    <Footers>
      <div className='foote'>

        <div>All rights reserved @ AICEE (All India Combined Entrance Examination)</div>


        <div className='foote1'><a style={{ textDecoration: 'none', color: 'white' }} href='http://aicee.in/' target='_blank'>Back to AICEE main Page</a></div>
      </div>
    </Footers>
  )
}

const Footers = styled.main`

.foote{
  display: flex;
  justify-content: space-between;
  background-color: #e35d26;
  font-size: 12px;
  line-height: 1.8;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neu, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji ;
  padding:10px;
  color: white;
    width:100%;
  
}
.foote1{
  text-align: end;
}
.foote>div{
  width: 50%;
}

@media only screen and (max-width: 600px){
  .foote{
  display: grid;
  
}
.foote1{
  text-align: center;
}
.foote>div{
  width: 117%;
}
  .footer93{
  display: grid;
  /* justify-content: space-between; */
}
.footer9{
  display: none;
}
  .footer12{
        display: grid;
        text-align: center;
        border: 2px solid red;
        margin: auto;
    }
    .footer12>div{
      text-align: center;
      border: 2px solid red;
      margin: auto;
      
    }
    *{
      text-align: center;
    }
    
}

`

export default Footer
import React, { useState, useEffect } from 'react'
import Dashboard from '../components/spotDashboard/Dashboard'
import axios from 'axios'
import PaymentSlip from './PaymentSlip'
function MyPayments() {
    const [state, setState] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [status, setStatus] = useState('')
    const [paymentInfo, setPaymentInfo] = useState()
    console.log(status)

    const getData = () => {
        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success
                // console.log(response)
                setStudentData(response.data.user)
                if (response.data.user.counselling_payments.length >= 1) {
                    setPaymentInfo(response.data.user.counselling_payments[0])
                }


            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })


    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div >
            <Dashboard />
            <div className="card" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                <div className="card-header" style={{ fontWeight: "500", backgroundColor: '#042e2e', color: 'white' }}>
                    Your Payment Status
                </div>

                <div className="card-body">
                    <div className="alert alert-light" style={{ padding: '7px 1rem', fontSize: '0.9rem' }} role="alert">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Tracking Id</th>
                                    <th scope="col">Payment Mode</th>
                                    <th scope="col">Date of Payment</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Payment Status</th>
                                    {
                                        paymentInfo && paymentInfo.payment_status == true && <th scope="col">Download</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px' }}>
                                    {
                                        paymentInfo &&
                                        <>
                                            <th scope="row">{paymentInfo.transId}</th>
                                            <td>{paymentInfo.payMode}</td>
                                            <td>{paymentInfo.payDate}</td>
                                            <td>{paymentInfo.amount}</td>
                                            {
                                                paymentInfo.payment_status == true ?
                                                    <td style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold' }}>Success</td> :
                                                    <td style={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>Failure</td>
                                            }
                                            {
                                                paymentInfo.payment_status == true && <th onClick={() => { setState(!state) }} scope="col" style={{ textAlign: 'center', cursor: 'pointer' }}><i class="fa fa-file-pdf" style={{ color: 'red', fontSize: '1.2rem', textAlign: 'center' }}></i></th>
                                    }
                                        </>
                                    }
                                    
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                state && <PaymentSlip />
            }
        </div>
    )
}

export default MyPayments
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function AdminLogin() {
    const [email, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate()
    const loginUser = (e) => {
        e.preventDefault()
        axios.post('/api/v1/admin/login', {
            emailId: email,
            password: password
        })
            .then(function (response) {
                console.log(response);
                navigate('/admin-dashboard')
            })
            .catch(function (error) {
                console.log(error);
                alert(error.response.data.message)
            });
    }


    // loader start
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <section className=" " style={{ height: '100vh', backgroundImage: 'url(https://wallpapercave.com/wp/wp2508260.jpg)' }}>
                <div className="container py-5">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card bg-dark text-white" style={{ borderRadius: "1rem" }}>
                                {error === "This Email-Id does not exist" ? <div className="alert alert-danger" role="alert">
                                    This Email-Id does not exist
                                </div> : <></>}
                                {error === "Please Enter correct password" ? <div className="alert alert-danger mt-1" style={{ padding: '0.5rem ', width: '70%', margin: 'auto' }} role="alert">
                                    Please Enter correct password
                                </div> : <></>}
                                {error === "Admin Login Sucessfully" ? <div className="alert alert-success" role="alert">
                                    Admin Login Sucessfully
                                </div> : <></>}
                                {error === "Please fill username and password" ? <div className="alert alert-danger" role="alert">
                                    Invalid Credentials
                                </div> : <></>}
                                <div className="card-body p-5 text-center" >

                                    <div className="mb-md-0 mt-md-0 pb-0">

                                        <h2 className="fw-bold mb-0 text-uppercase">Admin Login</h2>
                                        <p className="text-white-50 mb-1">Please enter your login and password!</p>

                                        <div className="form-outline form-white mb-3">
                                            <label className="form-label" for="typeEmailX" >Email</label>
                                            <input placeholder='Username' value={email} onChange={(e) => setUserName(e.target.value)} type="email" id="typeEmailX" className="form-control form-control-lg" />
                                        </div>

                                        <div className="form-outline form-white mb-4">
                                            <label className="form-label" for="typePasswordX">Password</label>
                                            <input placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="typePasswordX" className="form-control form-control-lg" />
                                        </div>
                                        <button onClick={loginUser} className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}

                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </section>
        </>
    )
}

export default AdminLogin
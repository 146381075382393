import styled from "styled-components";

const College = styled.main`
 .clgsearch{
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
 }
 @media only screen and (max-width:575px){
    .clgsearch{
    display: grid;
    justify-content: space-between;
    margin: 25px 0px;
 }
  }
`

export default College
import React, { useState, useEffect } from 'react'
import '../css/styles.css'
import { useNavigate, Link } from 'react-router-dom'
import {
  MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBadge,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { PendingCollegeAllotment } from '../Index'
const Sidebar = () => {
      // MODAL OPERATION
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

  const navigate = useNavigate()
  // GET ALL CHOICE FILLED STUDENT
  const [choiceFilled, setChoiceFilled] = useState([])
  console.log(choiceFilled)
  const getStudent = () => {
    axios.get('/api/v1/admin/choice-filled-student')
      .then(function (response) {
        // handle success
        console.log(response.data.students);
        setChoiceFilled(response.data.students);
      })
      .catch(function (error) {
        // handle error
        console.log({ error: error });
      })
  }
  const [choiceFilledfailed, setChoiceFilledfailed] = useState([])
  console.log(choiceFilled)
  const getStudentMissing = () => {
    axios.get('/api/v1/admin/choice-filled-student-failed')
      .then(function (response) {
        // handle success
        console.log(response.data.students);
        setChoiceFilledfailed(response.data.students);
      })
      .catch(function (error) {
        // handle error
        console.log({ error: error });
      })
  }

  useEffect(() => {
    getStudent()
    getStudentMissing()
  }, [])

  const [action, setAction] = useState('')
  const handleModal = (actionData) => {
    setAction(actionData)

    setFullscreenXlModal(true)
  }
  return (
    <div>
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
              <div className="sb-sidenav-menu">
                <div className="nav" style={{ cursor: 'pointer' }}>
                  <div className="sb-sidenav-menu-heading"></div>
                  <Link className="nav-link" to='/admin-dashboard'>
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Dashboard
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/college-allotment-pending' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Pending Allotment
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/college-allotment-view' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Alloted College
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/counselling-lock-college' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Lock Colleges
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/student/missing' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Missing Student
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/spot/college-allotment-pending' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Spot College Allotment
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/spot/college-alloted' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Spot Alloted Colleges
                  </Link>
                  <Link className="nav-link" to='/admin-dashboard/spot/lock-college' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Spot Locked Colleges
                  </Link>
                 
                  <Link className="nav-link" to='/admin-dashboard/counselling-payment' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Counselling Payment
                  </Link>
                 
                  
                  {/* <Link className="nav-link" to='/admin-dashboard/student/view' >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Total Student
                  </Link> */}
                  {/* <Link to='/admin-dashboard/payment-report' className="nav-link" >
                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                    Payment Report
                  </Link> */}
                  <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                    <nav className="sb-sidenav-menu-nested nav">
                      <a className="nav-link">Static Navigation</a>
                      <a className="nav-link" >Light Sidenav</a>
                    </nav>
                  </div>
                </div>
              </div>
            </nav>
          </div>
    </div>
  )
}

export default Sidebar
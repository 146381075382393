import React, { useState, useEffect } from 'react'
import RankCard from '../pages/RankCard'
import axios from 'axios'
function Result() {
  const [data, setData] = useState({})
  const getData =  () => {
    axios.get('/api/v1/profile')
  .then(function (response) {
    // handle success
    setData(response.data.user)
    console.log(response.data.user)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div>
    
      <RankCard data={data} />
    </div>
  )
}

export default Result
import React, { useState, useEffect } from 'react';
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//For API Requests
import axios from 'axios';

const AllStudent = (props) => {
    const [data, setState] = useState([])
    console.log("all tudent", data)
    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/api/v1/admin/counselling-payment').then(res => {
        // axios.get('/api/v1/admin/get/all-students').then(res => {
            //Storing users detail in state array object
            setState({ data: res.data.students });

            // console.log("dataAPI", res.data.userList);

        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print', 'excelHtml5', 'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
    // .


    //Datatable HTML
    return (
        <div style={{ width: '100%' }}>

            <div className="flex flex-col md:flex-row" style={{ width: '100%' }}>
                {/* <Sidebar /> */}
                <section style={{ width: '100%' }}>
                    <div
                        id="main"
                        className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5"
                        style={{ width: '100%' }}
                    >

                        <div className="flex flex-wrap bg-white" style={{ width: '100%' }}>


                            <div className="flex flex-col bg-white" style={{ width: '100%' }}>
                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="overflow-x-auto">
                                            <div className="MainDiv">




                                                <div className="container p-5">
                                                    <center><h1>Total Counselling Registered Student</h1></center>
                                                    <table id="example" class="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sl.No</th>
                                                                <th>Student Name</th>
                                                                <th>Email ID</th>
                                                                <th>Mob No</th>
                                                                <th>Father's Name</th>
                                                                <th>Rank</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.data && data.data.map((result, slNo) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{slNo + 1}</td>
                                                                        <td>{result.studentName}</td>
                                                                        <td>{result.emailId}</td>
                                                                        <td>{result.mobileNo}</td>
                                                                        <td>{result.fatherName}</td>
                                                                        <td>{result.rank}</td>

                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    );

}
export default AllStudent;
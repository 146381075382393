import React from 'react'
import styled from 'styled-components'
function NotCollegeAllot() {
    return (
        <Next>
            <center><p style={{ textDecoration: 'underline', fontSize: '1.2rem', marginTop: '9rem', fontWeight: 'bold' }}>
            Sorry ! You have not allotted a  seat in the 2nd Round of Counseling.
            </p></center>
            {/* <center><p style={{ fontSize: '1rem', marginTop: '1rem', fontWeight: 'bold' }}>
            Thank you for participating in the counseling rounds of AICEE 2023. <br></br>
You can claim your refund of INR 2300/- from 20th May, 2023 ( A separate link will be provided for that).
            </p></center> */}
            <center><button className='counsellingButton'
                onClick={() => { alert('College Choice Filling Round 3 will begin from 24th June, 2023, 12:00 PM') }}
              // onClick={() => { setCounsellingPortal(true) }}

            >Proceed For College Choice Filling Round 3</button></center>
        </Next>
    )
}

const Next = styled.main`
.counsellingButton{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
`

export default NotCollegeAllot
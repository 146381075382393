import React from 'react'
import styled from 'styled-components'
import { MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { axios } from 'axios'
import { message } from "antd";


function DocumentUploads(props) {
    const onSubmit = async (e, route) => {
        // alert('helo')
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("pdf", file);
        // Make an API call to upload the file
        fetch(`/api/v1/download/upload/${route}`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the API
                // alert('success')
                alert('Doucment Uploaded Successfully')
                props.onClick()
                console.log(data);
            })
            .catch((error) => {
                // Handle errors from the API call
                message.error('Doucment Uploaded Faied')
                console.error(error);
            });
    }


    return (
        <>
            <MDBCard>
                <div style={{ padding: '5px 8px' }}>
                    <Mess>
                        <div>
                            <div>
                                <div className='proceedButton' style={{ backgroundColor: '#042e2e' }}>Documents Upload </div>
                                <hr></hr>
                            </div>
                            <div style={{ marginTop: '-1.5rem' }}>
                                <>
                                    <div responsive className="containers">
                                        <div className="header-table">
                                            <div>Required Documents</div>
                                            <div>Document Specifications</div>
                                            <div>Upload</div>
                                        </div>
                                        <div className="header-table-card">
                                            <div>
                                                Class Xth Marksheet
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10 KB</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500 KB</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "tenth") }} />
                                                {/* <img  alt='10th'/> */}
                                                {
                                                    props.data.tenthCert &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.data.tenthCert}`} width='100%'></iframe>
                                                }
                                                {/* <embed  width="800px" height="2100px" /> */}
                                            </div>
                                        </div>
                                        <div className="header-table-card">
                                            <div>
                                                Aadhaar Card <span style={{ color: 'red' }}> ( Upload Both Sides of Aadhaar In One Single PDF ) </span>
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10 KB</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500 KB</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "aadhaar") }} />
                                                {
                                                    props.data.aadhaar &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.data.aadhaar}`} width='100%'></iframe>
                                                }

                                            </div>
                                        </div>

                                        <div className="header-table-card">
                                            <div>
                                                Class XIIth Marksheet <span style={{ color: 'red' }}>( Optional If you are Appearing in 2023 and your result hasn't been declared yet )  </span> <br></br>

                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10 KB</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500 KB</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "twelveCert") }} />
                                                {
                                                    props.data.twelveCert &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.data.twelveCert}`} width='100%'></iframe>
                                                }
                                            </div>
                                        </div>
                                        <div className="header-table-card">
                                            <div>
                                                Other Documents <span style={{ color: 'red' }}> ( If you have appeared for any of the Entrance Examination other than AICEE - 2023 ) </span>
                                            </div>
                                            <div>
                                                <div className='format'>
                                                    <p>Document Format: </p>
                                                    <p>PDF</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Min Size (KB): </p>
                                                    <p>10</p>
                                                </div>
                                                <div className='format' style={{ marginTop: '-0.5rem' }}>
                                                    <p>Max Size (KB): </p>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input className='choosefile' type='file' accept='.pdf' onChange={(e) => { onSubmit(e, "otherDocs") }} />
                                                {
                                                    props.data.otherDocs &&
                                                    <iframe src={`https://counselling.aicee.in/api/v1/download/${props.data.otherDocs}`} width='100%'></iframe>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>

                            {
                                props.data.tenthCert && props.data.aadhaar ? <center><button type="submit" className='proceedButton' style={{ backgroundColor: '#042e2e', marginTop: '2rem' }} onClick={()=>{props.setAction('Choice Filling')}}>Proceed to College Choice Filling</button></center> : null
                            }

                            {/* // payment start // */}

                            {/* // payment end // */}

                        </div>


                    </Mess>
                </div>
            </MDBCard>
        </>

    )
}

const Mess = styled.main`
*{
    border: none;
}

.proceedButton{
    padding: 8px 35px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
}
    .heading{
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        color: #042e2e;
    }
    hr{
        margin-top: -0.7rem;
    }
   
    .para{
        margin-top: -16px;
    }
    .header-table{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        background-color: #72787c;
        margin: 2rem 0 0 0;
        color: white;
        font-weight: 600;
        padding: 0.3rem 0.5rem;
    }
    .header-table-card{
        display: grid;
        grid-template-columns: repeat(3,1fr); 
    }
    .format{
        display: flex;
        justify-content: space-between;
    }
    .header-table-card>div{
       /* width: 33%; */
       border: 1px solid black;
       padding: 0.5rem 1rem  ;
       font-weight: 500; 
    }
    .choosefile{
        width: 100%;
        border: 2px solid #72787c;
    }
    @media only screen and (max-width: 600px){
        .headingMess{
        /* margin-left: 2rem; */
        margin-top: -0.7rem;
    }


    }


`

export default DocumentUploads
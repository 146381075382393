import React, { useState } from 'react';

import Dashboard from '../components/spotDashboard/Dashboard'

function RefundRules() {
    
  return (
    <div>
        <Dashboard/>
        <div style={{overflow:'scroll'}}>
        <iframe src='https://counselling.aicee.in/api/v1/download/AICEE 2023 REFUND RULES.pdf' width="100%" style={{margin:'auto'}} height="800px" frameborder="0"></iframe>
        </div>
    </div>
  )
}

export default RefundRules
import React, { useState, useEffect } from 'react'
import Dashboard from '../../components/spotDashboard/Dashboard'
import styled from 'styled-components'
import { MDBRadio, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, } from 'mdb-react-ui-kit';
import AllotmentResult from './AllotmentResult';
import ResultMessage from './ResultMessage';
import NextRoundMessage from './NextRoundMessage'
import DocumentUpload from './DocumentUpload';
import FinalPage from './FinalPage';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { message } from 'antd'
import ExitMessage from './ExitMessage';
const label1 = "I want to take admission in the allotted Institution and program. I hereby freeze my allotted seat . "
const label2 = " I hereby Accept my Allotted Seat but want to Participate in next round for Betterment ."
const label3 = "I want to Exit from the Counseling process ."
// const label3 = "I want to set my preferences again in next round"
function SeatConfirmation() {
    const [basicModal, setBasicModal] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [open, setOpen] = React.useState(true);
    const [action, setAction] = React.useState("result")
    const [choose, setChoose] = React.useState('')
    const toggleShow = (e) => {
        if (!choose) {
            message.error('Please Select Option of Freeze / Float / Exit .')
        }
        else {
            setActionMessage(e)
            setBasicModal(!basicModal)
        }
    };
    console.log(choose)


    // get data of student
    const [data, setData] = useState()
    const [allotedAction, setAllotedAction] = useState()
    const [rank, setRank] = useState()
    const [educationDetails,setEducationDetails] = useState()
    const [studentData,setStudentData] = useState()
    const [payment_info, setPaymentInfo] = useState()
    // console.log(data)
    //  console.log(data.progOpted)
    //  const branch = data.progOpted
    const getData = () => {
        setOpen(true)
        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success
                console.log("response",response)
                setData(response.data.user.college_choice[0].round_three_alloted_college)
                setEducationDetails(response.data.user.educationdetails)
                setStudentData(response.data.user)
                setRank(response.data.user.examination_details.rank)
                setAllotedAction(response.data.user.college_choice[0])
                setPaymentInfo(response.data.user.counselling_payments[0])
                if(response.data.user.college_choice[0].is_college_locked){
                    setOpen(false)
                    setAction('finalPage')
                    return
                }

                if(response.data.user.college_choice[0].round_three_action){
                    setAction(response.data.user.college_choice[0].round_three_action)
                }

                // if(response.data.user.college_choice[0].round_two_action){
                //     setAction(response.data.user.college_choice[0].round_two_action)
                // }
                // if(response.data.user.college_choice[0].round_one_action){
                //     setAction(response.data.user.college_choice[0].round_one_action)
                // }
                setOpen(false)
                // if (response.data.user.action.length >= 1) {

                //     setAction(response.data.user.action)
                //     console.log(response.data.user.action)
                //     if (response.data.user.SeatAccept) {
                //         setOpen(false)
                //         setAction("finalPage")
                //     }
                // }

                // console.log(response.data.user)
            })
            .catch(function (error) {
                // handle error
                setOpen(false)
                console.log(error);
            })
    }

    console.log("action......", action)

    //  update action from backend
    const saveAction = () => {
        setOpen(true)
        if (!choose) {
            message.error('Please Select any of the above')
            setOpen(false)
        }
        axios.post(`/api/v1/student/allot-action/${choose}`, )
            .then(function (response) {
                setOpen(false)
                console.log(response);
                setBasicModal(false)
                getData()
                // alert('College Choices saved successfully')
            })
            .catch(function (error) {
                console.log(error);
                setOpen(false)
                alert(error.response.data.message)
            });
    }
    useEffect(() => {
        getData()
    }, [])



    return (
        <>
            {action === "result" &&
                <Seat>
                    <div>
                        <Dashboard />
                        <div className='resultCard'><AllotmentResult  data={data} rank={rank}/></div>
                        <div className='resultCard' style={{ marginTop: '-0.5rem' }}><ResultMessage />

                            <div className='' style={{ padding: '0.8rem 1rem' }}>
                            <center><button className='studentConfirm'> Student confirmation </button></center>
                                <div>
                                    <div className='radio'>
                                        <MDBRadio onChange={() => { setChoose("Freeze") }} name='flexRadioDefault' id='flexRadioDefault1' /><p><span className='boldfont'>Freeze :</span> <span>
                                            {label1}
                                        </span></p>
                                    </div>
                                    {/* <div className='radio' style={{ marginTop: '-0.5rem' }}>
                                        <MDBRadio onChange={() => { setChoose("Float") }} name='flexRadioDefault' id='flexRadioDefault1' /><p><span className='boldfont'>Float :</span> <span>
                                            {label2}
                                        </span></p>
                                    </div>
                                    <div className='radio' style={{ marginTop: '-0.5rem' }}>
                                        <MDBRadio onChange={() => { setChoose("Slide") }} name='flexRadioDefault' id='flexRadioDefault1' /><p><span className='boldfont'>Slide :</span> <span>
                                            {label3}
                                        </span></p>
                                    </div> */}
                                    {
                                        payment_info && !payment_info.partial_payment_status && <div className='radio' style={{ marginTop: '-0.5rem' }}>
                                        <MDBRadio onChange={() => { setChoose("Exit") }} name='flexRadioDefault' id='flexRadioDefault1' /><p><span className='boldfont'>Exit :</span> <span>
                                            {label3}
                                        </span></p>
                                    </div>
                                    }
                                    

                                    <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', fontSize: '13px', marginTop: '0.4rem' }}>The Last date to Upload the documents & Pay Seat Acceptance Fee
                                    is 28th July 2024 11:00 PM.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <center><button onClick={toggleShow} className='proceedButton' style={{ backgroundColor: '#042e2e' }}>Proceed</button></center>
                    </div>
                </Seat>
            }
            {
                action === "Slide" && <>
                    <Dashboard />
                    <NextRoundMessage />
                </>
            }
            {
                action === "Exit" && <>

                    <Dashboard />
                    <ExitMessage />
                </>
            }
            {
                action === "Float" && <>
                    <Dashboard />
                    <div className='resultCard2' style={{ padding: '0 5rem' }}>
                        <DocumentUpload onClick={getData} data={studentData} educationDetails={educationDetails}/>
                    </div>
                </>
            }
            {
                action === "Freeze" && <>

                    <Dashboard />
                    <div className='resultCard2' style={{ padding: '0 5rem' }}>
                        <DocumentUpload onClick={getData} data={studentData} educationDetails={educationDetails}/>
                    </div>
                </>
            }

            {
                action == 'finalPage' && <>

                    <Dashboard />
                    <div className='resultCard'>
                        <FinalPage data={studentData} />
                    </div>
                </>
            }

            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>

                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>Are You Sure to <strong>{choose}</strong> your allotted Seat <span style={{ color: 'red' }}>(This can not be Undone)</span>. </MDBModalBody>

                        <MDBModalFooter>
                            <button color='secondary' onClick={toggleShow} style={{ backgroundColor: '#042e2e', color: 'white' }}>
                                Cancel
                            </button>
                            <button onClick={saveAction} className='proceedButton' style={{ backgroundColor: '#042e2e', color: 'white' }}>Submit</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <Backdrop
                sx={{ color: '#0e2e2e', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                style={{ backgroundColor: 'white' }}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

const Seat = styled.main`
*{
    /* font-family: Montserrat, sans-serif; */
}
.studentConfirm{
    text-align: center;
    background-color: #042e2e;
    margin-bottom: 5px;
    /* width: 22%;
    
    margin: auto; */
    /* background-clip: text;
    -webkit-background-clip: text;  */
    /* color: white; */
    padding: 4px 15px;
    border-radius:4px;
    color: white;
}
.componenst-main{
    width: 90%;
    margin:auto;
    padding-top: 1rem;

}
.radio{
    display: flex;
    justify-items: center;
    text-align: justify;
    gap: 2rem;
}
.boldfont{
    font-weight: 800;
}
.proceedButton{
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
}
.resultCard{
    margin: 2rem 10.5rem 2rem 10.5rem;
    box-shadow: rgba(4, 46, 46, 46) 0px 3px 8px;
    border: none;
}
.resultCard2{
    margin: 2rem 10.5rem 2rem 10.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 5px solid red;
    padding: 0 5rem;
}
.resultMessage{
    margin: 1rem 3rem 0.5rem 3rem;
}

@media only screen and (max-width: 600px){
    .resultCard{
    margin: 2rem 0rem 2rem 0rem;
    box-shadow: rgba(4, 46, 46, 46) 0px 3px 8px;
    border: none;
}
    .resultCard2{
    margin: 2rem 0rem 2rem 0rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 5px solid red;
    /* padding: 0 5rem; */
}

    .resultMessage{
    margin: 1rem 1rem 0.5rem 1rem;
}
    .resultCard{
    margin: 1rem 1rem 0.5rem 1rem;
}
    .componenst-main{
    width: 98%;
    margin:auto
}
.radio{
   gap: 0.3rem;
}

}
`

export default SeatConfirmation
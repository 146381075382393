import React, { useState, useEffect } from 'react';
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'jszip'
import 'pdfmake'
import $ from 'jquery';
//For API Requests
import axios from 'axios';
import Header from '../dashboard/Header';
import Sidebar from '../dashboard/Sidebar';
import Footer from '../dashboard/Footer';
const Index = () => {
    const [data, setState] = useState([])
    console.log("state", data.data)
    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/api/v1/admin/get/all-students').then(res => {
            //Storing users detail in state array object
            setState({ data: res.data.user });

            console.log("dataAPI", res.data.user)

        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: [
                            'copyHtml5',
                            'excelHtml5',
                            'csvHtml5',
                            'pdfHtml5',
                            'print'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
    // .


    //Datatable HTML
    return (
        <>
            <div>
                <body className="sb-nav-fixed">
                    <Header />
                    <div id="layoutSidenav">
                        <Sidebar />
                        <div id="layoutSidenav_content">
                            <main>
                                <div className="container-fluid px-4">
                                    <ol className="breadcrumb mb-4">
                                    </ol>
                                    <div className="row">



                                        <div>
                                            <div className="flex flex-col" style={{ width: '100%' }}>
                                                {/* <h1 className='flex justify-center pt-4 text-black  text-2xl  mb-0'>User list</h1> */}
                                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className=" overflow-x-auto">



                                                            {/* <DataTable columns={columns} data={student} pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight='400px'
                                                highlightOnHover
                                            // actions={<button className='bg-blue-900 text-white pl-4 pr-4' style={{}}>{actionsMemo}</button>}
                                            /> */}
                                                            <div className="MainDiv">




                                                                <div className="container p-5">
                                                                    {/* <div className='flex justify-end mr-8 '>

<button className='inline-block rounded border border-[#273272] px-12 py-2 text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500'>Add user</button>
           </div> */}
                                                                    <table id="example" class="table table-hover table-bordered ">
                                                                        <thead>
                                                                            <tr class="text-black bg-neutral-200">
                                                                                <th>Sr No.</th>
                                                                                <th>Name</th>
                                                                                <th>Email</th>
                                                                                <th>Mob</th>
                                                                                <th>Father's Name</th>
                                                                                <th>Password</th>
                                                                                <th>Payment</th>


                                                                                {/* <th>Catalog Verified</th> */}
                                                                                {/* <th>Full Name</th>
                                                    <th>Course Name</th>
                                                    <th>Transaction ID</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Mobile No.</th>
                                                    <th>Payment Status</th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {data.data && data.data.map((result, index) => {
                                                                                return (
                                                                                    <tr class="text-black ">
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{result.studentName}</td>
                                                                                        <td>{result.emailId}</td>
                                                                                        <td>{result.mobileNo}</td>
                                                                                        <td>{result.fatherName}</td>
                                                                                        <td>{result.password}</td>

                                                                                        <td>{result.counsellingFeeStatus ? <i style={{ color: 'green' }} className="fa fa-check-circle" ></i> : <i style={{ color: 'red' }} className="fa fa-close"></i>}</td>





                                                                                    </tr>

                                                                                )
                                                                            })}


                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </main>
                            <Footer />
                        </div>
                    </div>
                </body>
                <div>
                </div>
            </div>
        </>
    );

}
export default Index;
import React from 'react';
import Dashboard from '../components/spotDashboard/Dashboard'
function InstructionRules() {
    
  return (
    <div>
        <Dashboard/>
        <div style={{overflow:'scroll'}}>
        <iframe src='https://counselling.aicee.in/api/v1/download/COLLEGE CHOICE FILLING & ALLOTMENT GUIDE.pdf' width="100%" style={{margin:'auto'}} height="800px" frameborder="0"></iframe>
        </div>
    </div>
  )
}

export default InstructionRules
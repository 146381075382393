import React, { useState, useEffect } from 'react'
import './css/styles.css'
import { useNavigate, Link } from 'react-router-dom'
import {
  MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBadge,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { PendingCollegeAllotment } from './Index'
import ViewAllotedCollege from './ViewAllotedCollege';
import NotChoiceFilled from './NotChoiceFilled';
import Header from './dashboard/Header';
import Sidebar from './dashboard/Sidebar';
import AllStudent from './student/AllStudent';
function Dashboard() {
  // MODAL OPERATION
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

  const navigate = useNavigate()
  // GET ALL CHOICE FILLED STUDENT
  const [choiceFilled, setChoiceFilled] = useState([])
  console.log(choiceFilled)
  const getStudent = () => {
    axios.get('/api/v1/admin/choice-filled-student')
      .then(function (response) {
        // handle success
        console.log(response.data.students);
        setChoiceFilled(response.data.students);
      })
      .catch(function (error) {
        // handle error
        console.log({ error: error });
      })
  }
  const [choiceFilledfailed, setChoiceFilledfailed] = useState([])
  console.log(choiceFilled)
  const getStudentMissing = () => {
    axios.get('/api/v1/admin/choice-filled-student-failed')
      .then(function (response) {
        // handle success
        console.log(response.data.students);
        setChoiceFilledfailed(response.data.students);
      })
      .catch(function (error) {
        // handle error
        console.log({ error: error });
      })
  }

  useEffect(() => {
    getStudent()
    getStudentMissing()
  }, [])

  const [action, setAction] = useState('')
  const handleModal = (actionData) => {
    setAction(actionData)

    setFullscreenXlModal(true)
  }

  const [studentData, setStudentData] = useState({});
  const [totalStudentFilled, setTotalChoiceFilled] = useState([])
  // FETCH ALL DATA OF STUDENT //
  const [applicationNo, setApplicationNo] = useState("")
  const [choiceFillStudent, setChoiceFillStudent] = useState({})
  const [loader, setLoader] = useState("false")
  return (
    <div>
      <body className="sb-nav-fixed">
        <Header />
        <div id="layoutSidenav">
          <Sidebar />
          <div id="layoutSidenav_content">
            <main>
              <div className="container-fluid px-4">
                {/* <h1 className="mt-4">Welcome AICEE Admin</h1> */}
                <ol className="breadcrumb mb-4">
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
                <div className="row">
                 






                 <AllStudent/>
                </div>
              </div>
            </main>
            <footer className="py-4 bg-light mt-auto">
              <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                  <div className="text-muted">Copyright &copy; Your Website 2022</div>
                  <div>
                    <a >Privacy Policy</a>
                    &middot;
                    <a>Terms &amp; Conditions</a>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </body>
      <MDBBtn onClick={toggleShow}>Full screen below xl</MDBBtn>
      <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
        <MDBModalDialog size='fullscreen'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{action}</MDBModalTitle>
              {/* <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={toggleShow}
              ></MDBBtn> */}
              <MDBBadge color='primary' pill style={{ padding: '0.8rem 0.4rem', cursor: 'pointer' }} onClick={toggleShow}>
                Back To Dashboard
              </MDBBadge>
            </MDBModalHeader>
            <MDBModalBody>
              <div style={{ overflow: 'scroll' }}>
                {
                  action === "Pending College Allotment" && <PendingCollegeAllotment data={choiceFilled} onClick={getStudent} />
                }
                {
                  action === "View College Allotment" && <ViewAllotedCollege data={choiceFilled} onClick={getStudent} />
                }
                {
                  action === "Not Choice Filled College Student data" && <NotChoiceFilled data={choiceFilledfailed} onClick={getStudent} />
                }
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={toggleShow}>
                {/* Close */}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}

export default Dashboard
import React, { useState, useEffect } from 'react'
import Dashboard from '../components/spotDashboard/Dashboard'
import RankCard from './RankCard'
import axios from 'axios'
function Result() {
  const [data, setData] = useState()
  const getData =  async() => {
    await axios.get('/api/v1/profile')
  .then(function (response) {
    // handle success
    // setData(response.data.user)
    console.log(response.data.user)
    getRankCardData(response.data.user.email,response.data.user.password)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }

  const getRankCardData =  async (credentials,password) => {
    await axios.post('https://admin.aicee.in/counselling/rank-card',{credentials, password})
  .then(function (response) {
    // handle success
    console.log("response"+response)
    setData(response.data.data[0])
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div>
      <Dashboard />
      <RankCard data={data} />
    </div>
  )
}

export default Result
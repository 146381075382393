import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import axios from "axios";
const PrivateRouteAdmin = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = async() => {
    // const userToken = localStorage.getItem('user-token');
    // if (!userToken || userToken === 'undefined') {
    //   setIsLoggedIn(false);
    //   return navigate('/auth/login');
    // }
    // setIsLoggedIn(true);
    try {
      const response = await axios.get('/api/v1/admin/profile');
    //   const data = response.json()
    console.log(response)
    if(response.data.user.name === "Admin"){
        setIsLoggedIn(true);
    }
    //   console.log(axios.response.data)
    } catch (error) {
          setIsLoggedIn(false);
      return navigate('/admin/login');
    }
  }
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      {
        isLoggedIn ? props.children : null
      }
    </React.Fragment>
  );
}
export default PrivateRouteAdmin;
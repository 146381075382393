import React from 'react'

function SubmitCollege(props) {
  return (
    <>
      <center>
        <button className='m-3' style={{backgroundColor:'#042e2e',color:'white',border:'none',borderRadius:'3px', padding:'0.2rem 1rem'}} onClick={props.resetCollege}> Reset Your Choices</button>
        <button className='m-3' style={{backgroundColor:'#042e2e',color:'white',border:'none',borderRadius:'3px', padding:'0.2rem 1rem'}} onClick={props.saveCollege}>Save Your Choices</button>&nbsp; &nbsp;
        <button className='m-3' style={{backgroundColor:'#042e2e',color:'white',border:'none',borderRadius:'3px', padding:'0.2rem 1rem'}} onClick={props.submitCollege}> Final Submit</button>
    </center>
    <div style={{padding:'2rem'}}>
    <span style={{color:'red'}}>Note:-</span> <br></br>
    <li><span style={{color:'red'}}>Reset :- </span> This will clear all your previously selected colleges.</li>
    <li>Do not Share Your Login Id & Password with anyone .</li>
    </div>
    </>
  )
}

export default SubmitCollege
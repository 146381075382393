import styled from "styled-components";

const Rank = styled.main`
   *{
        text-transform: capitalize;
    }
    .form-input{
        outline: none;
        width: 25rem;
        margin: auto;
        border: 1px solid gray;
        padding: 7px;
    }
   
    table{
        /* border: 1px solid black; */
        border-collapse: collapse;
    }

    tr{
        border: 1px solid black;
    }
    tc{
        border: 1px solid black;
        width: 3rem;
    }
    td{
        border: 1px solid black;
    }
    .heading{
        background-color: #ececec;
    }
    .perf{
        background-color: #ececec;
        font: 800;
    }
    @media only screen and (max-width: 600px){
        .form-input{
        outline: none;
        width: 27rem;
        margin: auto;
        border: 1px solid gray;
        padding: 7px;
    }
}
`
export default Rank
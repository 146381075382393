import React, { useState, useEffect } from 'react'
import CollegeList from './CollegeList'
import CollegePreview from './CollegePreview'
import axios from 'axios'
import SubmitCollege from './SubmitCollege'
import SubmitCollegeMessage from './SubmitCollegeMessage'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
function ChoiceFilling(props) {
  const Navigate = useNavigate()
  const [college, setCollege] = React.useState([])
  const [counsellingPortal, setCounsellingPortal] = useState(false)
  const [result, setResult] = useState(true)
  function onSubmit(e, branch) {
    // alert(branch)
    // console.log(collegeName,collegeCode,programme,state,branch)
    console.log(e)
    if (college.some((item) => item.id === e._id && item.branch === branch)) {
      alert('This College has already been added in your list')
    } else {

      const updateCollege = [
        ...college,
        {
          id: e._id,
          collegeName: e.collegeName,
          collegeCode: e.collegeCode,
          programme: e.programme,
          state: e.state,
          branch: branch
        }
      ]
      setCollege(updateCollege)
    }
  }
  console.log(college)


  // Function to remove College based on its id
  const removeItem = (id) => {
    setCollege(college.filter((item) => item.id !== id));
  };

  // Function to change the index of a college
  const changeIndex = (id, newIndex) => {
    const itemIndex = college.findIndex((item) => item.id === id);
    const newData = [...college];
    const removedItem = newData.splice(itemIndex, 1)[0];
    newData.splice(newIndex, 0, removedItem);
    setCollege(newData);
  };

  const [data, setData] = useState({})
  const [open, setOpen] = React.useState(true);
  //  console.log(data.progOpted)
  //  const branch = data.progOpted
  const getData = () => {
    setOpen(true)
    axios.get('/api/v1/profile')
      .then(function (response) {
        // handle success
        setOpen(false)
        setData(response.data.user)
        // if (response.data.user.allotedCollege.length >= 1) {
        //   // Navigate('/student/dashboard/seat-confirmation')
        // }
        if (response.data.user.choiceFillingSpotRound.length >= 1) {
          setCollege(response.data.user.choiceFillingSpotRound)
        }
        // console.log(response.data.user)
      })
      .catch(function (error) {
        // handle error
        setOpen(false)
        console.log(error);
      })
  }
  useEffect(() => {
    getData()
  }, [])

  const saveCollege = () => {
    axios.post(`/api/v1/choice-filling-spot-round/${data._id}`, {
      collegeChoice: college
    })
      .then(function (response) {
        console.log(response);
        alert('College Choices saved successfully')
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message)
      });
  }
  const submitCollege = () => {
    if (college.length < 4) {
      alert("Please Select a minimum of 5 Choices")
    }
    else if(college.length >= 51){
      alert("Maximum 50 colleges choices allowed")
    }
    else {
      axios.post(`/api/v1/choice-filling-spot-round-submit/${data._id}`, {
        collegeChoice: college
      })
        .then(function (response) {
          console.log(response);
          alert('Your Choice Filled College has been Submitted successfully')
          props.getUpdateUserDetails()
          // getData()
        })
        .catch(function (error) {
          console.log(error);
          alert(error.data.data.message)
        });
    }
  }

  const resetCollege = () => {
    setCollege([])
  }

  return (
    <>
     {
        counsellingPortal ? <>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', margin: '10px' }}>
            <CollegeList onClick={(data, branch) => onSubmit(data, branch)} data={data} />
            <CollegePreview data={data.progOpted} previewData={college} onClick={(id) => removeItem(id)} onClicks={(id, newIndex) => changeIndex(id, newIndex)} />
            <SubmitCollege saveCollege={saveCollege}
              submitCollege={submitCollege}
              resetCollege={resetCollege}
            />
          </div>
        </>
          :
          <CSS>
            <center className='counsellingCss'>
              <div>
                <div className='proceedButton' style={{ backgroundColor: '#042e2e' }}>Important Instructions for Spot Round Choice Filling</div>
                {/* <hr></hr> */}
                <br></br>
              </div>
              <li>Always save Your College Choices Before Logging Out.</li>
              <li>Do not Click on <strong>Final Submit</strong> button until you want to lock all your filled Choices.</li>
              <li>Students won't be able to modify or change their college choice after <strong>Final Submit</strong>.</li>
              <li>Do not Share Your <span style={{ color: 'red' }}>Login Id & Password</span> with anyone .</li>
              {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600', marginTop: '0.8rem' }}>College Choice Filling Round 1  will begin from 30th April, 2023, 03:00 PM</p> */}
              <center><button className='counsellingButton'
                // onClick={() => { alert('College Choice Filling Round 3 will begin from 26th march, 2023, 12:00 PM') }}
                // onClick={() => { alert('Time Over') }}
                onClick={() => { setCounsellingPortal(true) }}
              >Proceed For College Choice Filling  </button></center>
            </center>
          </CSS>
      }
      

      {/* <Backdrop
        sx={{ color: '#0e2e2e', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        style={{ backgroundColor: 'white' }}
      
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </>

  )
}


const CSS = styled.main`
.proceedButton{
  width: 80%;
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin: auto;
    text-align: center;
}
.counsellingCss{
  /* border: 2px solid black; */
  /* width: 45%;
  margin: auto; */
  margin-top: 1rem;
  text-align: justify;
  /* justify-content: start; */
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
.counsellingCss>li{
  margin-left: 11rem;
}
@media only screen and (max-width: 600px){
  .counsellingCss>li{
  margin-left: 0rem;
}
  .counsellingCss{
  /* border: 2px solid black; */
  margin: 1rem;
  width: 100%;
  text-align: start;
  /* justify-content: start; */
  /* margin: 2rem 20rem; */
}
.counsellingButton{
  border: 3px solid black;
  width: 80%;
  margin: auto;
}
}
.counsellingButton{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
.counsellingButton2{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
  margin-top: 12rem;
}
`

export default ChoiceFilling
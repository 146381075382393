import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import styled from 'styled-components';
import Button from '@mui/material/Button';
export default function CollegePreview(props) {
    return (
        <Counselling style={{height:'50vh',overflow:'scroll',padding:'8px',marginTop:'2rem'}}>
       
     
     <p className='messageAlert'>College Choice List</p>
     <center>
     <Button variant="contained" style={{backgroundColor:'#042e2e',marginBottom:'10px'}}>Total Choices Filled : {props.previewData.length}</Button>
     </center>
            {props.previewData.length >= 1 &&

                <MDBTable responsive >
                    <MDBTableHead>
                        <tr className='counsellingTable1' style={{ width: '100%' }} >
                            <th className='counsellingTable2' scope='col'>S.L</th>
                            <th className='counsellingTable3' scope="col">College Name</th>
                            <th className='counsellingTable4' scope="col">College Code</th>
                            <th className='counsellingTable5' scope="col">Programme</th>
                            {/* {selectBranch == "" ? <></> : <th className='counsellingTable6' scope="col">Branch</th>} */}
                            {
                                                props.data == "engg" && <th>Branch</th>
                                            }
                            <th className='counsellingTable7' scope="col">State</th>
                            <th className='counsellingTable7' scope="col">Action</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            props.previewData.map((e, i) => {
                                {/* let arr = []
                                                    if (selectBranch != "") {
                                                        arr.push(e.branch.indexOf(selectBranch))
                                                    } */}

                                return (
                                    <>
                                        <tr key={e.collegeCode} style={{ fontSize: '0.8rem' }}>
                                            <td>{i + 1}</td>
                                            <td>{e.collegeName}</td>
                                            <td>{e.collegeCode}</td>
                                            <td>{e.programme}</td>
                                            {
                                                e.branch != undefined && <td>{e.branch}</td>
                                            }
                                            
                                            {/* {selectBranch == "" ? <></> : <td>{e.branch[arr]}</td>} */}
                                            <td>{e.state}</td>
                                            <td>
                                            <button variant="contained" style={{backgroundColor:'#ffc314',color:'white',border:'none',borderRadius:'3px'}} onClick={() => props.onClicks(e.id, i + 1)}>Down</button> &nbsp;
                                            <button variant="contained" style={{backgroundColor:'#ffc314',color:'white',border:'none',borderRadius:'3px'}} onClick={() => props.onClicks(e.id, i - 1)}>UP</button> &nbsp;
                                            <button variant="contained" style={{backgroundColor:'#cd5d66',color:'white',border:'none',borderRadius:'3px'}} onClick={() => props.onClick(e.id)}>Remove</button> &nbsp;
                                                {/* <button onClick={() => props.onClick(e.id)}>Remove</button>
                                                <button onClick={() => props.onClicks(e.id, i - 1)}>UP</button>
                                                <button onClick={() => props.onClicks(e.id, i + 1)}>Down</button> */}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </MDBTableBody>
                </MDBTable>

            }

        </Counselling>
    );
}

const Counselling = styled.main`
    .messageAlert {
  /* padding: 20px; */
  background-color: #042e2e;
  text-align: center;
  color: white;
     padding:0.6rem 1rem;
     border-radius: 6px;
     font-weight: bold;
  /* position: relative; */
}



`


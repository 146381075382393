import React, { useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import RankCss from '../css/RankCss';
import axios from 'axios';
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
function AllotmentLetter(props) {

    // const [data,setData] = useState({})
    const [data, setData] = useState()
    const getData = () => {
        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success
                setData(response.data.user)
                console.log(response.data.user)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    
    useEffect(() => {
        getData()
    }, [])
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // modal open an close functionality
    const [fullscreenXlModal, setFullscreenXlModal] = useState(true);

    const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
    return (
        <div>
            <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog size='fullscreen-xxl-down'>
                    <MDBModalContent>
                        <MDBModalBody>
                            <div className='p-4 paymentPage' ref={componentRef}>
                                <RankCss>
                                    {
                                        data ? <>
                                            <div style={{ border: '2px solid gray', width: '720px', margin: 'auto' }} >
                                                <center className='flex justify-between items-center mb-4 mt-4 pl-2 pr-2' style={{ display: 'flex', justifyContent: 'space-between', margin: '4rem 0', padding: '0 2rem' }} width='720'>
                                                    <div>
                                                        <img style={{ width: '3.6rem' }} src='https://aicee.in/static/media/logo1.bdff491eff12e960f90c.png' alt='aicee' />
                                                    </div>
                                                    <div>
                                                        <h4 className='font-black text-[#e35d26]' style={{ color: '#e35d26' }}>All India Combined Entrance Examination 2024</h4>
                                                        <h5 className='font-bold text-[#e35d26] ' style={{ textDecoration: 'underline', marginTop: '0.8rem', color: '#e35d26' }}>Seat Allotment Letter</h5>
                                                    </div>
                                                    <div style={{ paddingRight: '1.5rem' }}>
                                                        {/* <img style={{width:'5rem'}} src='https://aicee.in/static/media/logo1.32bf711ad21b08f4f19d.png' alt='aicee'/> */}
                                                    </div>
                                                </center>
                                                <table border='1' width='718' cellPadding='6' className='mt-4'>
                                                    <tr>
                                                        <td width='200' className='heading font-bold'>
                                                            Application No
                                                        </td>
                                                        <td colSpan='3'>
                                                            {data.examination_details.application_no}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='heading font-bold'>Student Name</td>
                                                        <td>{data.name}</td>
                                                        <td rowSpan='4' width='200' style={{ margin: 'auto' }}>
                                                            <img style={{ width: '8rem',height: '8rem', margin: 'auto' }} src={data.educationdetails.photo} alt='img' />
                                                        </td>


                                                    </tr>
                                                    <tr>
                                                        <td className='heading font-bold'>Father's Name</td>
                                                        <td>{data.personalDetails.fatherName}</td>

                                                    </tr>
                                                    <tr>
                                                        {/* <td className='heading font-bold'>Mother's Name</td>
                                                        <td>{data.motherName}</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td className='heading font-bold'>Mode Of Examination</td>
                                                        <td>{data.examination_details.moe}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='heading font-bold'>Program Opted</td>
                                                        <td>{data.program}</td>
                                                        <div cellPadding='none'>
                                                            <img style={{ width: '13rem', height: '2.3rem', padding: '-1rem' }} src={data.educationdetails.signature} alt='signature' />
                                                        </div>
                                                    </tr>
                                                    <tr>
                                                        <td className='heading font-bold'>Level Of Examination</td>
                                                        <td colSpan='3'>{data.examination_details.loe}</td>
                                                    </tr>
                                                </table>
                                                {
                                                    data && <>


                                                        {
                                                            data.college_choice[0].round_one_action === "Freeze" &&

                                                            <table border='1' width='718' cellPadding='6'>
                                                            <tr>
                                                                <td width='200' colSpan='5' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                                                    Allotment Details
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    College Name
                                                                </td>
                                                                <td colSpan='3'>
                                                                    {data.college_choice[0].round_one_alloted_college.college_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    College Code
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_one_alloted_college.college_code}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    State
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_one_alloted_college.college_state}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Program
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_one_alloted_college.college_program}
                                                                </td>
                                                            </tr>
                                                            {
                                                                data.college_choice[0].round_one_alloted_college.college_branch&& 
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Branch
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_one_alloted_college.college_branch}
                                                                </td>
                                                            </tr>
                                                            }
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Rank Used for Allottment
                                                                </td>
                                                                <td colSpan='3'>
                                                                    {data.examination_details.rank}
                                                                </td>
                                                            </tr>

                                                        </table>
                                                        }
                                                        {
                                                            data.college_choice[0].round_two_action === "Freeze" &&

                                                            <table border='1' width='718' cellPadding='6'>
                                                            <tr>
                                                                <td width='200' colSpan='5' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                                                    Allotment Details
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    College Name
                                                                </td>
                                                                <td colSpan='3'>
                                                                    {data.college_choice[0].round_two_alloted_college.college_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    College Code
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_two_alloted_college.college_code}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    State
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_two_alloted_college.college_state}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Program
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_two_alloted_college.college_program}
                                                                </td>
                                                            </tr>
                                                            {
                                                                data.college_choice[0].round_two_alloted_college.college_branch&& 
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Branch
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_two_alloted_college.college_branch}
                                                                </td>
                                                            </tr>
                                                            }
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Rank Used for Allottment
                                                                </td>
                                                                <td colSpan='3'>
                                                                    {data.examination_details.rank}
                                                                </td>
                                                            </tr>

                                                        </table>
                                                        }
                                                        {
                                                            data.college_choice[0].round_three_action === "Freeze" &&

                                                            <table border='1' width='718' cellPadding='6'>
                                                            <tr>
                                                                <td width='200' colSpan='5' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                                                    Allotment Details
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    College Name
                                                                </td>
                                                                <td colSpan='3'>
                                                                    {data.college_choice[0].round_three_alloted_college.college_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    College Code
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_three_alloted_college.college_code}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    State
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_three_alloted_college.college_state}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Program
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_three_alloted_college.college_program}
                                                                </td>
                                                            </tr>
                                                            {
                                                                data.college_choice[0].round_three_alloted_college.college_branch&& 
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Branch
                                                                </td>
                                                                <td colSpan='3'>
                                                                {data.college_choice[0].round_three_alloted_college.college_branch}
                                                                </td>
                                                            </tr>
                                                            }
                                                            <tr>
                                                                <td width='200' className='heading font-bold'>
                                                                    Rank Used for Allottment
                                                                </td>
                                                                <td colSpan='3'>
                                                                    {data.examination_details.rank}
                                                                </td>
                                                            </tr>

                                                        </table>
                                                        }
                                                    </>
                                                }
                                                {/* <table border='1' width='718' cellPadding='6'>
                                                    <tr>
                                                        <td width='200' colSpan='2' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                                            Rank
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width='350' className=''>
                                                            AICEE - 2023 Rank
                                                        </td>
                                                        <td colSpan='3'>
                                                            {data.rank}
                                                        </td>
                                                    </tr>


                                                </table> */}

                                                <div>
                                                    {/* <p style={{ fontSize: '12px', padding: '5px' }}>
                                                        AICEE Scores are normalized scores across multi session papers and are based on the relative performance of all those who appeared for the examination in one session
                                                    </p> */}
                                                    <p style={{ fontSize: '12px', padding: '5px', marginTop: '10px' }}>
                                                        <strong> Dear candidate,</strong> <br></br>
                                                        Based On Your Rank And Choices Of Program You Have Been Provisionally Allotted A Seat.
Reporting To The Allotted Institution Will Begin As Per Reporting Instructions Of AICEE (
Reporting Instructions Will Get Published After 3rd Round Of AICEE Counselling).
                                                    </p>
                                                    <p style={{ fontSize: '12px', padding: '5px', marginTop: '-1rem' }}>
                                                        <strong>
                                                            List of Documents to be carried at the time of Reporting to the Allotted Institution.
                                                        </strong>
                                                        <li>Seat Allotment letter.</li>
                                                        <li>Payment Receipt of seat Acceptance fee.</li>
                                                        <li>Aadhar Card</li>
                                                        <li>Passport size photo – 10</li>
                                                        <li>Class Xth Marksheet & certificate.</li>
                                                        <li>Class XIIth Marksheet (or equivalent).</li>
                                                        <li>Residential Certificate or Domicile Certificate.</li>
                                                        <li>Income certificate of parent if their Annual Income is less than 4 lakhs.</li>
                                                        {/* <li></li> */}
                                                    </p>
                                                    <div style={{ fontSize: '11px', padding: '3px',textAlign:'justify' }}>
                                                        <span className='text-red-500' style={{ color: 'red', }}> Note :-</span>

                                                        {/* Reporting to the Allotted Institution will begin from 7th Aug 2023, (tentative) . */} &nbsp;

                                                        This is not the final allotment. For final allotment, students need to submit all the required documents
mentioned above or as per the requirements of the respective institution or AICEE (within a given time
frame). Some institutions might also conduct an aptitude test, a personal interview round, or both
before the final allocation. Reporting Instructions Will Get Published After Three Rounds Of Counseling,
You Will Also Get A Call From AICEE For Reporting To The Institution.
                                                        
                                                        {/* This Is Not The Final Allotment. For Final Allotment Students Need To Submit All The Required Documents Mentioned Above Or As Per Requirement Of Respective Institution Or <span style={{fontWeight:'bold'}}>AICEE</span> (Within A Given Time of Frame). Some Institutions Might Also Conduct Aptitude Test Or Personal Interview Round Or Both Before The Final Allotment. For reporting instructions will get published after three rounds of counseling, you will also get a call from AICEE for reporting to the institution . */}
                                                         <br></br>
                                                        {/*  */}
                                                    </div>
                                                    <center style={{ fontSize: '12px', padding: '1px', backgroundColor: '#ececec', marginTop: '0.5rem' ,textTransform:'none'}}>
                                                        For support call: +91 7488661870 or write us a Mail on <span style={{textDecoration:'lowercase',textTransform:'lowercase'}}>info@aicee.in</span> or <strong style={{textTransform:'lowercase'}}>aicee.info@gmail.com</strong> .
                                                        <br></br>

                                                        Visit official AICEE website: <strong style={{textTransform:'lowercase'}}>www.aicee.in</strong> Regularly for more updates.
                                                    </center>
                                                </div>

                                            </div>
                                        </> : null
                                    }
                                </RankCss>
                                <center>
                                    <button onClick={handlePrint} style={{ backgroundColor: '#042e2e' }} className='pl-4 pr-4 pt-1 mt-5 text-white pb-1 rounded-md mt-2'><i class="fas fa-print"></i> &nbsp; Print / Download</button> &nbsp; &nbsp;
                                    {/* <button onClick={props.setLetter(false)} style={{ backgroundColor: '#042e2e' }} className='pl-4 pr-4 pt-1 mt-5 text-white pb-1 rounded-md mt-2'>  Close</button> */}
                                    <button onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} color='success' style={{ backgroundColor: 'gray', color: 'white', padding: '4px 12px', borderRadius: '8px' }}>Close</button>&nbsp; &nbsp;

                                </center>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}

export default AllotmentLetter
import React, { useState } from 'react'
import styled from 'styled-components'
import PaymentSlip from './PaymentSlip'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Button from '@mui/material/Button';


function SubmitCollegeMessage(props) {
    const [state, setState] = React.useState(false)
    const [showCollege, setShowCollege] = useState(false)
    return (
        <Messages>
            <div className='mess'>
                You have successfuly locked your College Choice for Round 3 of Counselling . College Allotment Result for Round 3 will get declared on <br></br>26th July 2024 07:00 PM.
                <div onClick={() => { setState(!state) }}>
                </div>
            </div>
            {
                state && <PaymentSlip />
            }
            {
                showCollege ? <>
                    {props.previewData.length >= 1 && <>

                        <center><button className='counsellingButton' onClick={() => { setShowCollege(true) }}>Your Submitted College Choices.</button></center>

                        <MDBTable responsive >
                            <MDBTableHead>
                                <tr className='counsellingTable1' style={{ width: '100%' }} >
                                    <th className='counsellingTable2' scope='col'>S.L</th>
                                    <th className='counsellingTable3' scope="col">College Name</th>
                                    <th className='counsellingTable4' scope="col">College Code</th>
                                    <th className='counsellingTable5' scope="col">Programme</th>
                                    {/* {selectBranch == "" ? <></> : <th className='counsellingTable6' scope="col">Branch</th>} */}
                                    {
                                        props.data == "Engineering ( B-Tech / B.E )" && <th>Branch</th>
                                    }
                                    <th className='counsellingTable7' scope="col">State</th>

                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                    props.previewData.map((e, i) => {
                                        {/* let arr = []
                                    if (selectBranch != "") {
                                        arr.push(e.branch.indexOf(selectBranch))
                                    } */}

                                        return (
                                            <>
                                                <tr key={e.collegeCode} style={{ fontSize: '0.8rem' }}>
                                                    <td>{i + 1}</td>
                                                    <td>{e.collegeName}</td>
                                                    <td>{e.collegeCode}</td>
                                                    <td>{e.programme}</td>
                                                    {
                                                        e.branch != undefined && <td>{e.branch}</td>
                                                    }

                                                    {/* {selectBranch == "" ? <></> : <td>{e.branch[arr]}</td>} */}
                                                    <td>{e.state}</td>

                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </>
                    }
                </> :
                    <>
                        <center><button className='counsellingButton' onClick={() => { setShowCollege(true) }}>Click here to see your submitted College Choices.</button></center>

                    </>
            }




        </Messages>
    )
}

const Messages = styled.main`
*{
    font-family: Montserrat, sans-serif;
    font-weight: bold;
}
.mess{
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
    color: green;
}
.counsellingButton{
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
`

export default SubmitCollegeMessage


import React from 'react';
import Dashboard from '../components/spotDashboard/Dashboard'
function ImpDates() {
    
  return (
    <div>
        <Dashboard/>
        <div style={{overflow:'scroll'}}>
        <iframe src='https://counselling.aicee.in/api/v1/download/IMPORTANT DATES RELATED WITH AICEE 2024 COUNSELING.pdf' width="100%" style={{margin:'auto'}} height="800px" frameborder="0"></iframe>
        </div>
    </div>
  )
}

export default ImpDates
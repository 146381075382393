import React, { useState, useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import GavelIcon from '@mui/icons-material/Gavel';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SynagogueIcon from '@mui/icons-material/Synagogue';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import logo1 from '../../assets/images/logo1.png'
import { useNavigate } from 'react-router-dom'
import GridViewIcon from '@mui/icons-material/GridView';
import { AppSidebarNav } from '../AppSidebarNav';
function Dashboard() {
  const [studentData, setStudentData] = useState({});
  const navigate = useNavigate();
  const callStudentDashboardPage = async () => {
    try {
      const res = await fetch("/spot/student-dashboards", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await res.json();
      // console.log(data)
      setStudentData(data);

      if (!res.status === 401) {
        const error = new Error(res.server);
        throw error;
      }
    } catch (error) {
      // console.log("unouthorized")
      navigate("/spot-login");
    }
  };

  // useEffect(() => {
  //   callStudentDashboardPage();
  // }, []);
  const Navigate = useNavigate()

  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }



  return (
    <div>

      {/* <button onClick={toggleDrawer}>Show</button> */}

      <Box style={{marginBottom:'10px'}}>
        <AppBar position="static" style={{ backgroundColor: 'white' }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
              style={{ color: '#042e2e', fontWeight: 'bold' }}

            >
              <MenuIcon /> &nbsp;
            <span>Menu</span>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {/* Login */}
            </Typography>
            <Button onClick={() => { Navigate('/logout') }} style={{ backgroundColor: '#042e2e' }} color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='left'
        className='bla bla bla'
        style={{ backgroundColor: '#042e2e'}}
        
      >
      
        <div>
          <ListItem style={{ color: 'white', fontSize: '2.2rem', backgroundColor: '#195656' }}>
            <ListItemAvatar>
              <Avatar
                alt="Remy Sharp"
                src={logo1}
                sx={{ width: 56, height: 56 }}
              />
            </ListItemAvatar>
            <h1>AICEE</h1>
          </ListItem>
          <AppSidebarNav item="" />
        </div>
      </Drawer>
    </div>
  )
}

export default Dashboard
import React from "react";
import Accordion from "./Accordion";
// import "./styles.css";
import styled from "styled-components";
export default function ApplicationStages() {
  return (
    <ImpUpdate>
      <div className="App  stages" >
        <div>
          <Accordion className='rightSidebarheaderreg' title="Step 1 - Login to the Spot Round Counseling portal ." content="
        
        <ul>
              <li>Login to the Counseling portal with the credentials that you have received After successful Registration.</li>
              
            </ul>
        
        " />
          <Accordion
            title="Step 2 - Read all the Spot Counseling related Instructions carefully ."
            content="
          <ul
          >
          <li>Read all the Spot Round Counseling related instructions carefully Available in your Dashboard after Login .</li>
        </ul>
          "
          />
          <Accordion
            title="Step 3 - Payment of Counseling fee."
            content=" 
          <ul
              >
              <li>A Spot Round Counseling fee of INR 1500/- Only will be charged to participate in the Spot Round Counseling . This Counseling
fee is Non - Refundable .</li>
<li>
  No Spot Round Counseling fee will be charged to the Students if they have already paid & Participated in the Counseling Rounds of Phase 1, Phase 2 , Phase 3 or Phase 4.
</li>
             
              
            </ul>
          "
          />
          <Accordion
            title="Step 4 - College Choice filling Round ."
            content="
          <ul
              >
              <li> After Completing Step 3. You will be need to Upload Class Xth, Class XIIth, Aadhaar Card & Other Documents. After Uploading All the documents you will be able to fill the college Choices. .</li>
             
              </ul>
          "
          />
          <Accordion
            title="Step 5 - Confirmation of Alloted Seat ."
            content=" 
          <ul>
              <li>If you are satisfied with the allotted College then you Need to Pay an Amount of INR 10,000/- Towards Seat Confirmation & Partial Tuition fee  .</li>
              
            </ul>
          "
          />

          <Accordion
            title="Step 6 - Report to the Allotted Institution."
            content=" 
          <ul
          >
          <ul>
              <li>Report to the Allotted Institution as per Instructions of AICEE . While Reporting Students
should carry original copy of All the Uploaded Documents as well as documents Mentioned on your Provisional Allotment Letter .</li>
<li>
Remaining First Year / First Semester
 Fee Should be paid to the Institution at the time of Reporting via NEFT/RTGS/IMPS or Demand Draft.
</li>
<li>
Reporting To the Institution will begin from 2nd week of August,2023 (Tentative ).
</li>
              
            </ul>
        </ul>
          "
          />
        </div>
      </div>
    </ImpUpdate>
  );
}


const ImpUpdate = styled.main`
  .stages{
    margin-top: -0.4rem;
  }
 @media only screen and (max-width: 600px){
  .stages{
    margin-top: 1rem;
  }
  }
`
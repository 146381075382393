import React, { useState, useEffect } from 'react'
import axios from 'axios'
function CounsellingPayment() {
    const [studentData, setStudentData] = useState()
    const [checked, setChecked] = useState(false)
    console.log(studentData)
    const getData = () => {
        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success       
                setStudentData(response.data.user)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }
    const handleChange = () => {
        setChecked(!checked)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>

            {studentData && 
                <div style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <div className="card">
                <div className="card-header " style={{ fontWeight: "bold", color: 'white', textAlign: 'center', backgroundColor: '#042e2e' }}>
                    {/* General Instructions related with AICEE 2022 Counselling */}
                    General Instructions for AICEE 2024 Counselling
                </div>
                <div className="card-body">
                    <blockquote
                        className="blockquote mb-0"
                        style={{ fontSize: "1rem", color: "black", fontWeight: "400" }}
                    >
                        <div className="alert alert-light" style={{ padding: '7px 1rem', fontSize: '0.9rem', color: 'black' }} role="alert">
                            <li> Students Needs to login with Valid Credentials .</li>
                            <li> Student will be able to Confirm their seats from the given choice of Colleges as per Vacancy in respective Institutions / Program .</li>
                        </div>
                        <div className="alert alert-light" style={{ padding: '7px 1rem', marginTop: '-1.6rem', fontSize: '0.9rem', color: 'black' }} role="">
                            {/* <li>Only those Students will be Allowed to take Part in the counseling process ,who had
                          registered themselves and Paid the counseling Fees as per their registered program .</li> */}
                            <li style={{ fontWeight: 'bold', fontSize: '0.9rem', textAlign: 'center', listStyle: 'none' }}>
                                {/* Students need to pay counseling fee as per given Table : */}
                                A Counselling Fee as per given Table will be charged
                            </li>
                        </div>

                        <div>
                            <table style={{ width: '98%', margin: 'auto', color: 'black', fontSize: '0.7rem', marginTop: '-0.8rem', borderRadius: '7px' }} className="table  table-bordered border-dark">
                                <thead>
                                    <tr>
                                        <th scope="col">Sl.No</th>
                                        <th scope="col">Courses</th>
                                        <th scope="col">Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                              <th scope="row">1</th>
                              <td>Polytechnic</td>
                              <td>INR <br /> 5300 /- </td>
                            </tr> */}
                                     <tr>
                                        <th scope="row">1</th>
                                        <td>Engineering (B-Tech / BE), Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology ) , B.Pharmacy , Agriculture ( Bsc. Agriculture / B-Tech Agriculture ) , Bsc Datascience, 	B.Pharmacy , Bsc.Nursing , Bsc.Agriculture , Bio-Tech, BBA , BCA , B.Design , LLB (BA / B.Com / B.sc / BBA / BCA) , Mass Communication & Journalism, MBA/PGDM</td>
                                        <td>INR 3,800 /-</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="alert alert-light" style={{ padding: '7px 1rem', fontSize: '0.9rem', color: 'black', textAlign: 'justify' }} role="">
                            <li>The Above said Counseling fees is Completely Adjustable in 1st Year of Tution Fee of allotted institution. In case of Withdrawal from the Counselling process. INR 2,000 /- will be refunded to student's Account.</li>
                            <li>A Student can fill Minimum 25 & Maximum 200 colleges Option in Respective Rounds .</li>
                            <li>Do not Share Your Login Id & Password with anyone .</li>

                        </div>

                        <div className="alert alert-light" style={{ marginTop: '-0.8rem', fontSize: '0.9rem' }} role="">
                           {/* <p style={{ textAlign: 'center', color: 'red', fontWeight: '600' }}>Last Date for Counselling Fee Payment is Over</p> */}
                           <p style={{ textAlign: 'center', color: 'red', fontWeight: '600' }}>Last date to pay the counseling Registration fee to Participate in AICEE 2024 Counselling Rounds 12th July, 2024 11:00 PM</p>
                           <p style={{ textAlign: 'center', color: 'red', fontWeight: '600',marginTop:'-12px' }}>To View the Complete list of Participating Colleges , Click on " Menu " at the top left corner of this page.</p>
                            {/* <span style={{textAlign:'center',color:'red',width:'80%',margin:'auto'}}>Last Date for payment counselling fee (Round 2) : 12th Aug 2022 11:00 PM</span> */}
                            {/* Registration for Counselling (Round 2) extended till 6th Aug, 2022, 11:00 PM. */}

                            {/* <figure className="text-center">
                                <blockquote className="blockquote">
                                    <p style={{ color: 'red', fontSize: '0.9rem', fontWeight: '500' }}>Last Date is Over </p>
                                </blockquote>
                            </figure> */}

                            <div className="form-check" style={{ width: '100%', margin: 'auto' }}>
                                <center>

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="gridCheck"
                                        value={checked}
                                        onChange={handleChange}
                                        style={{ border: 'none', border: '1px solid black', alignItems: "left" }}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="gridCheck"
                                        style={{ fontSize: "0.9rem", color: "black", margin: 'auto', textAlign: 'justify', fontWeight: 'bold' }}

                                    >

                                      Here I undertake that All the Information provided by me during Application are true & Correct to the best of my knowledge . In Case of any of the Information found to be Incorrect at any Stage my Alloted seat can be cancelled. I further want to say that i have read all the Instructions & Refund rules carefully and I agree to Participate in the Counselling Rounds of AICEE.
                                    </label>
                                </center>

                            </div>
                        </div>



                        <div className="payment-form" >
                            <form method="POST" name="customerData" action="/ccavRequestHandler">
                                <table style={{ display: 'none' }} width="40%" height="100" border='1' align="center">
                                    <caption>
                                        <font size="4" color="blue"><b>Integration Kit</b></font>
                                    </caption>
                                </table>
                                <table width="40%" height="100" border='1' align="center" style={{ border: 'none' }}>
                                    <tr style={{ display: 'none' }}>
                                        <td>Parameter Name:</td>
                                        <td>Parameter Value:</td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td colspan="2">Compulsory information</td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>Merchant Id</td>
                                        <td><input type="text" name="merchant_id" id="merchant_id" value="241419" /> </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>Order Id</td>
                                        <td><input type="text" name="order_id" value={studentData.examination_details.application_no} /></td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                      <td>Billing Zip:</td>
                                      <td><input type="text" name="billing_zip" value={studentData.personalDetails.pincode} /></td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                      <td>Billing Address:</td>
                                      <td><input type="text" name="billing_address"
                                        value={studentData.personalDetails.state} /></td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                      <td>Billing Tel:</td>
                                      <td><input type="text" name="billing_tel" value={studentData.mobileNo} />
                                      </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>Currency</td>
                                        <td><input type="text" name="currency" value="INR" /></td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>Amount</td>
                                        <td><input type="text" name="amount" value="3800" /></td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>Redirect URL</td>
                                        <td><input type="text" name="redirect_url"
                                            value="https://counselling.aicee.in/ccavResponseHandler" />
                                        </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                        <td>Cancel URL</td>
                                        <td><input type="text" name="cancel_url"
                                            value="https://counselling.aicee.in/ccavResponseHandler" />
                                        </td>
                                    </tr>
                                    <tr style={{ display: 'none' }}>
                                            <td>Language</td>
                                            <td><input type="text" name="language" id="language" value="EN" /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td colspan="2">Billing information(optional):</td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Name</td>
                                            <td><input type="text" name="billing_name" value={studentData.name} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Address:</td>
                                            <td><input type="text" name="billing_address"
                                                value={studentData.personalDetails.addressLine1} /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing City:</td>
                                            <td><input type="text" name="billing_city" value={studentData.personalDetails.city}  /></td>
                                        </tr>
                                        <tr style={{ display: 'none' }}>
                                            <td>Billing State:</td>
                                            <td><input type="text" name="billing_state" value={studentData.personalDetails.state}  /></td>
                                        </tr>

                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Country:</td>
                                            <td><input type="text" name="billing_country" value="India" />
                                            </td>
                                        </tr>

                                        <tr style={{ display: 'none' }}>
                                            <td>Billing Email:</td>
                                            <td><input type="text" name="billing_email"
                                                value={studentData.email} /></td>
                                        </tr>

                                    <tr>
                                        <td></td>
                                        <td>
                                            <center>
                                                <div style={{ textAlign: "center",marginTop:'-20px' }}>
                                                    <button
                                                        disabled={checked == false}
                                                        type="submit"
                                                        className="paymentButton mb-5"

                                                    >
                                                        
                                                        Proceed for payment of Counseling Registration fee

                                                    </button>
                                                </div>
                                            </center>
                                        </td>
                                    </tr>
                                </table>
                                
                            </form>
                        </div>

                    </blockquote>
                </div>
            </div>
        </div>
            }
        </>
    )
}

export default CounsellingPayment
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CounsellingPayment from './CounsellingPayment';
import DocumentUploads from './DocumentUploads';
import ChoiceFilling from './choiceFilling/ChoiceFilling';
import SubmitCollegeMessage from './choiceFilling/SubmitCollegeMessage';
import SeatConfirmation from './seatConfirmation/SeatConfirmation'
const Instruction = () => {
  // loader state
  const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState("");
  // fetch student data
  const [data, setData] = useState({})
  console.log(data)
  const getData = () => {
    setOpen(true)
    axios.get('/api/v1/profile')
      .then(function (response) {
        // handle success
        
        setData(response.data.user)
        if(response.data.user.allotedCollege.length >=1){
          setOpen(false)
          setState('Allot College')
        }
        else if(response.data.user.choiceFillingSpotRound1Submit){
          setState('College Allotment Result')
          setOpen(false)
        }
        else if (response.data.user.aadhaar && response.data.user.aadhaar && response.data.user.twelveCert && response.data.user.otherDocs) {
          setState('Choice Filling')
          setOpen(false)
        }
        else if (response.data.user.counsellingFeeStatus) {
          setState('Document Upload')
          setOpen(false)
        }
        else {
          setState('Counseling Payment')
          setOpen(false)
        }
      })
      .catch(function (error) {
        // handle error
        setOpen(false)

      })
  }
  console.log(state)
  useEffect(() => {
    getData()
  }, [])
  return (
    <div>
      {
        state == "College Allotment Result" &&  <SubmitCollegeMessage previewData={data.choiceFillingSpotRound} data={data.progOpted} />
      }
      {
        state == "Choice Filling" && <ChoiceFilling data={data} getUpdateUserDetails={getData}/>
      }
      {
        state == "Document Upload" && <DocumentUploads data={data} onClick={getData} setAction={setState}/>
      }
      {
        state == "Counseling Payment" && <CounsellingPayment />
      }
      {
        state == "Allot College" && <SeatConfirmation />
      }
     
      <Backdrop
        sx={{ color: '#0e2e2e', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        style={{ backgroundColor: 'white' }}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Instruction
import React from 'react'

const RefundRules = () => {
  return (
    <div>
      <div style={{overflow:'scroll'}}>
        <iframe src='https://counselling.aicee.in/api/v1/download/AICEE_2023_SPOT_ROUND_REFUND_RULES.pdf' width="100%" style={{margin:'auto'}} height="800px" frameborder="0"></iframe>
        </div>
    </div>
  )
}

export default RefundRules
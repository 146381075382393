import React, { useState, useEffect } from 'react'
import '../css/styles.css'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios';
import Header from '../dashboard/Header';
import Sidebar from '../dashboard/Sidebar';
import Footer from '../dashboard/Footer';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBTypography } from 'mdb-react-ui-kit';
function MissingStudent() {
    // MODAL OPERATION
    const [fullscreenXlModal, setFullscreenXlModal] = useState(true);

    const navigate = useNavigate()
    // GET ALL CHOICE FILLED STUDENT
    const [choiceFilled, setChoiceFilled] = useState([])
    console.log(choiceFilled)
    const getStudent = () => {
        axios.get('/api/v1/admin/counselling-payment')
            .then(function (response) {
                // handle success
                setOpen(false)
                console.log(response.data.students);
                setChoiceFilled(response.data.students);
            })
            .catch(function (error) {
                // handle error
                setOpen(false)
                console.log({ error: error });
            })
    }
    const [choiceFilledfailed, setChoiceFilledfailed] = useState([])
    console.log(choiceFilled)
    const getStudentMissing = () => {
        axios.get('/api/v1/admin/total-counselling-payment')
            .then(function (response){
                // handle success
                console.log(response.data.students);
                setChoiceFilledfailed(response.data.students);
            })
            .catch(function (error) {
                // handle error
                console.log({ error: error });
            })
    }

    useEffect(() => {
        getStudent()
        getStudentMissing()
    }, [])

    const [action, setAction] = useState('')
    const handleModal = (actionData) => {
        setAction(actionData)
        setFullscreenXlModal(true)
    }


    // view college allotment
    // Modal Operation
    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShow = () => setOptSmModal(!optSmModal);

    // college Allotment
    const [basicModal, setBasicModal] = useState(false);

    const [name, setName] = useState('')
    const [college, setCollege] = useState([])
    const [id, setId] = useState('')

    const viewCollege = (name, college, ids) => {
        setCollege(college)
        setName(name)
        setId(ids)
        setOptSmModal(true)
    }

    const [collegeName, setCollegeName] = useState('')
    const [collegeCode, setCollegeCode] = useState('')
    const [branch, setBranch] = useState('')
    const [programme, setProgramme] = useState('')
    const [state, setState] = useState('')
    const allotCollege = (collegeN, collegeC, programmeN, brancN, stateN) => {
        setCollegeName(collegeN)
        setCollegeCode(collegeC)
        setBranch(programmeN)
        setProgramme(brancN)
        setState(stateN)
        setBasicModal(true)
    }

    //  SUBMIT ALLOT COLLEGE
    const submitCollege = () => {



        axios.post(`/api/v1/admin/allot-college/${id}`, {
            collegeName,
            collegeCode, branch, programme, state
        })
            .then(function (response) {
                console.log(response);
                setBasicModal(false)
                setOptSmModal(false)
                getStudent()
                alert(response.data.message)
            })
            .catch(function (error) {
                console.log(error);
                alert(error.response.data.message)
            });
    }



    // loader start
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <div>
            <body className="sb-nav-fixed">
                <Header />
                <div id="layoutSidenav">
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container-fluid px-4">
                                <ol className="breadcrumb mb-4">
                                </ol>
                                <div className="row">



                                    <div>
                                        <MDBTable align='middle'>
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope='col'>Name</th>
                                                    <th scope='col'>SL.No</th>
                                                    <th scope='col'>Programme & Application No</th>
                                                    <th scope='col'>Mobile No</th>
                                                    <th scope='col'>Rank</th>
                                                    {/* <th scope='col'>Payment Date</th> */}
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {
                                                    choiceFilled && choiceFilled.map((e, i) => {
                                                        const payment = e.paymentResponse[e.paymentResponse.length - 1].paymentStatus
                                                        const paymentTrim = payment.trim().split("&")
                                                        const transDate = paymentTrim[40].trim().split('=')
                                                        return (
                                                            <>
                                                                {
                                                                    e.choiceFillingRound1Submit != true &&
                                                                    <tr key={i + 1}>
                                                                    <td>{i+1}</td>
                                                                        <td>
                                                                            <div className='d-flex align-items-center'>
                                                                                <img
                                                                                    src={e.photo}
                                                                                    alt='pic'
                                                                                    style={{ width: '45px', height: '45px' }}
                                                                                    className='rounded-circle'
                                                                                />
                                                                                <div className='ms-3'>
                                                                                    <p className='fw-bold mb-1'>{e.studentName}</p>
                                                                                    <p className='text-muted mb-0'>{e.emailId}</p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <p className='fw-normal mb-1' style={{ textTransform: 'capitalize' }}>{e.progOpted}</p>
                                                                            <p className='text-muted mb-0'>{e.applicationNo}</p>
                                                                        </td>
                                                                        <td>

                                                                            <p className='text-muted mb-0'>{e.mobileNo}</p>
                                                                        </td>
                                                                        <td>{e.rank}</td>
                                                                        {/* <td>
                                                                            <MDBBadge color='success' pill style={{ padding: '0.8rem 0.4rem', cursor: 'pointer' }} 
                                                                            
                                                                            onClick={() => { viewCollege(e.studentName, e.allotedCollege, e._id) }}
                                                                            >
                                                                              {transDate[1] && transDate[1]}
                                                                            </MDBBadge>
                                                                        </td> */}
                                                                    </tr>
                                                                }

                                                            </>
                                                        )
                                                    })
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                        {/* College LIST TABLE */}
                                        <MDBModal show={optSmModal} tabIndex='-1' setShow={setOptSmModal} >
                                            <MDBModalDialog size='xl' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                <MDBModalContent>
                                                    <MDBModalHeader>
                                                        <MDBModalTitle>College Alloted By You </MDBModalTitle>
                                                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>

                                                    </MDBModalHeader>
                                                    <MDBModalBody>
                                                        <MDBTable responsive >

                                                            <MDBTableHead>
                                                                <tr>
                                                                    <th scope='col'>#</th>
                                                                    <th scope='col'>College Name</th>
                                                                    <th scope='col'>State</th>
                                                                    <th scope='col'>Branch</th>
                                                                    <th scope='col'>Programme</th>
                                                                    <th scope='col'>College Code</th>
                                                                </tr>
                                                            </MDBTableHead>
                                                            {
                                                                college?.map((e, i) => {
                                                                    return (
                                                                        <MDBTableBody key={i}>
                                                                            <tr>
                                                                                <th >{i + 1}</th>
                                                                                <td>{e.collegeName}</td>
                                                                                <td>{e.state}</td>
                                                                                <td>{e.branch}</td>
                                                                                <td>{e.programme}</td>
                                                                                <td>{e.collegeCode}</td>
                                                                                {/* <td>
                                                        <button onClick={() => { allotCollege(e.collegeName, e.collegeCode, e.branch, e.programme, e.state) }} className='me-1' style={{ backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '6px' }} color='success'>
                                                            Allot
                                                        </button>
                                                    </td> */}
                                                                            </tr>

                                                                        </MDBTableBody>
                                                                    )
                                                                })
                                                            }
                                                        </MDBTable>
                                                    </MDBModalBody>
                                                </MDBModalContent>
                                            </MDBModalDialog>
                                        </MDBModal>


                                        {/* //   ALLOT COLLEGE MODAL */}

                                        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                                            <MDBModalDialog size='md' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                <MDBModalContent>
                                                    <MDBModalHeader>
                                                        <MDBModalTitle>Are You Sure</MDBModalTitle>
                                                        <MDBBtn className='btn-close' color='none' onClick={() => { setBasicModal(false) }}></MDBBtn>
                                                    </MDBModalHeader>
                                                    <MDBModalBody style={{ fontSize: '0.5rem' }}>
                                                        <MDBTypography blockquote>
                                                            <p><strong>College Name : </strong>{collegeName}</p>
                                                        </MDBTypography>
                                                        <MDBTypography blockquote>
                                                            <p><strong>College Code : </strong>{collegeCode}</p>
                                                        </MDBTypography>
                                                        <MDBTypography blockquote>
                                                            <p><strong>State : </strong>{state}</p>
                                                        </MDBTypography>
                                                        <MDBTypography blockquote>
                                                            <p><strong>Programe : </strong>{programme}</p>
                                                        </MDBTypography>
                                                        <MDBTypography blockquote>
                                                            <p><strong>Branch : </strong>{branch}</p>
                                                        </MDBTypography>

                                                    </MDBModalBody>

                                                    <MDBModalFooter>
                                                        <MDBBadge style={{ padding: '0.8rem 0.8rem', cursor: 'pointer' }} color='danger' onClick={() => { setBasicModal(false) }}>
                                                            Cancel
                                                        </MDBBadge>
                                                        <MDBBadge style={{ padding: '0.8rem 0.4rem', cursor: 'pointer' }} color='success' onClick={submitCollege}>Allot College</MDBBadge>
                                                    </MDBModalFooter>
                                                </MDBModalContent>
                                            </MDBModalDialog>
                                        </MDBModal>
                                    </div>





                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </body>
            <div>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}

                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    )
}

export default MissingStudent
import React from 'react'

function PatmentTest() {
  return (
    <div>
        <div>
                                <form method="POST" name="customerData" action="/ccavRequestHandler">
                                    <table width="40%" height="100" border='1' align="center">
                                        <caption>
                                            <font size="4" color="blue"><b>Integration Kit</b></font>
                                        </caption>
                                    </table>
                                    <table width="40%" height="100" border='1' align="center">
                                        <tr>
                                            <td>Parameter Name:</td>
                                            <td>Parameter Value:</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Compulsory information</td>
                                        </tr>
                                        <tr>
                                            <td>Merchant Id</td>
                                            <td><input type="text" name="merchant_id" id="merchant_id" value="241419" /> </td>
                                        </tr>
                                        <tr>
                                            <td>Order Id</td>
                                            <td><input type="text" name="order_id" value='23432452345' /></td>
                                        </tr>
                                        <tr>
                                            <td>Currency</td>
                                            <td><input type="text" name="currency" value="INR" /></td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td><input type="text" name="amount" value="1.00" /></td>
                                        </tr>
                                        <tr>
                                            <td>Redirect URL</td>
                                            <td><input type="text" name="redirect_url"
                                                value="https://counselling.aicee.in/rank-card" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cancel URL</td>
                                            <td><input type="text" name="cancel_url"
                                                value="https://counselling.aicee.in/payment-form" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Language</td>
                                            <td><input type="text" name="language" id="language" value="EN" /></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Billing information(optional):</td>
                                        </tr>
                                        <tr>
                                            <td>Billing Name</td>
                                            <td><input type="text" name="billing_name" value='shivam' /></td>
                                        </tr>
                                        <tr>
                                            <td>Billing Address:</td>
                                            <td><input type="text" name="billing_address"
                                                value="Santacruz" /></td>
                                        </tr>
                                        <tr>
                                            <td>Billing City:</td>
                                            <td><input type="text" name="billing_city" value="Mumbai" /></td>
                                        </tr>
                                        <tr>
                                            <td>Billing State:</td>
                                            <td><input type="text" name="billing_state" value="MH" /></td>
                                        </tr>

                                        <tr>
                                            <td>Billing Country:</td>
                                            <td><input type="text" name="billing_country" value="India" />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Billing Email:</td>
                                            <td><input type="text" name="billing_email"
                                                value="shivambkp70@gmail.com" /></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Shipping information(optional):</td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Name</td>
                                            <td><input type="text" name="delivery_name" value="Sam" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Address:</td>
                                            <td><input type="text" name="delivery_address"
                                                value="Vile Parle" /></td>
                                        </tr>
                                        <tr>
                                            <td>Shipping City:</td>
                                            <td><input type="text" name="delivery_city" value="Mumbai" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shipping State:</td>
                                            <td><input type="text" name="delivery_state" value="Maharashtra" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Zip:</td>
                                            <td><input type="text" name="delivery_zip" value="400038" /></td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Country:</td>
                                            <td><input type="text" name="delivery_country" value="India" />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td><input className='' type="submit" value="Checkout" /></td>
                                        </tr>
                                    </table>
                                </form>
    </div>
    </div>
  )
}

export default PatmentTest
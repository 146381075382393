import React from 'react'
import { useNavigate } from 'react-router-dom'
const Header = () => {
    const navigate = useNavigate()
  return (
    <div>
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">

{/* <!-- Navbar Brand--> */}
<a className="navbar-brand ps-3">Admin Dashboard </a>
{/* <!-- Sidebar Toggle--> */}
<button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" ><i className="fas fa-bars"></i></button>
{/* <!-- Navbar Search--> */}
<form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
  <div className="input-group" onClick={() => { navigate('/logout') }}>
    <input className="form-control" type="button" aria-label="Search for..." value="Logout" aria-describedby="btnNavbarSearch" />
    {/* <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button> */}
  </div>
</form>
{/* <!-- Navbar--> */}
<ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
      <li><a className="dropdown-item">Settings</a></li>
      <li><a className="dropdown-item">Activity Log</a></li>
      <li><hr className="dropdown-divider" /></li>
      <li><a className="dropdown-item" >Logout</a></li>
    </ul>
  </li>
</ul>
</nav>
    </div>
  )
}

export default Header
import React,{useState,useEffect} from 'react'
import Dashboard from '../components/spotDashboard/Dashboard'
import axios from 'axios'
import PaymentSlip from '../pages/PaymentSlip'
function MyPayments() {
    const [state,setState] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [status, setStatus] = useState('')
    console.log(status)
   

        const getData = () => {
            axios.get('/api/v1/profile')
                .then(function (response) {
                    // handle success
                    // console.log(response)
                    setStudentData(response.data.user)
                    if (response.data.user.paymentResponse) {
                        const payment = response.data.user.paymentResponse[response.data.user.paymentResponse.length - 1].paymentStatus
                        const paymentTrim = payment.trim().split("&")
                        const transDate = paymentTrim[40].trim().split('=')
                        const paymentMode = paymentTrim[5].trim().split('=')
                        const paymentAmount = paymentTrim[10].trim().split('=')
                        const paymentTransaction = paymentTrim[1].trim().split('=')
                        const orderStatus = paymentTrim[3].trim().split('=')
                        setPaymentDate(transDate[1])
                        setPaymentMethod(paymentMode[1])
                        setAmount(paymentAmount[1])
                        setTransactionId(paymentTransaction[1])
                        setStatus(orderStatus[1])
                    }
                    console.log(response.data.user)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        
                
            }
            useEffect(() => {
                getData()
            }, [])
    return (
        <div >
            
            <div className="card" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                <div className="card-header" style={{ fontWeight: "500", backgroundColor: '#042e2e', color: 'white' }}>
                    Your Payment Status
                </div>

                <div className="card-body">
                    <div className="alert alert-light" style={{ padding: '7px 1rem', fontSize: '0.9rem' }} role="alert">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Tracking Id</th>
                                    <th scope="col">Payment Mode</th>
                                    <th scope="col">Date of Payment</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Payment Status</th>
                                    {
                                        status == "Success" && <th scope="col">Download</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border:'1px'}}>
                                    <th scope="row">{transactionId}</th>
                                    <td>{paymentMethod}</td>
                                    <td>{paymentDate}</td>
                                    <td>{amount}</td>
                                    {
                                        status == "Success" ? 
                                        <td style={{backgroundColor:'green', color:'white',fontWeight:'bold'}}>{status}</td>:
                                        <td style={{backgroundColor:'red', color:'white',fontWeight:'bold'}}>{status}</td>
                                    }
                                    {
                                        status == "Success" && <th onClick={()=>{setState(!state)}} scope="col" style={{textAlign:'center',cursor:'pointer'}}><i class="fa fa-file-pdf" style={{color:'red',fontSize:'1.2rem',textAlign:'center'}}></i></th>
                                    }
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                state && <PaymentSlip/>
            }
        </div>
    )
}

export default MyPayments
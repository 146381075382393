import React from "react";
import Accordion from "./Accordion";
// import "./styles.css";
import styled from "styled-components";
export default function ApplicationStages() {
  return (
    <ImpUpdate>
      <div className="App  stages" >
      <div>
        <Accordion className='rightSidebarheaderreg' title="Step 1 - Login to the Counseling Portal ." content="
        
        <ul>
              <li>Login to the Counseling portal with the credentials that you have received After successful registration.</li>
              
            </ul>
        
        " />
        <Accordion
          title="Step 2 - Read all the Counseling related Instructions carefully ."
          content="
          <ul
          >
          <li>Read all the Counseling related instructions carefully on the official website &amp; in your Counseling portal
Dashboard .</li>
        </ul>
          "
        />
        <Accordion
          title="Step 3 - Payment of Counseling fee."
          content=" 
          <ul
              >
              <li>A counseling fee of INR 3,800/- will be charged to participate in the counseling Rounds . This Counseling
fee is partially refundable and completely adjustable against Tuition fee.</li>
             
              
            </ul>
          "
        />
        <Accordion
          title="Step 4 - College Choice filling Round 1 , Round 2 &amp; Round 3."
          content="
          <ul
              >
              <li> After the Payment of Counseling fee , You will be able to fill the college choice for Round 1 ,Round 2 &amp;
Round 3. If you are satisfied with the allotted college in Round 1 then Accept your Seat and proceed for
Step 5, If you are not satisfied with the allotted college in round 1 or you want betterment then Float
your seat for Round 2 &amp; Round 3 of counseling .</li>
             
              </ul>
          "
        />
        <Accordion
          title="Step 5 - Uploading of Required Documents &amp; Seat Confirmation ."
          content=" 
          <ul>
              <li>If you are satisfied with the allotted College in any of the counseling rounds then upload all the required
Documents . After Uploading all the documents Accept the Allotted Seat and Pay INR 15,000 Towards
Seat Confirmation and Tuition fee .</li>
            </ul>
          "
        />

        <Accordion
          title="Step 6 - Report to the Allotted Institution."
          content=" 
          <ul
          >
          <ul>
              <li>Report to the Allotted Institution as per Time to Time Instructions of AICEE . While Reporting Students
should carry original copy of All the Uploaded Documents &amp; Remaining First Year or First Semester
Tuition Fee . ( This Fee Can be paid to the Institution via NEFT/RTGS/IMPS or Demand Draft.)</li>

              
            </ul>
        </ul>
          "
        />
      </div>
    </div>
    </ImpUpdate>
  );
}


const ImpUpdate = styled.main`
  .stages{
    margin-top: -0.4rem;
  }
 @media only screen and (max-width: 600px){
  .stages{
    margin-top: 1rem;
  }
  }
`

// <li>
// Reporting To the Allotted Institution for Admission year 2024 will begin from 25th May,2023 (Tentative ).
// </li>
import React, { useState } from 'react'
import {
    MDBCard, MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBCheckbox,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalFooter,
    MDBCardHeader,
    MDBCardBody,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody
} from 'mdb-react-ui-kit';
import ApplicationStages from './ApplicationStages';
import axios from 'axios'
import './Registration.css'
import newgif from '../assets/images/newGif.gif'
import Footer from './Footer';
import { useNavigate, useParams } from 'react-router-dom'
import ImpUpdate from './ImpUpdate';


function Testing() {
    const userIds = useParams();
    console.log(userIds.id)
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [showAlert, setShowAlert] = React.useState(false);



    const [justifyActive, setJustifyActive] = useState('tab1');
    const navigate = useNavigate()
    // console.log(justifyActive)
    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };

    const [studentName, setStudentName] = useState('')
    const [country, setCountry] = useState('')
    const [gender, setGender] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [emailId, setEmailId] = useState('')
    const [datareg, setDatareg] = useState({})
    const [enterEmailOtp, setEnterEmailOtp] = useState()
    const [enterMobileOtp, setEnterMobileOtp] = useState()
    const [studentUserId, setStudentUserId] = useState('')
    const [errorMessage, setErrorMessage] = useState('')



    /// login start
    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')


    const loginUser = (e) => {
        e.preventDefault()
        axios.post('/api/v1/login', {
            email: loginEmail,
            password: loginPassword
        })
            .then(function (response) {
                console.log(response);
                navigate('/dashboard')
            })
            .catch(function (error) {
                console.log(error);
                alert(error.response.data.message)
            });
    }
    // login end



    return (
        <div className='RegistrationCardContainer'>
            <div className='RegistrationCard'>
                <div className='RegistrationCard2'>
                    <MDBCard >
                        <div className='RegistrationCard3' style={{ backgroundColor: '#e35d26' }}>
                            <center className='RegistrationCard4' style={{ backgroundColor: '#e35d26' }}><img className='fluid' style={{ backgroundColor: '#e35d26' }} src='https://aicee.in/static/media/logo1.bdff491eff12e960f90c.png' alt="aicee" /></center>
                            <div className='RegistrationCard5' style={{ backgroundColor: '#e35d26', color: 'white' }}><p>AICEE 2024 Counselling Portal</p>
                                <div className='mt-1' style={{ backgroundColor: '#e35d26' }}>
                                    <marquee style={{ marginTop: '-15px', color: 'white', fontWeight: 'bolder', textTransform: 'capitalize', fontWeight: '500', fontFamily: 'Open Sans, sans-serif' }} behavior="" direction="" ><span style={{ display: 'flex' }}><span> <a href="https://aicee.in/student/rank-card" target='_blank' style={{textDecoration:'none',color:'white'}}>AICEE 2024 Results are declared ( click here to check)</a> </span> <sup><img style={{ padding: '0.4rem 0 0 0' }} src={newgif} alt="gif" /></sup></span></marquee>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <MDBContainer fluid className="p-4 my-5">

                            <MDBRow style={{ marginTop: '-4.8rem' }}>

                                <MDBCol style={{ border: '1px solid #BDBDBD', borderRadius: '5px' }} col='6' md='5'>
                                    <MDBTabs style={{ backgroundColor: '#04124f', width: '100%', padding: '0px' }} className='mb-3 d-flex  '>
                                        {
                                            justifyActive == "tab2" ?
                                                <>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', backgroundColor: 'white', color: '#17a2b8' }} onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                                            New User
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', color: 'white' }} 
                                                        onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                                            Login
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                </> :
                                                <>

                                                    {/* <MDBTabsItem style={{ listStyle: 'none', marginLeft: '1rem', marginTop: '5px', marginBottom: '5px' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', backgroundColor: 'white', color: '#17a2b8' }} onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                                            Login
                                                        </MDBTabsLink>
                                                    </MDBTabsItem> */}
                                                </>
                                        }
                                    </MDBTabs>
                                    <MDBTabsContent >

                                        <MDBTabsPane show={justifyActive === 'tab1'}>


                                            <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'darkblue', marginBottom: '', padding: '10px', backgroundColor: 'white', borderRadius: '5px' }}> Counselling Portal Login <hr style={{ border: '2px solid darkblue', color: "darkblue", marginTop: '5px' }}></hr></div>
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="envelope" /> Email ID<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput onChange={(e) => { setLoginEmail(e.target.value) }} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='text' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="key" /> Password<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput onChange={(e) => { setLoginPassword(e.target.value) }} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='password' />
                                            <div style={{ textAlign: 'right' }}>
                                            </div>
                                            <button style={{ backgroundColor: '#04124f', marginTop: '1rem', color: 'white', fontWeight: 'bold', padding: '5px 0', borderRadius: '8px' }} 
                                            
                                            onClick={loginUser} 
                                            // onClick={()=>{alert('Counselling will begin from 9th Feb 2024')}}
                                            className="mb-4 w-100">Login</button>


                                        </MDBTabsPane>



                                    </MDBTabsContent>

                                </MDBCol>

                                <MDBCol col='6' md='7'>

                                    <ApplicationStages />



                                </MDBCol>

                            </MDBRow>

                            <div style={{ marginTop: '15px' }}><ImpUpdate /></div>
                        </MDBContainer>
                        <div className=''>
                        </div>
                        <div style={{ margin: '0 0 -4rem 0' }}></div>
                    </MDBCard>
                </div>
            </div>
            <div><Footer /></div>
        </div>
    )
}

export default Testing
import React, { useState } from 'react'
import styled from 'styled-components'
import newGif from '../assets/images/newGif.gif'
function ImpUpdate() {
  const [showModal, setShowModal] = useState(false);
  function handleAlertClick() {
    setShowModal(!showModal);
  }

  return (
    <Update>
      <div className='Header' style={{ textAlign: 'center' }}>
        Update Related with AICEE 2024 Counselling Process
      </div>
      <div className='notice'>
        <div>
          <a href='https://counselling.aicee.in/api/v1/download/COUNSELING INSTRUCTIONS.pdf' target='_blank'><p className='marqueePara'>Important Dates & Refund Rules Related with AICEE 2024 Counseling <i className="fa fa-file" style={{ fontSize: "15px", color: 'red'}}></i> &nbsp;<img style={{marginLeft:'-3px'}} src={newGif} alt="new" /> </p></a>
        </div>
        <div>
          <a
           href='https://counselling.aicee.in/api/v1/download/COLLEGE CHOICE FILLING & ALLOTMENT GUIDE.pdf'
           
            target='_blank'><p className='marqueePara' style={{textTransform:'capitalize'}}>College Choice Filling & Allotment Guide <i className="fa fa-file" style={{ fontSize: "18px", color: 'red', marginLeft: '1rem' }}></i><img src={newGif} alt="new" /> </p></a>
          {/* <marquee onmouseover="this.stop();" onmouseout="this.start()" width="100%" direction="up" height="100px" scrollamount="3" className="marqueestyle flex">
        </marquee> */}
        </div>
      </div>
      <div>
      </div>

    </Update>
  )
}


const Update = styled.main`
    a{
        list-style: none;
        text-decoration: none;
    }
    .notice{
      
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .notice>div{
      width:50%
    }
    @media only screen and (max-width: 600px){
      .notice{
      
      display: grid;
      
      gap: -25px;
    }
    .notice>div{
      width:105%;
      margin-bottom:-1.1rem;
    }
}
    .Header{

        border: 1px solid red;
        background-color: #e35d26;
        color: white;
        padding: 10px 15px;
        border-radius: 10px 10px;
        font-weight: 600;
    }
   
    .marqueePara{
        background-color: #e0e0e0;
        margin-top: 10px;
        border-radius: 5px;
        padding: 7px 10px;
        font-size: 13px;
        color: black;
    }
    .marqueePara>img{
        margin-left: 10px;
    }
    .modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  position: relative;
  margin: 10% auto;
  max-width: 400px;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-body {
  padding: 20px;
  text-align: center;
}
.modal-body>img{
  width: 150px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.btn-primary {
  background-color: #1f1463;
  border-color: #007bff;
}

    
`
export default ImpUpdate
import React from 'react'
import styled from 'styled-components'
import PaymentSlip from './PaymentSlip'

function Message() {
    const [state, setState] = React.useState(false)
    return (
        <Messages>
            {/* <div className='mess'>
                You have successfully Registered for Counselling rounds of AICEE , College Choice Filling will begin from 2nd March 2023, 06:00 PM

                <div onClick={() => { setState(!state) }}>

                    <center style={{ marginTop: '1rem', cursor: 'pointer' }}>
                        Download Your Payment Acknowledgement &nbsp; &nbsp; &nbsp; <i class="fa fa-file-pdf" style={{ color: 'red' }}></i>
                    </center>
                </div>
            </div> */}
            
            {
                state && <PaymentSlip />
            }
        </Messages>
    )
}

const Messages = styled.main`
*{
    font-family: Montserrat, sans-serif;
    font-weight: bold;
}
.mess{
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: 4rem;
}
`

export default Message
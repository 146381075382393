import React, { useState, useEffect, useRef } from 'react'
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
import { useReactToPrint } from "react-to-print";
import axios from 'axios'
function PaymentSlip() {
    const [studentData, setStudentData] = useState({})
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [status, setStatus] = useState('')
    const [paymentInfo, setPaymentInfo] = useState()
    console.log(status)


    const getData = () => {
        axios.get('/api/v1/profile')
            .then(function (response) {
                // handle success
                // console.log(response)
                setStudentData(response.data.user)
                if (response.data.user.counselling_payments.length >= 1) {
                    setPaymentInfo(response.data.user.counselling_payments[0])
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })


    }
    useEffect(() => {
        getData()
    }, [])

    //    MBA APPLICATION OPEN FUNCTIONALLITY
    const [fullscreenXlModal, setFullscreenXlModal] = useState(true);

    const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

    ///   print functionallity
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        //   pageStyle: "@page { margin-top: 0px;  } ",
        content: () => componentRef.current,
    });
    //   useEffect(() => {
    //     userAuthenticationData()
    //   }, [])

    return (
        <div className='text-center paymentPage'>

            {/* // modal start // */}
            <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog size='fullscreen-xxl-down'>
                    <MDBModalContent>
                        <MDBModalBody>
                            <div style={{ width: '700px', margin: 'auto' }} className='border border-black' ref={componentRef}>
                                <div className='m-5 border border-black'>
                                    <center><img style={{ width: '11rem', margin: 'auto', marginTop: '2rem' }} src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="" /></center>
                                    <div className='m-4'>
                                    </div>
                                    <div className='text-center text-black' style={{marginTop:'-1rem'}}>
                                        <h5 >All India Combined Entrance Examintaion</h5>
                                    </div>
                                    <div className='text-center text-black font-bold '>
                                        <h5 className='font-bold' style={{ marginTop: '0.8rem', textDecoration: 'underLine', lineHeight:'1' }}>Electronic Receipt</h5>
                                    </div>
                                    {
                                        paymentInfo && <>
                                            <div className='text-center text-black font-bold '>
                                                <h6 className='' style={{ marginTop: '0.8rem' }}>Application No : <span className='font-bold'>{studentData.examination_details.application_no}</span></h6>
                                            </div>
                                            <div className='text-start pl-3 text-black mt-1' style={{ fontSize: '0.9rem', paddingLeft: '1rem' }}>
                                                <p className='' style={{ marginTop: '1.8rem' }}>To  <span className='font-bold'><strong>{studentData.name}</strong></span></p>
                                                <p className='font-bold' style={{ marginTop: '-0.8rem', fontSize: '0.8rem',fontWeight:'bold' }}>Your Counseling Registration is Successful. </p>
                                                {/* <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Amount Received towards application for <span className='font-bold'>UG Application Form 2023 <br /></span> </p> */}
                                                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}> <span >Date of Receipt :</span> <span className='font-bold'>{paymentInfo.payDate}</span> </p>
                                                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}> <span >Payment Type :</span> <span className='font-bold'>Online</span> </p>
                                                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}> <span >Payment Mode :</span> <span className='font-bold'>{paymentInfo.payMode}</span> </p>
                                                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}> <span >Counseling Fee :</span> <span className='font-bold'>{paymentInfo.amount}</span> </p>
                                                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem', marginBottom: '3rem' }}> <span >Transaction Id :</span> <span className='font-bold'>{paymentInfo.transId}</span> </p>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                            <center className='pb-5 mt-2'>
                                <button onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} color='success' style={{ backgroundColor: 'gray', color: 'white', padding: '4px 12px', borderRadius: '8px' }}>Close</button>&nbsp; &nbsp;
                                <button className='ml-3' color='primary' onClick={handlePrint} style={{ backgroundColor: 'green', color: 'white', padding: '4px 12px', borderRadius: '8px' }}> Print</button>
                            </center>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            {/* // modal end // */}

        </div>
    )
}

export default PaymentSlip
import React from 'react'
import CollegeListss from '../views/pages/collegeChoice/CollegeListss'
const ParticipatingColleges = () => {
  return (
    <div>
      <CollegeListss />
    </div>
  )
}

export default ParticipatingColleges
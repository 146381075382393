import React from 'react'
import { MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import styled from 'styled-components';
function AllotmentResult({data,rank}) {
    console.log("data",data)
    // const [alloted,setAlloted] = React.useState(props.data.allotedCollege)
    // console.log(props.data.allotedCollege[0].collegeName)
    // console.log(props.data)
    const allotmentRound = 3
    return (
        <>
            {data && <Allo>
                <MDBCard>
                    <div className='allotment'>
                    <div className='proceedButton' style={{ backgroundColor: '#042e2e' }}>AICEE 2024 Seat Allotment Result Round - {allotmentRound}</div>
                    </div>
                    <div className='allotment'>
                        Allotment Details
                        <hr></hr>
                    </div>
                    <div style={{ margin: '-26px 1rem 0 1rem' }}>
                        <MDBTable bordered borderColor="primary" >

                            <MDBTableBody>
                                <tr>
                                    <th colSpan={1} className='thBody'>Round No</th>
                                    <td colSpan={1}>{allotmentRound}</td>
                                </tr>
                                <tr>
                                    <th colSpan={1} className='thBody'>Institute Alloted</th>
                                    <td colSpan={1}>{data.college_name}</td>
                                </tr>
                                <tr>
                                    <th colSpan={1} className='thBody'>Alloted Program</th>
                                    <td colSpan={1}>{data.college_program}</td>
                                </tr>
                                <tr>
                                {data.college_branch && <>
                                    <th colSpan={1} className='thBody'>Alloted Branch</th>
                                    <td colSpan={1}>{data.college_branch}</td>
                                </> }
                                </tr>
                                <tr>
                                    <th colSpan={1} className='thBody'>Rank used for Allotment</th>
                                    <td colSpan={1}>{rank}</td>
                                </tr>
                                <tr>
                                    <th colSpan={1} className='thBody'>Alloted College Code</th>
                                    <td colSpan={1}>{data.college_code}</td>
                                </tr>

                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </MDBCard>
            </Allo>}
        </>
    )
}




const Allo = styled.main`
.proceedButton{
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px 0;
}
*{
    border: none;
}
.allotment{
    margin: -15px 15px 0 15px;
    border-radius: 3px;
    text-align: center;
    /* background-color: #042e2e; */
    padding: 0.6rem 0;
    font-weight: bold;
    color: #042e2e;
    /* margin-left: 1rem; */
    font-weight: 700;
}
.thBody{
    width:25%;
    font-weight: bold;
}
@media only screen and (max-width: 600px){
    .thBody{
    width:50%
}
}
`

export default AllotmentResult
// import 'react-app-polyfill/stable'
// import 'core-js'
// import React from 'react'
// import ReactDOM from 'react-dom'
// import App from './App'
// import reportWebVitals from './reportWebVitals'

// ReactDOM.render(
  //   <Provider store={store}>
  //     <App />
  //   </Provider>,
  //   document.getElementById('root'),
  // )
  
  // reportWebVitals()
  
  import React from "react";
  import ReactDOM from "react-dom/client";
  import "./index.css";
  import App from "./App";
  import { Provider } from 'react-redux'
  import store from './store'
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <App />
  </Provider>,
  </React.StrictMode>
);

reportWebVitals();

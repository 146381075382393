import React, { useRef } from 'react'
import styled from 'styled-components'
import AllotmentLetter from './AllotmentLetter'
import PaymentReceipt from './PaymentReceipt'
import { useReactToPrint } from 'react-to-print';
function FinalPage(props) {
    const [state, setState] = React.useState(false)
    const [letter, setLetter] = React.useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <Next>
            <div>
                <div style={{ marginTop: '8rem' }}>
                    <h1 className='text-center text-6xl' style={{ fontSize: '2.5rem' }}>Thank You !</h1>
                    <p className='text-center text-2xl'>Your payment is successful</p>
                    <p className='text-center text-xl ' style={{marginTop:'-0.9rem'}}>Application No : <span className='font-bold'>{props.data.applicationNo}</span></p>
                    {/* <p className='text-center text-xl'>Rank : <span className='font-bold'>{props.data.rank}</span></p> */}
                    <p onClick={() => { setState(!state) }} style={{ cursor: 'pointer',marginTop:'-1.6rem' }} className='text-center text-sm pt-4 cursor-pointer'> <span><i className='fa fa-print' /></span> Print / Download Payment Acknowledgement <span className='font-bold'></span></p>
                    <p onClick={() => { setLetter(!letter) }} style={{ cursor: 'pointer',marginTop:'-0.9rem' }} className='text-center text-sm pt-1 cursor-pointer'> <span><i className='fa fa-print' /></span> Print / Download Allotment Letter<span className='font-bold'></span></p>
                    {/* <p onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} className='text-center text-sm pt-4 cursor-pointer'> <span><i className='fa fa-print'/></span> Print / Download Application Form<span className='font-bold'></span></p> */}
                </div>
            </div>



            {
                state && <PaymentReceipt />
            }
            {
                letter && <AllotmentLetter setLetter={setLetter} />
            }
        </Next>
    )
}

const Next = styled.main`
*{
    font-family: Montserrat, sans-serif;
}
.counsellingButton{
    width:30%;
  margin: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #042e2e;
}
`

export default FinalPage
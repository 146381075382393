import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import './Index.css'
import Instruction from './Instruction';
import Rank from '../pages/Result';
import Result from './Result';
import ParticipatingColleges from './ParticipatingColleges';
import MyPayments from './MyPayments';
import RefundRules from './RefundRules';
import ImpDates from './ImpDates';
import Support from './Support';
import { useNavigate, useParams } from 'react-router-dom';
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer() {
    const {download} = useParams()
    const Navigate = useNavigate()
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [menuActive,setMenuActive] = useState('Home')
    console.log(menuActive)
    React.useEffect(()=>{
if(download === 'download'){
    setMenuActive('My Payments')
}
    },[])
    return (
        <Box sx={{ display: 'flex' }} style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <CssBaseline />
            {/* // HEADER // */}
            <AppBar position="fixed" open={open} style={{ backgroundColor: 'white' }}>
          <Toolbar>
            <IconButton
               color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                        style={{color:'#042e2e'}}

            >
              <MenuIcon /> &nbsp;
                        <bold style={{ fontFamily: 'Montserrat, sans-serif',fontWeight:'bold' }}>Menu</bold>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {/* Login */}
            </Typography>
            <Button onClick={() => { Navigate('/spot/logout') }} style={{ backgroundColor: '#042e2e' }} color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
            <Drawer variant="permanent" open={open} style={{ fontFamily: 'Montserrat, sans-serif' }}>
                <DrawerHeader className='spotheader' style={{backgroundColor:'#195656'}}>
                    <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
                    <div className='sidebarHeadings' style={{fontSize:'40px',margin:'auto',display:'flex', alignItems:'center',gap:'1rem'}}> <img style={{width:'30px'}} src="/logo192.png" alt="" /> aicee</div>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider style={{color:'#042e2e'}}/>
                <List className='spotSidebar' style={{height:'100vh'}}>
                    {['Home', 'Result', 'Participating Colleges', 'My Payments', 'Refund Rules', "Important Dates","Support & Contacts"].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }} >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                onClick={()=>{setMenuActive(text)}}
                                style={{ fontFamily: 'Montserrat, sans-serif' }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    style={{ fontFamily: 'Montserrat, sans-serif' }}
                                >
                                    {index % 2 === 0 ? <InboxIcon className='spotSidebar'/> : <MailIcon className='spotSidebar'/>}
                                </ListItemIcon>
                                {/* <ListItemText  primary={text} sx={{ opacity: open ? 1 : 0 }} /> */}
                                {
                                    open && <span>{text}</span>
                                }
                            </ListItemButton>
                            <Divider />
                        </ListItem>
                    ))}
                </List>
                {/* <Divider /> */}
                
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {menuActive == "Home" && <Instruction/>}
                {menuActive == "Result" && <Result/>}
                {menuActive == "Participating Colleges" && <ParticipatingColleges/>}
                {menuActive == "My Payments" && <MyPayments/>}
                {menuActive == "Refund Rules" && <RefundRules/>}
                {menuActive == "Important Dates" && <ImpDates/>}
                {menuActive == "Support & Contacts" && <Support/>}
             
            </Box>
        </Box>
    );
}